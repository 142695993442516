import React, { useEffect, useState } from 'react';
import cubejs from '@cubejs-client/core';
import { CubeProvider, useCubeQuery } from '@cubejs-client/react';

import { useUser } from './UserContext';

const API_URL = process.env.REACT_APP_CUBE_URL;

export const AnalyticsProvider = ({ children }) => {

  const { token } = useUser();
  const [cubejsApi, setCubejsApi] = useState();

  useEffect(() => {
    setCubejsApi(cubejs(token, {
      apiUrl: `${API_URL}/cubejs-api/v1`,
    }));
  },[token])

  return cubejsApi && (
    <CubeProvider cubejsApi={cubejsApi}>{ children }</CubeProvider>
  );
};

export const useAnalyticsQuery = (...args) => useCubeQuery(...args);
