import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_CREATE_BLOCK',
  request: (id, data={}) =>
    request.post(`/partners/building-calendars/${id}/appointments`, data).then(r => r.data.data.appointment),
  reducers: {
    success: (state, { response: block, params: [id] }) => {

      let appointments = [...get(state,`partnerCalendar.${id}.appointments`)];
      appointments.push(block);

      return {
        ...state,
        partnerCalendar: {
          ...state.partnerCalendar,
          [id]: {
            ...state.partnerCalendar[id],
            appointments,
          }
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useBlockPending,
} = generate.redux(creator);

export const initialState = {};
