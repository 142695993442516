import React from 'react';
import { Icon } from '@moved/ui';
import CSS from '../styles/shared.module.scss';

export const StatusHeader = ({icon, title, description}) => (
  <div className={CSS.header}>
    {icon && (
      <Icon symbol={icon.symbol} size={'80px'} color={icon.color} className={CSS.action_icon} />
    )}
    <h1 className={CSS.title}>{ title }</h1>
    <div className={CSS.description}>{ description }</div>
  </div>
);
