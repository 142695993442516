import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_DOCUMENT_TASK_DETAILS',
  request: (id, data={}) => request.patch(`/admins/document-task-file-requirement-lists/${id}`, data).then(r => get(r,'data.data.document_task_file_requirement_list')),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        fileRequirementLists: {
          ...state.fileRequirementLists,
          [response.id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateFileRequirementListPending,
} = generate.redux(creator);

export const initialState = {};
