import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_DOCUMENT_TASK_DETAILS',
  request: (id, params={}) => request.get(`/admins/building-tasks/${id}/document-task-file-requirement-list`, { params }).then(r => get(r,'data.data.document_task_file_requirement_list')),
  selector: (state, id) => { return get(state,`fileRequirementLists.${id}`,{}) || {}; },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        fileRequirementLists: {
          ...state.fileRequirementLists,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useFileRequirementList,
  usePending: useFileRequirementListPending,
} = generate.redux(creator);

export const initialState = {
  fileRequirementLists: {},
};
