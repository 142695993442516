import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import CSS from './DateSelect.module.scss';
import textCSS from '../Text/Text.module.scss';

const convertToDate = (date) => date ? new Date(date+'T00:00') : null;

export const DateSelect = ({
  name,
  value,
  isControlled,
  label,
  hint,
  disabled,
  readOnly,
  onChange,
  className,
  error,
  minDate,
  maxDate,
}) => {
  const [selected, setSelected] = useOptionalControl(value, isControlled);
  const [focused, setFocused] = useState(false);

  const handleSelect = (newDate) => {
    setFocused(false);
    if(disabled) return;
    const newValue = moment(newDate).format('YYYY-MM-DD')
    setSelected(newValue);
    onChange?.({[name]:newValue});
  };

  return (
    <div className={classNames(CSS.container,className)}>
      <DatePicker
        name={name}
        selected={convertToDate(selected)}
        onChange={handleSelect}
        wrapperClassName={classNames(
          CSS.wrapper,
          {
            [CSS.hasValue]: selected,
            [CSS.hasFocus]: focused,
            [CSS.readOnly]: readOnly,
            [CSS.hasError]: error,
            [CSS.disabled]: disabled,
          },
        )}
        className={textCSS.input}
        calendarClassName={CSS.calendar}
        dateFormat={'MMMM dd, yyyy'}
        readOnly={readOnly}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onClickOutside={() => setFocused(false)}
        minDate={minDate ? moment(minDate).toDate() : undefined}
        maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        disabledKeyboardNavigation
        showDisabledMonthNavigation
        autoComplete="off"
        aria-autocomplete="list"
      />
      <label className={classNames(CSS.label, 'labelS contentSecondary')} htmlFor={name}>{ label }</label>
      { error ? (
        <div className='labelS contentError marginTop-4'>{ error }</div>
      ) : hint && (
        <div className={classNames('labelS','marginTop-4',disabled?'contentTertiary':'contentSecondary')}>{ hint }</div>
      )}
    </div>

  );
};

DateSelect.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input `YYYY-MM-DD` (only initial value if not controlled) */
  value: PropTypes.string,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** (optional) Second line of text */
  hint: PropTypes.string,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** Minimum date value allowed `YYYY-MM-DD` */
  minDate: PropTypes.string,
  /** Maximum date value allowed `YYYY-MM-DD` */
  maxDate: PropTypes.string,
};
