import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_KEY_PICKUP_APPOINTMENT_REQUEST',
  request: (token) => request.get(`/partners/key-pickup-appointment-requests/token/${token}`).then(r => r.data.data.key_pickup_appointment_request),
  selector: (state, token) => get(state, `keyPickupAppointmentRequests.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        keyPickupAppointmentRequests: {
          ...state.keyPickupAppointmentRequests,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useKeyPickupAppointmentRequest,
  usePending: useKeyPickupAppointmentRequestPending,
} = generate.redux(creator);

export const initialState = {
  keyPickupAppointmentRequests: {},
};
