import { generate, request } from '@moved/services';
import { get } from 'lodash';

const getUniqueKey = (...params) => params.join('-');

const creator = {
  namespace: 'ADMIN_GET_TENANT_BUILDING_RESERVATION_OPTIONS',
  request: (tenantEventId, buildingTaskId) => request.get(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/building-reservation-task-options`)
    .then(r => get(r, 'data.data.building_reservation_task_options')),
  selector: (state, ...params) => { return get(state,`tenantBuildingReservationOptions.${getUniqueKey(...params)}`); },
  reducers: {
    success: (state, { response, params }) => {
      return {
        ...state,
        tenantBuildingReservationOptions: {
          ...state.tenantBuildingReservationOptions,
          [getUniqueKey(...params)]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useTenantBuildingReservationOptions,
  usePending: useTenantBuildingReservationOptionsPending,
} = generate.redux(creator);

export const initialState = {
  tenantBuildingReservationOptions: {},
};
