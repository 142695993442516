import { merge } from 'lodash';

import * as getDocumentSubmission from './getDocumentSubmission';
import * as reviewDocumentSubmission from './reviewDocumentSubmission';

export const initialState = merge(
  getDocumentSubmission.initialState,
  reviewDocumentSubmission.initialState,
);

export const reducers = [
  getDocumentSubmission.reducer,
  reviewDocumentSubmission.reducer,
];
