import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'APPROVALS_REVIEW_RESERVATION_REQUEST',
  request: (token, action, data) => request.post(`/partners/building-reservation-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.building_reservation_request')),
  reducers: {
    success: (state, { response: reservationRequest, params: [token] }) => {
      return {
        ...state,
        reservationRequests: {
          ...state.reservationRequests,
          [token]: reservationRequest,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReviewReservationRequestPending,
} = generate.redux(creator);

export const initialState = {
  reservationRequests: {},
};
