import React from 'react';

import { useModal } from '@moved/services';
import { OptionList, ModalTitle, ModalContent, ModalActions, Button } from '@moved/ui';

import CSS from './styles/SelectType.module.scss';

export const SelectType = ({ data={}, updateData, onNext }) => {
  const Modal = useModal();
  const typeOptions = [
    {
      label: 'Add new',
      value: 'new',
      icon: {
        library: 'navigation',
        symbol: 'Plus',
      },
      className: CSS.option,
    },
    {
      label: 'Choose from gallery',
      value: 'template',
      icon: {
        library: 'design',
        symbol: 'Image',
      },
      className: CSS.option,
    },
  ];

  return (
    <>
      <ModalTitle>
        <span className='contentSecondary mr-10'>1/?</span>
        Add chapters
      </ModalTitle>

      <ModalContent className={CSS.content}>
        <OptionList
          name='type'
          size='square'
          onChange={updateData}
          value={data.type}
          options={typeOptions}
        />
      </ModalContent>

      <ModalActions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => Modal.close()}
        />
        <Button
          text='Next'
          color='primary'
          disabled={!data.type}
          onClick={() => data.type && onNext()}
        />
      </ModalActions>
    </>
  );
};
