import { Calendar } from './components/Calendar';

const routes = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    exact: true,
    breadcrumb: {
      label: "Calendar",
      tooltip: "Calendar",
      icon: {
        symbol: 'Date-to',
        library: 'code',
      },
    },
  },
];

export default routes;
