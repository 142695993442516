import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'COMMON_GET_CLIENT',
  request: (id) => request.get(`/admins/partners/${id}`).then(r => get(r,'data.data.partner')),
  selector: (state, id) => get(state,`clients.${id}`),
  reducers: {
    success: (state, { response }) => ({
      ...state,
      clients: {
        ...state.clients,
        [response.id]: response,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useClient,
  usePending: useClientPending,
} = generate.redux(creator);

export const initialState = {
  clients: {},
};
