import React from 'react';

import CSS from './styles/FieldDisplay.module.scss';

// TODO: can this component be consolidated with @moved/ui FieldList?
export const FieldDisplay = ({ field }) => {
  return (
    <div className={CSS.field}>
      <div className={CSS.label}>{field.label}</div>
      <div className={CSS.value}>{field.value}</div>
    </div>
  );
};
