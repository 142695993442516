import { merge } from 'lodash';

import * as createFileRequirement from './createFileRequirement';
import * as createFileRequirementList from './createFileRequirementList';
import * as getEligibleFileRequirementLists from './getEligibleFileRequirementLists';
import * as getFileRequirements from './getFileRequirement';
import * as getFileRequirementList from './getFileRequirementList';
import * as getS3UploadUrl from './getS3UploadUrl';
import * as linkFileRequirementList from './linkFileRequirementList';
import * as reorderFileRequirements from './reorderFileRequirements';
import * as updateFileRequirement from './updateFileRequirement';
import * as updateFileRequirementList from './updateFileRequirementList';

export const reducers = [
  createFileRequirement.reducer,
  createFileRequirementList.reducer,
  getEligibleFileRequirementLists.reducer,
  getFileRequirements.reducer,
  getFileRequirementList.reducer,
  getS3UploadUrl.reducer,
  linkFileRequirementList.reducer,
  reorderFileRequirements.reducer,
  updateFileRequirement.reducer,
  updateFileRequirementList.reducer,
];

export const initialState = merge(
  createFileRequirement.initialState,
  createFileRequirementList.initialState,
  getEligibleFileRequirementLists.initialState,
  getFileRequirements.initialState,
  getFileRequirementList.initialState,
  linkFileRequirementList.initialState,
  reorderFileRequirements.initialState,
  updateFileRequirement.initialState,
  updateFileRequirementList.initialState,
);
