import React, { useState } from 'react';
import classNames from 'classnames';

import { Uploader, Icon } from '@moved/ui';

import CSS from '../styles/UploadImageModal.module.scss';

export const UploadStep = ({ asset, fileData, next }) => {

  const { name, description, requirements={}, allowCropping } = asset;
  const { dimensions={} } = requirements;
  const assetDestination = allowCropping ? 'original' : 'final';

  const [file, setFile] = useState(fileData?.[assetDestination]);

  const options = {
    maxFiles: 1,
    multiple: false,
    accept: requirements.types,
    maxSize: requirements.maxSize,
    // dimension based restrictions require custom validation
    validator: (file) => {
      const errors = [];
      if(dimensions.minWidth && file.width < dimensions.minWidth) errors.push({
        code: 'image-too-narrow',
        message: `Image must be at least ${dimensions.minWidth} wide`,
      });
      if(dimensions.minHeight && file.height < dimensions.minHeight) errors.push({
        code: 'image-too-short',
        message: `Image must be at least ${dimensions.minHeight} tall`,
      });
      if(
        (dimensions.maxAspectRatio && file.width/file.height > dimensions.maxAspectRatio.value) ||
        (dimensions.minAspectRatio && file.width/file.height < dimensions.minAspectRatio.value)
      ) errors.push({
        code: 'aspect-ratio-error',
        message: `Image must have an aspect ratio between ${dimensions.minAspectRatio.display} to ${dimensions.maxAspectRatio.display}`,
      });
      return errors.length ? errors : null
    },
  };

  const completeUpload = () => {
    if(!file) return;
    const updatedFileData = {
      ...fileData,
      cropped: undefined, // remove the crop when replacing the image (if had already gone forward and back)
      [assetDestination]: file,
    }
    return next(updatedFileData);
  };

  return (
    <div className={CSS.modal_contents}>
      <h4 className={CSS.asset_name}>{ name }</h4>
      <p>{ description }</p>
      <div className={CSS.upload_wrapper}>
        <Uploader
          className={CSS.dropzone}
          dzOptions={options}
          icon={{ symbol: 'Image', library: 'design', size: '27px' }}
          onSelect={(files) => setFile(files[0])}
          initialFiles={file && [file]}
          content={(
            <div className={CSS.extra_restrictions}>
              { dimensions.minHeight && (
                <div className={CSS.restriction}>
                  <Icon symbol={'Arrows-v'} library={'navigation'} size={'22px'}/>
                  Minimum height { dimensions.minHeight }px
                </div>
              )}
              { dimensions.minWidth && (
                <div className={CSS.restriction}>
                  <Icon symbol={'Arrows-h'} library={'navigation'} size={'22px'}/>
                  Minimum width { dimensions.minWidth }px
                </div>
              )}
              { (dimensions.minAspectRatio || dimensions.maxAspectRatio) && (
                <div className={CSS.restriction}>
                  <Icon symbol={'Size'} library={'general'} size={'22px'}/>
                  Aspect ratio { dimensions.minAspectRatio.display } to { dimensions.maxAspectRatio.display }
                </div>
              )}
            </div>
          )}
        />
      </div>
      <div className={CSS.actions}>
        <div className={CSS.spacer}></div>
        <button
          className={classNames('btn-primary','btn--small')}
          disabled={!file}
          onClick={completeUpload}
        >
          Next
        </button>
      </div>
    </div>
  );
};
