import { merge } from 'lodash';

import * as shared from './actions/redux';

import * as cableInternet from './types/cable-internet/actions/redux';
import * as documentSubmission from './types/document-submission/actions/redux';
import * as happycoInspection from './types/happyco-inspection/actions/redux';
import * as insurance from './types/insurance/actions/redux';
import * as keys from './types/keys/actions/redux';
import * as orientation from './types/orientation/actions/redux';
import * as payMoveInCosts from './types/pay-move-in-costs/actions/redux';
import * as petRegistration from './types/pet-registration/actions/redux';
import * as petscreeningVerification from './types/petscreening-verification/actions/redux';
import * as reserve from './types/reserve/actions/redux';
import * as utilitySetup from './types/utility-setup/actions/redux';

export const reducers = [
  ...cableInternet.reducers,
  ...documentSubmission.reducers,
  ...happycoInspection.reducers,
  ...insurance.reducers,
  ...keys.reducers,
  ...orientation.reducers,
  ...payMoveInCosts.reducers,
  ...petRegistration.reducers,
  ...petscreeningVerification.reducers,
  ...reserve.reducers,
  ...shared.reducers,
  ...utilitySetup.reducers,
];

export const initialState = merge(
  cableInternet.initialState,
  documentSubmission.initialState,
  happycoInspection.initialState,
  insurance.initialState,
  keys.initialState,
  orientation.initialState,
  payMoveInCosts.initialState,
  petRegistration.initialState,
  petscreeningVerification.initialState,
  reserve.initialState,
  shared.initialState,
  utilitySetup.initialState,
);
