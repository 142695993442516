import { Four04 } from '@moved/product';

import { OrderDetails } from '../orders';

import { MovesSearch } from './components/MovesSearch';
import { MoveDetailsWrapper } from './components/MoveDetailsWrapper';
import { MoveSteps } from './components/MoveSteps';
import { MoveStepRedirect } from './components/MoveStepRedirect';

const routes = [
  {
    path: '/moves',
    name: 'MovesSearch',
    component: MovesSearch,
    children: [
      {
        path: '/:moveId(\\d+)/orders/:orderId(\\d+)',
        name: 'OrderDetails',
        component: OrderDetails,
        exact: true,
      },
      {
        path: '/:moveId(\\d+)',
        name: 'MoveDetails',
        component: MoveDetailsWrapper,
        children: [
          {
            path: '/',
            name: 'MoveStepRedirect',
            component: MoveStepRedirect,
            exact: true,
          },
          {
            path: '/steps/:stepId(\\d+)',
            name: 'MoveStepDetails',
            component: MoveSteps,
            exact: true,
          },
          {
            path: '*',
            name: 'Page not found',
            component: Four04,
          },
        ]
      },
    ]
  },
  {
    path: '*',
    name: 'Page not found',
    component: Four04,
  },
];

export default routes;
