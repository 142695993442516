import { merge } from 'lodash';

import * as createInsuranceOverride from './createInsuranceOverride';
import * as createInsurancePolicy from './createInsurancePolicy';
import * as getInsuranceSettings from './getInsuranceSettings';
import * as getS3UploadUrl from './getS3UploadUrl';

export const reducers = [
  createInsuranceOverride.reducer,
  createInsurancePolicy.reducer,
  getInsuranceSettings.reducer,
  getS3UploadUrl.reducer,
];

export const initialState = merge(
  createInsuranceOverride.initialState,
  createInsurancePolicy.initialState,
  getInsuranceSettings.initialState,
);
