import React, { createContext, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useUser } from './UserContext';

const SentryContext = createContext();
const useSentry = () => useContext(SentryContext);

const SentryProvider = ({children, ...props}) => {
  const { user, hasDomain, isProxy } = useUser();

  // load the sdk
  useEffect(() => {
    // if the ENV does not have a sentry dsn, do not initialize it
    if(!process.env.REACT_APP_SENTRY_DSN) return;
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENV_NAME,
    });
  },[]); // only run on mount to load the sdk once

  // update Sentry identity if user changes
  useEffect(() => {
    // if there is no sdk loaded, quick exit.
    if(!process.env.REACT_APP_SENTRY_DSN) return;
    const sentryUser = hasDomain('guest') ? {
      id: 'guest',
      fullname: 'Guest User',
      email: '',
    } : isProxy ? {
      id: `admin::${user.id}`,
      fullname: `Admin as ${user.fullname}`,
      email: user.email,
    } : {
      id: user.id,
      fullname: user.fullname,
      email: user.email,
    };

    Sentry.configureScope((scope) => {
      scope.setUser(sentryUser);
    });
    return () => Sentry.close();
  },[user,isProxy,hasDomain]);

  // assign the sdk global to the context for proper scoped access
  const context = Sentry;

  return (
    <SentryContext.Provider value={context} {...props}>
      { children }
    </SentryContext.Provider>
  );
};

export { SentryContext, useSentry, SentryProvider };
