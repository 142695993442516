import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CLIENTS_GET_CLIENTS',
  request: (params={},cancelToken) => request.get(`/admins/partners`, { params, cancelToken }).then(r => r.data),
  selector: (state) => get(state,`clientSearch`),
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        clientSearch: {
          params: searchParams,
          totalResults: get(response,'pagination.total_items'),
          activeSet: get(response,'data.partners'),
        },
      }
    },
    failure: (state, { params: [searchParams] }) => {
      return {
        ...state,
        clientSearch: {
          params: searchParams,
          totalResults: 0,
          activeSet: [],
        }
      }
    },
  }
};


export const {
  action,
  reducer,
  useResource: useClientSearch,
  usePending: useClientSearchPending,
} = generate.redux(creator);

export const initialState = {
  clientSearch: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
