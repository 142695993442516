import { merge } from 'lodash';

import * as createLeaseUtilityAccount from './createLeaseUtilityAccount';
import * as getS3UploadUrl from './getS3UploadUrl';
import * as getUtilityCategories from './getUtilityCategories';
import * as getUtilityProviders from './getUtilityProviders';
import * as getUtilitySetup from './getUtilitySetup';
import * as updateUtilitySetup from './updateUtilitySetup';

export const reducers = [
  createLeaseUtilityAccount.reducer,
  getS3UploadUrl.reducer,
  getUtilityCategories.reducer,
  getUtilityProviders.reducer,
  getUtilitySetup.reducer,
  updateUtilitySetup.reducer,
];

export const initialState = merge(
  createLeaseUtilityAccount.initialState,
  getUtilityCategories.initialState,
  getUtilityProviders.initialState,
  getUtilitySetup.initialState,
  updateUtilitySetup.initialState,
);
