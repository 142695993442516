import { merge } from 'lodash';

import * as getCoiUpload from './getCoiUpload';
import * as reviewCoiUpload from './reviewCoiUpload';

export const initialState = merge(
  getCoiUpload.initialState,
  reviewCoiUpload.initialState,
);

export const reducers = [
  getCoiUpload.reducer,
  reviewCoiUpload.reducer,
];
