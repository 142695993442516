import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_CALENDAR_OPTIONS',
  request: (id, params) => request.get(`/admins/buildings/${id}/building-calendars/keys`, { params }).then(r => get(r, 'data.data.building_calendars')),
  selector: (state, id) => { return get(state,`buildingCalendarOptions.${id}`); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        buildingCalendarOptions: {
          ...state.buildingCalendarOptions,
          [id]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useBuildingCalendarOptions,
  usePending: useBuildingCalendarOptionsPending,
} = generate.redux(creator);

export const initialState = {
  buildingCalendarOptions: {},
};
