import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONFIG_LINK_FILE_REQUIREMENT_LIST',
  request: (data={}) => request.post(`/admins/document-task-file-requirement-list-building-tasks`, data).then(r => get(r,'data.data.document_task_file_requirement_list')),
};

export const {
  action,
  reducer,
  usePending: useLinkFileRequirementListPending,
} = generate.redux(creator);

export const initialState = {};
