import { merge } from 'lodash';

import * as getReservationRequest from './getReservationRequest';
import * as reviewReservationRequest from './reviewReservationRequest';

export const initialState = merge(
  getReservationRequest.initialState,
  reviewReservationRequest.initialState,
);

export const reducers = [
  getReservationRequest.reducer,
  reviewReservationRequest.reducer,
];
