import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_PAY_MOVE_IN_COSTS_REQUEST',
  request: (token, action, data) => request.post(`/partners/pay-move-in-costs-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.pay_move_in_costs_request')),
  reducers: {
    success: (state, { response: request, params: [token] }) => ({
      ...state,
        payMoveInCosts: {
        ...state.payMoveInCosts,
        [token]: request,
      },
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useReviewPayMoveInCostsPending,
} = generate.redux(creator);

export const initialState = {};
