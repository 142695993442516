import { generate, request } from '@moved/services';
import { remove } from 'lodash';

const creator = {
  namespace: 'CALENDAR_RESPOND_BUILDING_CALENDAR_REQUEST',
  request: (building_id, building_calendar_id, request_id, action = 'approve') =>
    request.post(`/partners/building-calendars/${building_calendar_id}/requests/${request_id}/${action}`).then(r => r.data.data.building_calendar_request),
  reducers: {
    success: (state, { response, params: [building_id] }) => {
      const newPending = state.calendarPendingRequests[building_id];
      remove(newPending,(request => request && request.id === response.id));

      return {
        ...state,
        calendarPendingRequests: {
          ...state.calendarPendingRequests,
          [building_id]: [
            ...newPending,
          ],
        },
        calendarRepliedRequests: {
          ...state.calendarRepliedRequests,
          [building_id]: [
            response,
            ...state.calendarRepliedRequests[building_id],
          ],
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useRespondCalendarRequestPending,
} = generate.redux(creator);

export const initialState = {};
