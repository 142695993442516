import React from 'react';

import { format } from '@moved/services';
import { reserve } from '@moved/product';

import { StatusHeader, DashboardLink, RenderAttributes } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const ApprovedContent = ({ request, children }) => {
  const status = {
    icon: { symbol: 'Done', color: 'green' },
    title: `You've accepted this reservation request.`,
    description: `What's next? Nothing! We'll let the resident know that the
      request has been accepted and they can proceed with booking movers.
      The reservation details are below.`,
  };

  const fields = [{
    label: 'Date approved',
    value: format.date(request.responded_at,'dateTime'),
  }];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <RenderAttributes attributes={fields} />
      <RenderAttributes attributes={reserve.getRequestFields(request)} />
      <DashboardLink/>
    </>
  );
};
