import React from 'react';

import { format } from '@moved/services';
import { FieldList } from '@moved/ui';
import { payMoveInCosts } from '@moved/product';

import { StatusHeader, DashboardLink } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const DeclinedContent = ({ request }) => {
  const status = {
    icon: { symbol: 'Error', color: 'red' },
    title: 'Declined Confirmation',
    description: (<>
      <p className='mb-15'>
        You have declined the payment of move-in costs review for {format.fullname(request.tenant)}.
      </p>
      <p className='italic'>{request.notes}</p>
    </>),
  };
  const fields = [
    {
      label: 'Date declined',
      value: format.date(request.responded_at,'dateTime'),
    },
    ...payMoveInCosts.getRequestFields(request),
  ];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <FieldList list={fields} />
      <DashboardLink/>
    </>
  );
};
