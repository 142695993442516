import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { FilterPills } from '@moved/ui';

export const DateRangeToolbar = ({ activeRange, setActiveRange }) => {
  const [startDate, setStartDate] = useState((activeRange && activeRange[0]) || DateTime.now().startOf('year').toISODate());
  const [endDate, setEndDate] = useState((activeRange && activeRange[1]) || DateTime.now().toISODate());

  useEffect(() => {
    setActiveRange([startDate,endDate]);
  },[startDate,endDate,setActiveRange]);

  const setQuickRange = (option) => {
    setStartDate(option.start);
    setEndDate(option.end);
  };

  // helpers
  const DATE_SHORT_FORMAT = DateTime.parseFormatForOpts(DateTime.DATE_SHORT);
  const convertToISO = date => DateTime.fromFormat(date,DATE_SHORT_FORMAT).toISODate();
  const convertToLocale = date => DateTime.fromISO(date).toLocaleString();

  const filters = [
    {
      type: 'dateSelect',
      label: 'Start Date',
      active: convertToLocale(startDate),
      props: {
        onSelect: value => setStartDate(convertToISO(value)),
        required: true,
      },
    },
    {
      type: 'dateSelect',
      label: 'End Date',
      active: convertToLocale(endDate),
      props: {
        onSelect: value => setEndDate(convertToISO(value)),
        required: true,
      },
    },
    { type: 'divider' },
    {
      type: 'toggle',
      label: 'This year',
      active: (
        startDate === DateTime.now().startOf('year').toISODate() &&
        endDate === DateTime.now().toISODate()
      ),
      props: {
        onSelect: () => setQuickRange({
          start: DateTime.now().startOf('year').toISODate(),
          end: DateTime.now().toISODate(),
        }),
      },
    },
    {
      type: 'toggle',
      label: 'This quarter',
      active: (
        startDate === DateTime.now().startOf('quarter').toISODate() &&
        endDate === DateTime.now().toISODate()
      ),
      props: {
        onSelect: () => setQuickRange({
          start: DateTime.now().startOf('quarter').toISODate(),
          end: DateTime.now().toISODate(),
        }),
      },
    },
    {
      type: 'toggle',
      label: 'Last year',
      active: (
        startDate === DateTime.now().minus({ year: 1 }).startOf('year').toISODate() &&
        endDate === DateTime.now().minus({ year: 1 }).endOf('year').toISODate()
      ),
      props: {
        onSelect: () => setQuickRange({
          start: DateTime.now().minus({ year: 1 }).startOf('year').toISODate(),
          end: DateTime.now().minus({ year: 1 }).endOf('year').toISODate(),
        }),
      },
    },
    {
      type: 'toggle',
      label: 'Last quarter',
      active: (
        startDate === DateTime.now().minus({ quarter: 1 }).startOf('quarter').toISODate() &&
        endDate === DateTime.now().minus({ quarter: 1 }).endOf('quarter').toISODate()
      ),
      props: {
        onSelect: () => setQuickRange({
          start: DateTime.now().minus({ quarter: 1 }).startOf('quarter').toISODate(),
          end: DateTime.now().minus({ quarter: 1 }).endOf('quarter').toISODate(),
        }),
      },
    },
  ];

  return (
    <div className='mb-30'>
      <FilterPills filters={filters}/>
    </div>
  );

};
