import React from 'react';
import classNames from 'classnames';
import { get, find, isNil } from 'lodash';

import { Icon } from '../../../sondheim/components/Icon';
import { FilterSearch } from '../FilterSearch';
import { KeywordPill } from './KeywordPill';
import { TogglePill } from './TogglePill';
import CSS from './FilterPills.module.scss';

const _renderClear = clearValues => {
  return (
    <span className={CSS.clear_icon}>
      <Icon symbol={'Close'} library={'navigation'} size={'18px'} onClick={e => { e.stopPropagation(); return clearValues();}} />
    </span>
  );
}

const _renderTypeAheadLabel = ({ active, list, label, props={} }) => {
  if(get(find(list, item => item.id === active[0]),'name')) {
    if(active.length === 1) {
      return (
        <>
          <span className={CSS.type_label}>{label}</span>
          <span className={CSS.type_value}>{find(list, item => item.id === active[0]).name}</span>
          {!props.required && _renderClear(() => props.onSelect([]))}
        </>
      );
    } else {
      return (
        <>
          <span className={CSS.type_label}>{label}</span>
          <span className={CSS.type_value}>{active.length} selected</span>
          {!props.required && _renderClear(() => props.onSelect([]))}
        </>
      );
    }
  }
  else {
    return (<span>{label}</span>);
  }
};

const _renderMultiSelectLabel = ({ active, list, label, props }) => {
  if(get(find(list, item => item.id === active[0]),'name')) {
    if(active.length === 1) {
      return (
        <>
          <span className={CSS.type_label}>{label}</span>
          <span className={CSS.type_value}>{find(list, item => item.id === active[0]).name}</span>
          {!props.required && _renderClear(() => props.onSelect([]))}
        </>
      );
    } else {
      return (
        <>
          <span className={CSS.type_label}>{label}</span>
          <span className={CSS.type_value}>{active.length} selected</span>
          {!props.required && _renderClear(() => props.onSelect([]))}
        </>
      );
    }
  }
  else {
    return (<span>{label}</span>);
  }
};

const _renderSingleSelectLabel = ({ active, list, label, props }) => {
  if(active === false || isNil(active)) return (<span>{label}</span>);
  return (
    <>
      <span>{get(find(list, item => item.id === active),'name')}</span>
      {!props.required && _renderClear(() => props.onSelect(undefined))}
    </>
  );
};

const _renderDateSelectLabel = ({ active, list, label, props }) => {
  if(active) {
    return (
      <>
        <span className={CSS.type_label}>{label}</span>
        <span>{active}</span>
        {!props.required && _renderClear(() => props.onSelect([]))}
      </>
    );
  }
  else {
    return (<span>{label}</span>);
  }
};

const _renderDateRangeSelectLabel = ({ active, list, label, props }) => {
  if(active.length > 0) {
    return (
      <>
        <span className={CSS.type_label}>{label}</span>
        <span>{active.join(' - ')}</span>
        {!props.required && _renderClear(() => props.onSelect([]))}
      </>
    );
  }
  else {
    return (<span>{label}</span>);
  }
};

export const FilterPills = ({ filters = [], clearAll }) => {

  return (
    <div className={CSS.wrapper}>
      <div className={CSS.pills}>
        {filters.map((filter,idx) => {
          switch(filter.type) {
            case 'divider':
              return <div className={CSS.divider} key={`divider_${idx}`}/>;
            case 'keyword':
              return (
                <KeywordPill
                  key={filter.label}
                  active={filter.active}
                  label={filter.label}
                  {...filter.props}
                />
              );
            case 'toggle':
              return (
                <TogglePill
                  key={filter.label}
                  active={filter.active}
                  label={filter.label}
                  {...filter.props}
                />
              );
            default:
              return (
                <FilterSearch
                  key={filter.label}
                  className={classNames(
                    CSS.pill,
                    { [CSS.pill_active]: Array.isArray(filter.active) ? filter.active.length > 0 : !(isNil(filter.active) || filter.active === false) }
                  )}
                  list={filter.list}
                  active={filter.active}
                  type={filter.type}
                  {...filter.props}
                >
                  <div className={CSS.pill_inner}>
                    {(function() {
                      switch(filter.type) {
                        case 'typeAhead':
                          return _renderTypeAheadLabel(filter);
                        case 'multiSelect':
                          return _renderMultiSelectLabel(filter);
                        case 'singleSelect':
                          return _renderSingleSelectLabel(filter);
                        case 'dateSelect':
                          return _renderDateSelectLabel(filter);
                        case 'dateRangeSelect':
                        return _renderDateRangeSelectLabel(filter);
                        default:
                          return null;
                      };
                    })()}
                  </div>
                </FilterSearch>
              );
            }
        })}
      </div>
      {clearAll && (
        <div className={CSS.clear_all}>
          <span className={CSS.clear_link} onClick={clearAll}>Clear all</span>
        </div>
      )}
    </div>
  );

};
