import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_MOVES_GET_BUILDINGS',
  request: (params={},cancelToken) => request.get(`/admins/buildings/summaries`, { params, cancelToken }).then(r => get(r,'data.data.buildings')),
  selector: (state) => get(state,'buildings'),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        buildings: response,
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useBuildings,
  usePending: useBuildingsPending,
} = generate.redux(creator);

export const initialState = {
  buildings: [],
};
