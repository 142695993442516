import React, { useState } from 'react';
import classNames from 'classnames';

import { ImageCrop } from '@moved/ui';

import CSS from '../styles/UploadImageModal.module.scss';

export const CropStep = ({ asset, fileData, next, prev }) => {
  const [crop, setCrop] = useState(fileData?.cropped ?? {});

  const { name, description, requirements={}, croppingAspectRatio } = asset;
  const submit = async () => {
    const { file } = crop;
    if(!file) return;
    return next({ ...fileData, cropped: crop, final: file });
  };

  return (
    <div className={CSS.modal_contents}>
      <h4 className={CSS.asset_name}>{ name }</h4>
      <p>{ description }</p>
      <div className={CSS.crop_wrapper}>
        <ImageCrop
          id='image-cropper'
          file={fileData.original}
          aspect={croppingAspectRatio}
          minWidth={requirements?.dimensions?.minWidth}
          minHeight={requirements?.dimensions?.minHeight}
          maxWidth={requirements?.dimensions?.maxWidth}
          maxHeight={requirements?.dimensions?.maxHeight}
          onCrop={setCrop}
          initialCrop={fileData?.cropped?.crop}
          className={CSS.crop_preview}
        />
      </div>
      <div className={CSS.actions}>
        {prev && (
            <button
            className={classNames('btn-gray','btn--small')}
            onClick={() => prev()}
          >
            Back
          </button>
        )}
        <div className={CSS.spacer}></div>
        <button
          className={classNames('btn-primary','btn--small')}
          onClick={submit}
          disabled={!crop}
        >
          Next
        </button>
      </div>
    </div>
  );
};
