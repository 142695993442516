import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, Notebox } from '@moved/ui';

import { updateOrderDetails } from '../../actions';
import { useUpdateOrderDetailsPending } from '../../actions/selectors';

import CSS from '../../styles/UpdateRateModal.module.scss';

export const UpdateOrderDetailsModal = ({ order={} }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const pending = useUpdateOrderDetailsPending();

  const { mover_booking: moverBooking } = order;
  const fields = [
    {
      label: "Addresses",
      type: 'addressList',
      name: 'stops',
      value: get(moverBooking,'stops',[]),
      required: 'Address is required',
    }
  ];

  const handleSubmit = ({ stops }) => {
    dispatch(updateOrderDetails(order.id, get(order,'mover_booking.id'), { stops }))
      .then(() => {
        Notify.default(`Details updated and notifications sent to ${get(order,'customer.contact_name')}${get(order,'vendor.name') ? ' and '+get(order,'vendor.name') : ''}`);
        Modal.close();
      })
      .catch(error => Notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Update Stops</h3>
    <Notebox
      heading='Note:'
      body={(
        <p className={'mb-5'}>
          This action assumes both customer and vendor have already agreed to
          updated addresses. Upon submission, a confirmation email will be
          immediately sent to both parties (if applicable).
        </p>
      )}
      color='blue'
      className={CSS.note_box}
    />
    <DynamicForm
      id='rate-form'
      formStyle='underline'
      className={CSS.form}
      onSubmit={handleSubmit}
      fields={fields}
    />
    <section className={classNames(CSS.actions)}>
      <span className={'btn-gray mr-15'} onClick={Modal.close}>Cancel</span>
      <button className={classNames('btn-primary',{loading:pending})} type='submit' form='rate-form'>Update</button>
    </section>
  </>);

}
