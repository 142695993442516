import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Icon } from '@moved/ui';

import CSS from './styles/ClientResult.module.scss';
import columnsCSS from './styles/columns.module.scss';

export const ClientResult = ({ item: client = {}, className }) => {
  return (
    <Link
      className={classNames(className, CSS.box)}
      to={`/admin/clients/${client.id}`}>

      <div className={CSS.box_top}>

        <div className={classNames(columnsCSS.name, CSS.client_name)}>
          <div
            className={CSS.splash}
            style={{
              ...{backgroundImage: client.logo_url ?
                `url('${client.logo_url}')` : null,
              }
            }}
          />
          <div className={columnsCSS.name_text}>{client.name}</div>
        </div>

        <div className={columnsCSS.slug}>
          {client.slug}
        </div>

        <div className={columnsCSS.contact_name}>
          {client.contact_name}
        </div>

        <div className={columnsCSS.num_buildings}>
            {client.total_buildings}
        </div>

        <div className={columnsCSS.num_admins}>
          {client.total_admins}
        </div>

        <div className={columnsCSS.arrow}>
          <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow_icon} />
        </div>

      </div>

    </Link>
  );
};
