import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { pull, uniq, clone } from 'lodash';

import { Icon } from '../../../../sondheim/components/Icon';
import CSS from './styles/FilterMultiSelect.module.scss';

const _handleResultClick = (value, isActive, active, setActive) => {
  let newList = clone(active);

  if(isActive) return setActive(pull(newList,value));

  newList.push(value);
  return setActive(uniq(newList));
};

export const FilterMultiSelect = ({ list, title = 'Select', searchAction, setVisible, popOverClass, active, setActive, single }) => {

  // HOOKS
  const [ term, setTerm ] = useState('');
  const [ termList, setTermList ] = useState(list);

  useEffect(() => {
    if(term.length > 1 && searchAction) {
      // setTermList(searchAction(term));
      searchAction(term)
        .then(resp => setTermList(resp));
    }
    setTermList(list.filter(item => (item.name || '').toLowerCase().indexOf(term.toLowerCase()) !== -1));
  // eslint-disable-next-line
  },[term]);

  return (
    <>
      <div className={CSS.search_bar}>
        <input className={CSS.search_input} id="popSearch" type="text" placeholder="Search&hellip;" autoComplete="off" value={term} onChange={(e,input) => setTerm(e.target.value)} />
        <label className={CSS.input_icon} htmlFor="popSearch">
          <Icon symbol={'Search'} library={'general'} size={'24px'} />
        </label>
        {term.length > 0 && (
          <div className={CSS.input_clear}  onClick={e => setTerm('')}>
            <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
          </div>
        )}
      </div>
      <div className={CSS.results_section}>
        <div className={CSS.results_title}>
          <h6>
            {term.length > 0 ? `Results (${termList.length})` : 'All'}
          </h6>
          {(!single && termList.length > 0) && (
            termList.filter(item => active.indexOf(item.id) !== -1).length < termList.length ? (
              <span className={classNames(CSS.link,'faux-link')} onClick={e => setActive(termList.map(item => item.id))}>Select all</span>
            ) : (
              <span className={classNames(CSS.link,'faux-link')} onClick={e => setActive([])}>Deselect all</span>
            )
          )}

        </div>
        <ul className={CSS.results}>
          {termList.map((item, idx) => {
            const isActive = active.indexOf(item.id) !== -1;

            return (
              <li className={CSS.result} key={item.id} >

                <input
                  className={CSS.check_input}
                  checked={isActive}
                  id={`check_${item.id}`}
                  value={item.id}
                  type="checkbox"
                  onChange ={
                    single ?
                    (e => setActive([item.id])) :
                    (e => _handleResultClick(item.id, isActive, active, setActive))
                  }
                />

                <label htmlFor={`check_${item.id}`}>
                  <Icon className={CSS.on} symbol={'Checkbox-on'} library={'code'} size={'24px'} />
                  <Icon className={CSS.off} symbol={'Checkbox-off'} library={'code'} size={'24px'} />
                  <span>{item.name}</span>
                </label>
                { !single && (<span className={classNames(CSS.link,'faux-link')} onClick={e => setActive([item.id])}>Only</span>)}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
