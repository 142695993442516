import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_PET_REGISTRATION_REQUEST',
  request: (token, action, data) => request.post(`/partners/pet-registration-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.pet_registration_request')),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
          petRegistrations: {
          ...state.petRegistrations,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReviewPetRegistrationPending,
} = generate.redux(creator);

export const initialState = {};
