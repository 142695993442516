import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Icon } from '../../../sondheim/components/Icon';
import { PopOver } from '../PopOver';
import CSS from './Breadcrumbs.module.scss';

const WithPopOver = (props) => {
  const { tooltip: Tooltip, children, isActive } = props;
  return ((Tooltip && !isActive) ?
    <PopOver
      customClass={CSS.popover}
      customArrowClass={CSS.popover_arrow}
      hideOnScroll={true}
      placement="bottom"
      trigger={"hover"}
      tooltip={
        <span className={CSS.popover_text}>
          {(typeof Tooltip === 'function') ? (<Tooltip {...props} />) : Tooltip}
        </span>
      }
    >
      {children}
    </PopOver>
    : children
  )
};

export const Breadcrumbs = ({ crumbs = [], className }) => {

  const history = useHistory();

  if(crumbs.length === 0) return null;
  return (
    <div className={classNames(CSS.bread,className)}>
      {crumbs.map(({breadcrumb, componentProps}, idx) => {
        const isActive = idx === crumbs.length-1;

        const { match } = componentProps;
        const { icon = {}, label: Label } = breadcrumb;

        return (
          <React.Fragment key={match.url}>
            <WithPopOver {...breadcrumb} {...componentProps} isActive={isActive}>
              <div
                className={classNames(CSS.crumb,{ [CSS.active]: isActive})}
                onClick={() => history.push(match.url)}
              >
                {icon.symbol && <Icon className={CSS.icon} {...icon} />}
                {(!icon.symbol || isActive) && (
                  <span className={CSS.label}>
                    {(typeof Label === 'function') ? (<Label {...breadcrumb} {...componentProps} isActive={isActive} breadCSS={CSS} />) : Label}
                  </span>
                )}
              </div>
            </WithPopOver>
            {!isActive && (<Icon symbol='Chevron-right' library='navigation' className={CSS.next_icon}/>)}
          </React.Fragment>
        )
      })}
    </div>
  );
};
