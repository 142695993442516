import { merge } from 'lodash';

import * as getClientSearch from './getClientSearch';
import * as updateClient from './updateClient';

export const reducers = [
  getClientSearch.reducer,
  updateClient.reducer,
];

export const initialState = merge(
  getClientSearch.initialState,
);
