import { get, isNil } from 'lodash';

import {
  getSummaryFields as getMoverBookingFields,
  getMoverBookingServiceOrderFields
} from '../bookMovers/getSummaryFields';

// Map vendor option to display value
// also used in COI override fields
export const getMovingMethodDisplay = (option) => {
  switch(option) {
    case 'professional-movers':
      return `I'm hiring professional movers`;
    case 'hiring-labor': // support for legacy options
    case 'truck-rental': // support for legacy options
    case 'container-rental': // support for legacy options
    case 'diy':
      return 'Moving myself';
  }
};

export const getSummaryFields = (taskDetails) => {
  const selectedMovingCompanyAds = taskDetails?.selected_moving_company_ads;
  return [
    {
      label: 'Method',
      value: getMovingMethodDisplay(taskDetails?.selected_moving_method),
      icon: { symbol: 'Moving-logistics', library: 'task' },
    },
    taskDetails?.selected_moving_method === 'professional-movers' && selectedMovingCompanyAds != null && {
      label: 'Requested contact',
      value: selectedMovingCompanyAds?.length ? selectedMovingCompanyAds.map(company => company.name).join(', ') : 'No thanks',
      icon: { symbol: 'Sending', library: 'communication' },
    },
  ].filter(v=>v&&v.value);
};
