import { merge } from 'lodash';

import * as getPetRegistrationRequirements from './getPetRegistrationRequirements';
import * as updatePetRegistrationRequirements from './updatePetRegistrationRequirements';

export const reducers = [
  getPetRegistrationRequirements.reducer,
  updatePetRegistrationRequirements.reducer,
];

export const initialState = merge(
  getPetRegistrationRequirements.initialState,
  updatePetRegistrationRequirements.initialState,
);
