import React from 'react';
import { get } from 'lodash';

import { Icon } from '@moved/ui';

import { SectionDisplay } from './SectionDisplay';
import { PaymentMethod } from './PaymentMethod';
import { PaymentHistory } from './PaymentHistory';

import { ResolvePaymentIssue } from '../buttons';

import CSS from '../../styles/PaymentSection.module.scss';

export const PaymentSection = ({ order }) => {

  const details =   {
      title: 'Payment details',
      actions: [
        <ResolvePaymentIssue order={order} key='resolve-payment-issue'/>,
      ],
      columns: [
        [
          {
            label: 'Default card',
            value: (<>
              <PaymentMethod payment={get(order,'default_payment_method')} size={24}/>
              { get(order,'customer.stripe_id') && (
                <a className={CSS.stripe_link} href={`${process.env.REACT_APP_STRIPE_CLIENT_URL}/customers/${get(order,'customer.stripe_id')}`} target='_blank' rel="noopener noreferrer">
                  View in Stripe <Icon library='navigation' symbol='Up-right' size='16px' color='blue'/>
                </a>
              )}
            </>),
          },
        ],
        [
          {
            label: 'Status',
            value: (
              <PaymentHistory payments={get(order, 'finalized_payments')} preAuths={get(order,'payment_pre_authorization_attempts')}/>
            ),
          },
        ],
      ],
    };

  return (
    <SectionDisplay section={details}/>
  );

};
