import React from 'react';
import { useField } from 'formik';
import { get, isNil } from 'lodash';
import classNames from 'classnames';

import { useModal, format } from '@moved/services';

import { Icon } from '../../../../sondheim/components/Icon';
import { AddressModal } from '../../AddressModal';
import { InputWrapper } from '../InputWrapper';
import CSS from '../StyledForm.module.scss';

// validation helper
const isCompleteAddress = (stop, { simple, showDeed }) => {
  return (
    get(stop,'type') && // type always required
    get(stop,'address.google_place_id') && // google_place_id always required
    (simple || !['apartment','other'].includes(get(stop,'type')) || ( // if simple or type is not apartment/other these fields are not presented
      get(stop,'floor') && // floor is required when present
      !isNil(get(stop,'flights_of_stairs')) // flights_of_stairs is required when present
    )) &&
    (
      get(stop,'type') === 'storage' || // deed is not included with storage addresses
      !showDeed || // deed is optional with its own flag
      get(stop,'deed')
    )
  );
}

export const FieldAddress = (props) => {
  // deconstruct props for code readability
  const { input, remove, index } = props;
  const { showDeed = true, simple, onChange } = input;

  const Modal = useModal();
  const [field, { value, error, touched }, { setValue }] = useField({
    ...input,
    validate: (value) => {
      if(!value) return 'Address is required';
      return !isCompleteAddress(value,{showDeed,simple}) && 'Missing required details';
    },
  });

  const _onChange = (value) => {
    // Passing up the onchange event for use in parent
    onChange && onChange(setValue);
    return setValue(value, true);
  };

  const showModal = (e) => {
    e.preventDefault();
    Modal.open(
      <AddressModal
        input={input}
        field={field}
        showDeed={showDeed}
      />,
      {
        onClose: (addressObject) => {
          if(!addressObject) return null;
          return _onChange(addressObject);
        }
      }
    );
  };

  const classes = classNames(CSS.faux_input, CSS.address_field, {
    [CSS.has_error]: touched && error,
    [CSS.has_value]: value,
  });

  return (
    <div className={CSS.address_item}>
      <div className={CSS.address_content}>
        {input.stopIcon && (<span className={CSS.address_icon} />)}
        <InputWrapper {...props}>
          <div className={classes} onClick={(e) => showModal(e)}>
            { isCompleteAddress(value,{showDeed,simple}) ? format.address(get(value,'address')) : null }
          </div>
          <input
            name={field.name}
            value={value}
            type="hidden"
          />
        </InputWrapper>
        {remove && (
          <div className={CSS.address_remove} onClick={() => remove(index)}>
            <Icon symbol={'Error-circle'} library={'code'} color='red' />
          </div>
        )}
      </div>
    </div>
  );
};
