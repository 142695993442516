import React, { useState, useEffect } from 'react';
import moment from 'moment'

import { Icon } from '../../../../sondheim/components/Icon';
import { MonthlyCalendar } from '../../MonthlyCalendar';
import CSS from './styles/FilterDateSelect.module.scss';

export const FilterDateSelect = ({ list, title = 'Select', week = false, setVisible, active, setActive }) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  // HOOKS
  const [inputDate, setInputDate] = useState(active);

  const _setDate = date => {
    setActive(date.format(DATE_FORMAT));
  };

  const _handleInputBlur = (e,input) => {
    // Not ideal, not sure if there's any other way to fix this
    // another reason to get rid of moment - [Evie]
    const typedDate = moment(new Date(e.target.value));
    if(typedDate.isValid()) {
      setInputDate(typedDate.format(DATE_FORMAT));
      return setActive(typedDate.format(DATE_FORMAT));
    } else {
      return setInputDate(active);
    }
  };

  useEffect(() => {
    setInputDate(active);
  },[active]);

  return (
    <>
      <div className={CSS.date_bar}>
        <input
          className={CSS.date_input}
          id="dateInput"
          value={inputDate}
          onChange={(e,input) => setInputDate(e.target.value)}
          onBlur={_handleInputBlur}
          onKeyUp={(e,input) => {
            if (e.keyCode === 13) {
              return _handleInputBlur(e,input);
            } else {
              return null;
            }
          }}
          type="text"
          placeholder="Enter date&hellip;"
          autoComplete="off"
        />
        <label className={CSS.input_icon} htmlFor="dateInput">
          <Icon symbol={'Date'} library={'code'} size={'24px'} />
        </label>
      </div>
      <div className={CSS.calendar}>
        <MonthlyCalendar
          selected={active ? moment(active,'MM/DD/YYYY') : moment()}
          onSelect={_setDate}
          moment={active ? moment(active,'MM/DD/YYYY') : moment()}
          highlightWeek={week}
          highlightDay={!week}
          hoverWeek={week}
        />
      </div>
    </>
  );
};
