import React from 'react';
import { get } from 'lodash';

import { CreditCardLogo } from '@moved/ui';

export const PaymentMethod = ({ payment, size=18 }) => (
  <div className={'flex items-center'}>
    <CreditCardLogo symbol={get(payment,'card_brand')} width={size} />&nbsp;
    <span>**** **** **** { get(payment,'card_last4') }</span>
  </div>
);
