import { generate, request } from '@moved/services';
import moment from 'moment';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_GET_PARTNER_CALENDAR',
  request: (id, week_of = moment().format('YYYY-MM-DD')) =>
    request.get(`/partners/building-calendars/${id}`, {params: { week_of }}).then(r => r.data.data),
  selector: (state, id) => {
    if(!id) return null;
    return get(state,`partnerCalendar[${id}]`) || {};
  },
  reducers: {
    success: (state, { response: reservation, params: [id, date] }) => {

      // Format for easier access to slots by date
      const availability = get(reservation,'building_calendar.availability').reduce(function(result, item) {
        result[item.date] = { ...item }
        return result;
      }, {});

      return {
        ...state,
        partnerCalendar: {
          [id]: {
            id: get(reservation,'building_calendar.id'),
            appointments: get(reservation,'building_calendar.appointments'),
            availability
          },
        },

      };
    },
    failure: (state) => {
      return {
        ...state,
        partnerCalendar: initialState.partnerCalendar,
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: usePartnerCalendar,
  usePending: usePartnerCalendarPending,
} = generate.redux(creator);

export const initialState = {
  partnerCalendar: {},
};
