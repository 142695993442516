import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { DynamicForm } from '@moved/ui';
import { useUser } from '@moved/services';

import { ImageManager } from '../../branding';
import { getAssetUploadUrl } from '../../branding/actions';

import { updateClient } from '../actions';
import { useClient } from '../../../../common/actions/selectors';

export const ClientSettings = (props) => {
  // HOOKS
  const { clientId } = useParams();
  const client = useClient(clientId);
  const { hasAbilities } = useUser();

  const getUploadUrl = (assetType) => getAssetUploadUrl('partners',client.id,assetType);

  const fields = [
    {
      type: 'title',
      label: 'General'
    },
    {
      type: 'text',
      name: 'name',
      label: 'Client name',
      value: get(client,'name'),
      readOnly: true,
      half: true,
    },
    {
      type: 'text',
      name: 'slug',
      label: 'URL slug',
      value: get(client,'slug'),
      readOnly: true,
      half: true,
    },
  ];

  const clientAssets = [
    {
      name: 'Client logo',
      description: 'The logo appears on both the client\'s sign in page and homepage',
      type: 'logo',
    },
    {
      name: 'Welcome image',
      description: 'This image appears on the client\'s sign in page',
      type: 'welcome-image',
    },
    {
      name: 'Dashboard image',
      description: 'This image appears on the tenant\'s dashboard',
      type: 'dashboard-image',
    },
  ];

  return (
    <>

      <DynamicForm id='client-details' formStyle='underline' fields={fields} />

      { clientAssets.map(asset => (
        <ImageManager
          key={asset.type}
          assetType={asset.type}
          name={asset.name}
          description={asset.description}
          brandable={client}
          getUploadUrl={getUploadUrl}
          update={updateClient}
          readonly={!hasAbilities('UpdatePartners')}
        />
      ))}

    </>
  );
};
