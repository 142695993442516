import { merge } from 'lodash';

import * as getRentersInsurancePolicy from './getRentersInsurancePolicy';
import * as reviewRentersInsurancePolicy from './reviewRentersInsurancePolicy';

export const initialState = merge(
  getRentersInsurancePolicy.initialState,
  reviewRentersInsurancePolicy.initialState,
);

export const reducers = [
  getRentersInsurancePolicy.reducer,
  reviewRentersInsurancePolicy.reducer,
];
