import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_PET_REGISTRATION_REQUEST',
  request: (token) => request.get(`/partners/pet-registration-requests/token/${token}`).then(r => r.data.data.pet_registration_request),
  selector: (state, token) => get(state, `petRegistrations.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: usePetRegistration,
  usePending: usePetRegistrationPending,
} = generate.redux(creator);

export const initialState = {
  petRegistrations: {},
};
