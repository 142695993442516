import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { format, useModal, useNotify } from '@moved/services';
import { reserve } from '@moved/product';

import { DeclineReasonModal, StatusHeader, RenderAttributes } from '../../shared/components';

import { reviewReservationRequest } from '../actions';
import { useReviewReservationRequestPending } from '../actions/selectors';

import CSS from '../../shared/styles/shared.module.scss';

export const ReviewContent = ({ request }) => {

  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const { token } = useParams();
  const [approvalPending, setApprovalPending] = useState();

  const status = {
    icon: { symbol: 'Info', color: 'blue' },
    title: 'Reservation Request Review',
    description: `Please review the reservation request below and indicate whether it is approved or declined.`,
  };
  const triggerApproval = () => {
    if(approvalPending) return;
    setApprovalPending(true);
    dispatch(reviewReservationRequest(token,'approve'))
      .catch(error => {
        setApprovalPending(false);
        Notify.error();
      })
  };
  const triggerReject = () => {
    const reject = (data) => dispatch(reviewReservationRequest(token,'reject',data))
      .then(() => Modal.close())
      .catch(error => Notify.error());
    Modal.open(<DeclineReasonModal submit={reject} usePending={useReviewReservationRequestPending}/>);
  };
  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <RenderAttributes attributes={reserve.getRequestFields(request)} />
      <div className={classNames(CSS.actions)}>
        <button className={classNames('btn-green-dark',{loading:approvalPending})} onClick={triggerApproval}>Approve</button>
        <button className={classNames('btn-ghost-red')} onClick={triggerReject}>Decline</button>
      </div>
    </>
  );
};
