import { ChapterDetails, ChapterList } from './components';

export const routes = [
  {
    path: '/chapters',
    name: 'OrientationChapters',
    children: [
      {
        path: '/',
        name: 'OrientationChapterList',
        component: ChapterList,
        exact: true,
      },
      {
        path: '/:chapter_id(\\d+)',
        name: 'OrientationChapterDetails',
        component: ChapterDetails,
        breadcrumb: { label: 'Chapter details' },
        exact: true,
      },
    ],
  },
];
