import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';

import { format, helpers } from '@moved/services';

import { Icon } from '../../Icon';
import CSS from './Button.module.scss';

const getClassName = (size, color) => {
  return CSS[size+format.capitalize(color)];
};

const iconSizeMap = {
  small:'18px',
  default:'24px',
  large:'24px',
};

export const Button = ({
  text,
  size='default',
  color='primary',
  onClick=noop,
  icon,
  iconPosition='left',
  className,
  showWarning,
  style,
  disabled=false,
  form,
  customColor,
}) => {

  const baseColor = color === 'custom' ? (helpers.isHexDark(customColor) ? 'primary' : 'secondary') : color;

  const classes = [
    getClassName(size,baseColor),
    color === 'custom' && CSS.customColor,
    icon && (text ? (iconPosition === 'left' ? CSS.withIconLeft : CSS.withIconRight) : CSS.withIconOnly),
    showWarning && (CSS.warning),
    className,
  ].filter(Boolean);
  const ButtonIcon = icon && (
    <Icon className={CSS.buttonIcon} library={icon.library} symbol={icon.symbol} size={iconSizeMap[size]} />
  );
  return (
    <button
      className={classNames(...classes)}
      onClick={onClick}
      disabled={disabled}
      form={form}
      style={{
        backgroundColor: color === 'custom' && customColor,
        ...style
      }}
      type={form ? 'submit' : 'button'}
    >
      { iconPosition === 'left' && ButtonIcon }
      { text }
      { iconPosition === 'right' && ButtonIcon }
    </button>
  );
};

Button.propTypes = {
  /** The text of the button */
  text: PropTypes.string,
  /** Which size to render the button */
  size: PropTypes.oneOf(['small','default','large']),
  /** Which color style to apply to the button */
  color: PropTypes.oneOf(['primary','secondary','tertiary','custom']),
  /** onClick handler function */
  onClick: PropTypes.func,
  /** (Optional) Icon to display in the button */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** (Optional) Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** (Optional) Custom class name to add to the button */
  className: PropTypes.string,
  /** Flag to activate 'red' colors for the button */
  showWarning: PropTypes.bool,
  /** (Optional) Custom styles to add to the button */
  style: PropTypes.object,
  /** Flag to disable the button */
  disabled: PropTypes.bool,
  /** (Optional) Form name if this button should submit a form */
  form: PropTypes.string,
};
