import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_GET_PETSCREENING_VERIFICATION_INSTRUCTIONS',
  request: (taskId) => request.get(`/admins/building-tasks/${taskId}/petscreening-verification-instructions`).then(r => r?.data?.data?.instruction_content),
  selector: (state, taskId) => state?.petscreeningVerificationInstructions?.[taskId],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      petscreeningVerificationInstructions: {
        ...state.petscreeningVerificationInstructions,
        [id]: response,
      }
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      petscreeningVerificationInstructions: {
        ...state.petscreeningVerificationInstructions,
        [id]: null,
      }
    }),
  }
};

export const {
  action,
  reducer,
  useResource: usePetscreeningVerificationInstructions,
  usePending: usePetscreeningVerificationInstructionsPending,
} = generate.redux(creator);

export const initialState = {
  petscreeningVerificationInstructions: {},
};
