import { merge } from 'lodash';

import * as getVehicleRegistration from './getVehicleRegistration';
import * as reviewVehicleRegistration from './reviewVehicleRegistration';

export const initialState = merge(
  getVehicleRegistration.initialState,
  reviewVehicleRegistration.initialState,
);

export const reducers = [
  getVehicleRegistration.reducer,
  reviewVehicleRegistration.reducer,
];
