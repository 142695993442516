import { merge } from 'lodash';

import * as getMoveDetails from './getMoveDetails';
import * as updateMove from './updateMove';
import * as addCoupon from './addCoupon';
import * as bookMove from './bookMove';
import * as movesSearch from './movesSearch';

export const initialState = merge(
  getMoveDetails.initialState,
  updateMove.initialState,
  addCoupon.initialState,
  bookMove.initialState,
  movesSearch.initialState,
);

export const reducers = [
  getMoveDetails.reducer,
  updateMove.reducer,
  addCoupon.reducer,
  bookMove.reducer,
  movesSearch.reducer,
];
