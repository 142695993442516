import { merge } from 'lodash';

import * as getPartnerCoupons from './getPartnerCoupons';
import * as getMultiplePartnerCoupons from './getMultiplePartnerCoupons';
import * as getMovedDiscounts from './getMovedDiscounts';

export const initialState = merge(
  getPartnerCoupons.initialState,
  getMultiplePartnerCoupons.initialState,
  getMovedDiscounts.initialState,
);

export const reducers = [
  getPartnerCoupons.reducer,
  getMultiplePartnerCoupons.reducer,
  getMovedDiscounts.reducer,
];
