import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { Toggle } from '@moved/ui';

import CSS from '../styles/Snow.module.scss';

export const Snow = () => {
  const now = moment();
  const Let = now.isAfter(moment(`${now.year()}-12-24`,'YYYY-MM-DD'));
  const It = now.isBefore(moment(`${now.year()}-01-15`,'YYYY-MM-DD'));
  const Snow = Cookies.get('disableSnow') !== 'true';

  const [snowing, setSnowing] = useState(!!Snow);

  useEffect(() => {
    if(!(Let || It)) return;
    let loadInterval;
    if(Snow) {
      loadInterval = setInterval(() => {
        window.snowStorm = window.snowStorm || {};
        window.snowStorm.autoStart = false;
        window.snowStorm.targetElement = "snow";
        window.snowStorm.followMouse = false;
        window.snowStorm.freezeOnBlur = false;
        window.snowStorm.vMaxX = 0;
        window.snowStorm.vMaxY = 1;
        window.snowStorm.zIndex = 25;
        window.snowStorm.snowColor = '#00000066';
        if(!window.snowStorm.start) return;
        window.snowStorm.start();
        clearInterval(loadInterval);
      },100);
      return () => clearInterval(loadInterval);
    }
  },[]); // eslint-disable-line

  if(!(Let || It)) return;

  const toggleSnow = (data) => {
    setSnowing(!snowing);
    Cookies.set('disableSnow',snowing);
    snowing ? window.snowStorm.freeze() : window.snowStorm.resume();
  };

  return (
    <>
      <Helmet>
        <script src="/snowstorm-min.js" type="text/javascript" />
      </Helmet>
      <div className={CSS.snowToggle}>
        <Toggle
          name='snowToggle'
          value={snowing}
          onChange={toggleSnow}
          label='Let it snow'
        />
      </div>
      <div id='snow' className={classNames(CSS.snowfield,{[CSS.hidden]:!snowing})}/>
    </>
  );
};
