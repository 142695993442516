import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONFIG_GET_ELIGIBLE_TASK_TYPES',
  request: (id, params) => request.get(`/admins/building-task-lists/${id}/eligible-task-types`, { params }).then(r => get(r,'data.data.task_types')),
  selector: (state, id) => { return get(state,`eligibleTaskTypes.${id}`) || []; },
  reducers: {
    success: (state, { response: properties, params: [id] }) => {
      return {
        ...state,
        eligibleTaskTypes: {
          ...state.eligibleTaskTypes,
          [id]: properties
        },
      };
    },
    failure: (state, { params: [id] }) => {
      return {
        ...state,
        eligibleTaskTypes: {
          ...state.eligibleTaskTypes,
          [id]: [],
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useEligibleTaskTypes,
  usePending: useEligibleTaskTypesPending,
} = generate.redux(creator);

export const initialState = {
  eligibleTaskTypes: {},
};
