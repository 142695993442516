import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_MOVES_TAG_SEARCH',
  request: (data, cancelToken) => request.get(`/v2/tags`,{params:{where:{name:{'$startswith':data}}},cancelToken}).then(r => get(r,'data')),
};

export const {
  action,
} = generate.redux(creator);
