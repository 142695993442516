import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Table.module.scss';

export const TableHeader = ({
  columns=[],
  options={},
  className,
}) => (
  <div className={classNames(CSS.headerRow,className)}>
    { columns.map((column={},index) => (
      <div key={column.name || index} className={classNames('labelS contentSecondary',CSS.headerColumn,column.className)}>{column.label}</div>
    ))}
    { options.showActions && (
      <div className={classNames('labelS contentSecondary',CSS.headerColumn,CSS.actions)}></div>
    )}
  </div>
);

TableHeader.propTypes = {
  /** Array of table cells to use in this row */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** Unique key property per column */
    name: PropTypes.string,
    /** Value to use for the contents of this column */
    label: PropTypes.node,
    /** (optional) TODO: Flag to activate sorting for this column */
    sortable: PropTypes.bool,
    /** (optional) Class name to add to the column */
    className: PropTypes.string,
  })).isRequired,
  /** Collection of table level options */
  options: PropTypes.shape({
    /** Flag to include an empty column for alignment of an actions column in the table */
    showActions: PropTypes.bool,
  }),
  /** (optional) class name to add to the row */
  className: PropTypes.string
};
