import { FileRequirementDetails } from './components/FileRequirementDetails';

  // orientation task specific sub-routes
export const routes = [
  {
    path: '/documents/:documentID(\\d+)',
    name: 'FileRequirementDetails',
    component: FileRequirementDetails,
    breadcrumb: { label: 'Document details' },
    exact: true,
  },
];
