import React from 'react';
import { useParams } from 'react-router-dom';

import { useDrawer } from '@moved/services';
import { Icon, Button } from '@moved/ui';

import { useTenantEvent } from '../actions/selectors';

import { AdminTaskCompletionDrawer } from './AdminTaskCompletionDrawer';

import CSS from './styles/AdminCompleteTask.module.scss';

const defaultMessage = 'This task has not been completed.'

export const AdminCompleteTask = ({ task, message=defaultMessage }) => {
  const Drawer = useDrawer();
  const { id } = useParams();
  const tenantEvent = useTenantEvent(id);

  const openAdminCompletionOverlay = () => {
    Drawer.open(
      <AdminTaskCompletionDrawer task={task} tenantEvent={tenantEvent} />,
    );
  }
  return (
    <div className={CSS.box_wrapper}>
      <Icon className={CSS.icon} symbol={'Warning-1-circle'} library={'code'} size={'32px'} />
      <div className='labelM contentPrimary marginTop-8'>
        { message }
      </div>
      <Button
        size='default'
        color='secondary'
        text='Complete task'
        className='marginTop-20'
        onClick={openAdminCompletionOverlay}
      />
    </div>
  );
};
