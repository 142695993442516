import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { format } from '@moved/services';
import { EventList } from '@moved/ui';

import CSS from '../../styles/VendorOfferHistory.module.scss';

export const VendorOfferHistory = ({ offers }) => {
  const events = [];
  offers.forEach((offer) => {
    events.push({
      key: `${offer.id}-created`,
      title: `Offer sent to ${get(offer,'vendor.name')}`,
      timestamp: offer.created_at
    });
    switch (offer.status) {
      case 'pending':
        events.push({
          key: `${offer.id}-${offer.status}`,
          color: 'yellow',
          title: `Pending offer to ${get(offer,'vendor.name')}`,
          timestamp: offer.expires_at
        });
        break;
      case 'accepted':
        events.push({
          key: `${offer.id}-${offer.status}`,
          color: 'green',
          title: `Accepted by ${get(offer,'vendor.name')}`,
          timestamp: offer.responded_at
        });
        break;
      case 'declined':
        events.push({
          key: `${offer.id}-${offer.status}`,
          color: 'red',
          title: `Declined by ${get(offer,'vendor.name')}`,
          timestamp: offer.responded_at
        });
        break;
      case 'expired':
        events.push({
          key: `${offer.id}-${offer.status}`,
          color: 'red',
          title: `Expired by ${get(offer,'vendor.name')}`,
          timestamp: offer.expires_at
        });
        break;
      default: return;
    }
  });

  events.sort((a,b) => {
    return moment.utc(a.timestamp).isBefore(b.timestamp) ? 1 : -1;
  });

  return (
    <EventList events={events.map(({ timestamp, ...event }) => ({
      ...event,
      content: (
        <div className={CSS.event_content}>{ format.date(timestamp,'dateTime') }</div>
      )
    }))}/>
  );
}
