import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_TRIGGER_INVITE',
  request: (partnerId, tenantEventId, params={}) => {
    // Use admin endpoint if no partner is passed to the action
    const url = partnerId ?
      `/partners/${partnerId}/tenant-events/${tenantEventId}/signup-reminders` :
      `/admins/tenant-events/${tenantEventId}/signup-reminders`;
    return request.post(url, params).then(r => r.data)
  },
};

export const {
  action,
  reducer,
  usePending: useInvitePending,
} = generate.redux(creator);

export const initialState = {};
