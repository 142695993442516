import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'COMMON_GET_PARTNER',
  request: (partnerSlug) => request.get(`/partners/${partnerSlug}`).then(r => get(r,'data.data.partner')),
  selector: (state, partnerSlug) => get(state,`partners.${partnerSlug}`),
  reducers: {
    success: (state, { response: partner, params: [partnerSlug] }) => ({
      ...state,
      partners: {
        ...state.partners,
        [partnerSlug]: partner,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: usePartner,
  usePending: usePartnerPending,
} = generate.redux(creator);

export const initialState = {
  partners: {},
};
