import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { DynamicForm, ModalTitle, ModalContent, ModalActions, Button } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import { orientationFields} from '../orientationFields'
import { createOrientationChapter } from '../../actions';
import { useCreateOrientationChapterPending } from '../../actions/selectors';

import CSS from './styles/CreateNewChapter.module.scss';

export const CreateNewChapter = ({ taskId, order = 1, onBack }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const Modal = useModal();
  const Notify = useNotify();

  // MOVE DETAILS STATE
  const pending = useCreateOrientationChapterPending();

  const createChapter = (data) => {
    dispatch(createOrientationChapter(taskId, data))
      .then(resp => {
        Notify.default(`Chapter created!`);
        Modal.close();
        return history.push(`${location.pathname}/${resp.id}`);
      })
      .catch(error => Notify.error(format.error(error)));
  };

  const fields = [
    ...cloneDeep(orientationFields),
    {
      label: 'Order',
      type: 'hidden',
      name: 'order',
      value: order.toString(),
    },
  ];

  return (
    <>

      <ModalTitle>
        <span className='contentSecondary mr-10'>2/2</span>
        Add new chapter
      </ModalTitle>

      <ModalContent className={CSS.content}>
        <DynamicForm
          id='new-chapter-form'
          formStyle='underline'
          fields={fields}
          onSubmit={createChapter}
        />
      </ModalContent>

      <ModalActions>
        <Button
          text='Back'
          icon={{ library: 'navigation', symbol: 'Arrow-left' }}
          color='secondary'
          disabled={pending}
          onClick={() => !pending && onBack()}
        />
        <div className={CSS.spacer} />
        <Button
          text='Cancel'
          color='secondary'
          disabled={pending}
          onClick={() => !pending && Modal.close()}
        />
        <Button
          text="Create chapter"
          color='primary'
          form="new-chapter-form"
          disabled={pending}
        />
      </ModalActions>

    </>
  );
};
