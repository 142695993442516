import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useNotify, format, useUser } from '@moved/services';
import { Icon, DynamicForm } from '@moved/ui';

import { updateMove } from '../actions';
import CSS from '../styles/MoveDetailsNotes.module.scss';

export const MoveDetailsNotes = ({ move }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { hasAbilities } = useUser();

  const [notePending, setNotePending] = useState(false);
  const [noteComplete, setNoteComplete] = useState(false);

  const submitNotes = (formData) => {
    setNotePending(true);
    dispatch(updateMove(move.id, formData))
      .then(() => {
        setNotePending(false);
        setNoteComplete(true);
        return setTimeout(() => setNoteComplete(false),1200)
      })
      .catch(error => Notify.error(format.error(error)));
    return;
  };

  return (
    <div className={classNames(CSS.section, CSS.note_form, { [CSS.note_complete]: noteComplete})}>
      <Icon symbol={'Warning-1-circle'} library={'code'} size={'24px'} className={CSS.note_icon} />
      <DynamicForm
        id="notes-form"
        formStyle='stacked'
        fields={[
          {
            type: 'textarea',
            name: 'notes',
            value: get(move,'notes'),
            className: CSS.note_input,
            placeholder: 'Enter your notes here...',
            onBlur: form => form.submitForm(),
            readOnly: !hasAbilities('UpdateMoves'),
          }
        ]}
        onSubmit={submitNotes}
        disabled={notePending}
      />
      <Icon symbol={'Done'} library={'code'} size={'24px'} className={CSS.note_check} />
    </div>
  );
}
