import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_FILE_REQUIREMENTS',
  request: (id, params={}) => request.get(`/admins/document-task-file-requirements/${id}`, { params }).then(r => get(r,'data.data.document_task_file_requirement')),
  selector: (state, id) => { return get(state,`fileRequirements.${id}`,{}); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        fileRequirements: {
          ...state.fileRequirements,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useFileRequirements,
  usePending: useFileRequirementsPending,
} = generate.redux(creator);

export const initialState = {
  fileRequirements: {},
};
