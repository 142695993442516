import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONFIG_GET_PROPERTIES_SEARCH',
  request: (params={},cancelToken) => request.get(`/admins/buildings`, { params, cancelToken }).then(r => r.data),
  selector: (state) =>  get(state,`propertiesSearch`),
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        propertiesSearch: {
          params: searchParams,
          totalResults: get(response,'pagination.total_items'),
          activeSet: get(response,'data.buildings'),
        },
      }
    },
    failure: (state, { params: [searchParams] }) => {
      return {
        ...state,
        propertiesSearch: {
          params: searchParams,
          totalResults: 0,
          activeSet: [],
        }
      }
    },
  }
};


export const {
  action,
  reducer,
  useResource: usePropertiesSearch,
  usePending: usePropertiesSearchPending,
} = generate.redux(creator);

export const initialState = {
  propertiesSearch: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
