import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_ADMINS_SEARCH_ADMIN',
  request: (params={},cancelToken) => request.get(`/admins/admins`, { params, cancelToken }).then(r => r.data),
  selector: (state) => get(state,'adminSearch'),
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        adminSearch: {
          params: searchParams,
          totalResults: get(response,'pagination.total_items'),
          activeSet: get(response,'data.admins'),
        },
      }
    },
    failure: (state, { params: [searchParams] }) => {
      return {
        ...state,
        adminSearch: {
          params: searchParams,
          totalResults: 0,
          activeSet: [],
        }
      }
    },
  }
};

export const {
  action,
  reducer,
  useResource: useAdminSearch,
  usePending: useAdminSearchPending,
} = generate.redux(creator);

export const initialState = {
  adminSearch: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
