import { DashboardRedirect, DefaultPageNotFound } from '@moved/product';

import { PageNotFound } from './';

const routes = [
  // if logged in as cx base domain redirects to dashboard
  {
    path: '/',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    exact: true,
  },
  // catchall route for logged in non-cx is redirect to dashboard
  {
    path: '*',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    restrict: ['customer','vendor'],
  },
  // catchall route for logged in cx is 404 with navigation
  {
    path: '*',
    name: 'Page not found',
    component: PageNotFound,
  },
  // catchall route for guests is shared 404 screen
  {
    path: '*',
    name: 'Page not found',
    component: DefaultPageNotFound,
    restrict: ['guest'],
  },
];

export default routes;
