import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_ADMINS_ASSIGN_ADMIN_PROPERTY',
  request: (id, data={}) => request.post(`/admins/admins/${id}/building-admins`, data).then(r => get(r,'data.data.admin')),
  reducers: {
    success: (state, { response: admin }) => {

      return {
        ...state,
        admins: {
          ...state.admins,
          [admin.id]: admin,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  usePending: useAssignAdminPropertyPending,
} = generate.redux(creator);

export const initialState = {
  admins: {},
};
