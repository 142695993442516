import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import { useOptionalControl } from '@moved/services';

import { AtomSpinner } from '../../../../balazs/components/AtomSpinner';
import { Text } from '../Text';
import { Options } from '../Select';

import CSS from './TypeAhead.module.scss';

// provide a default filter for static options lists to match the query
// string with the label of the option (case insensitive)
const defaultFilter = query => option => option.label.match(new RegExp(query,'i'))

export const TypeAhead = ({
  name,
  value,
  isControlled,
  options=[],
  onSearch,
  isSearching,
  label,
  hint,
  icon,
  iconPosition,
  disabled,
  readOnly,
  error,
  onChange,
  className,
}) => {
  const valueIsValid = options.some(option => option.value === value);
  // if valid initial option, use it to pre-populate the selected state
  const [selected, setSelected] = useOptionalControl(valueIsValid && value, isControlled);
  // only on mount, determine if there is a valid initial option
  const [query, setQuery] = useState(options.find(option => option.value === selected)?.label ?? '');


  // to control the open and closed state of the suggested options dropdown
  // we need element ref to attach focus an blur handlers and a stateful boolean
  const elementRef = useRef();
  const [hasFocus, setHasFocus] = useState();

  // if filtering by an async search function, use options as is, otherwise
  // use the default filter to limit the options by label string match
  const filteredOptions = onSearch != null ? options : options.filter(defaultFilter(query));
  const activeOption = options.find(option => option.value === selected);
  // ensure query text is updated when controlled value changes
  useEffect(() => {
    if(isControlled && activeOption) setQuery(activeOption?.label);
  },[isControlled, activeOption, selected]);

  // create debounced version of the onSearch event handler
  const debouncedSearch = useDebouncedCallback((onSearch ?? noop), 300);

  // on change to the text field, update the query string, deselect previous
  // selection, notify the parent of the new search event (debounced version)
  const updateQuery = (queryString) => {
    setQuery(queryString);
    debouncedSearch(queryString);
    if(selected) {
      setSelected();
      onChange?.({[name]:undefined});
    }
  };

  // update the selected value and notify the parent of the event
  const handleSelect = (option) => {
    if(disabled || readOnly) return;
    onChange?.({[name]:option.value});
    setSelected(option.value);
    if(!isControlled) setQuery(option.label);
  };

  /* useEffect to attach listener to close the dropdown on loss of focus */
  useEffect(() => {
    if(!hasFocus) return;
    const handleClickOutside = e => {
      // if the element is not the document's currently active element it no longer has focus
      if(elementRef?.current !== document?.activeElement) {
        setHasFocus(false);
        if(!selected) updateQuery(''); // reset the query on close if nothing is selected
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  },[hasFocus, selected]); // eslint-disable-line

  return (
    <div className={classNames(CSS.container, className)}>

      <Text
        name={name}
        label={label}
        hint={hint}
        error={error}
        value={query}
        isControlled={true}
        icon={icon}
        iconPosition={iconPosition}
        onChange={({ [name]:queryString }) => updateQuery(queryString)}
        onFocus={() => setHasFocus(true)}
        disabled={disabled}
        readOnly={readOnly}
        ref={elementRef}
      />

      <Options
        options={filteredOptions}
        isOpen={hasFocus && !selected && filteredOptions.length > 0}
        onSelect={handleSelect}
        className={CSS.dropdown}
      />

      { isSearching && (
        <AtomSpinner
          size={20}
          className={classNames(
            CSS.spinner,
            { [CSS.trailingIcon]: icon && iconPosition === 'right' },
          )}
        />
      )}

    </div>
  );
};

TypeAhead.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input (only initial value if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Available options in the dropdown */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Value to use for this option */
      value: PropTypes.any.isRequired,
      /** Label text for this option */
      label: PropTypes.string.isRequired,
    })
  ),
  /** Callback function that recieves the updated queryString anytime the query is updated (can be sync or async) */
  onSearch: PropTypes.func,
  /** Flag to indicate when the search function is pending response */
  isSearching: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
};
