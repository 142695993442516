import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { AtomSpinner, Chart } from '@moved/ui';
import { useAnalyticsQuery } from '@moved/services';

import CSS from './styles/LeaseEngagement.module.scss';

const chartOptions = {
  plugins: {
    legend: {
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        padding: 20,
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      position: 'bottom',
      stacked: true,
      grid: { drawOnChartArea: false, offset: false },
      time: {
        displayFormats: { month: 'MMM, yy' },
        tooltipFormat: 'MMMM, yyyy',
        unit: 'month',
      }
    },
    y: {
      display: true,
      type: 'linear',
      position: 'left',
      title: { display: true, text: 'People' },
    },
    y1: {
      display: true,
      type: 'linear',
      position: 'right',
      stacked: true,
      title: { display: true, text: 'Tasks' },
      grid: { drawOnChartArea: false, },
    },
  },
};

export const LeaseEngagement = ({ client, dateRange }) => {

  const { resultSet, isLoading, error } = useAnalyticsQuery({
    measures: [
      'MonthlyActivity.invites',
      'MonthlyActivity.signups',
      'MonthlyActivity.completedTasks',
      'MonthlyActivity.incompleteTasks'
    ],
    timeDimensions: [
      {
        dimension: 'MonthlyActivity.activityTime',
        granularity: 'month',
        dateRange,
      }
    ],
    order: {
      'MonthlyActivity.activityTime': 'asc'
    },
    filters: [
      {
        member: 'MonthlyActivity.partnerId',
        operator: 'equals',
        values: [client.id],
      }
    ]
  });

  // State Handling
  let displayValue;
  if(error) displayValue = error.toString();
  else if(isLoading || !resultSet) displayValue = <AtomSpinner />;
  if(displayValue) return (
    <div className={CSS.placeholder}>
      <div className={classNames(CSS.loader,'labelS')}>{ displayValue }</div>
    </div>
  );

  const results = resultSet.chartPivot();
  // format the data into chart specific data sets
  const data = {
    datasets: [
      {
        label: 'Invites',
        type: 'line',
        data: results.map(result => ({
          x: get(result,'x'),
          y: get(result,'MonthlyActivity.invites'),
        })),
        borderColor: 'rgb(0,0,0)',
        backgroundColor: 'rgb(0,0,0)',
        yAxisID: 'y',
      },
      {
        label: 'Signups',
        type: 'line',
        data: results.map(result => ({
          x: get(result,'x'),
          y: get(result,'MonthlyActivity.signups'),
        })),
        borderColor: 'green',
        backgroundColor: 'green',
        yAxisID: 'y',
      },
      {
        label: 'Incomplete Tasks',
        type: 'bar',
        data: results.map(result => ({
          x: get(result,'x'),
          y: get(result,'MonthlyActivity.incompleteTasks'),
        })),
        backgroundColor: 'rgba(0,0,0,0.3)',
        yAxisID: 'y1',
      },
      {
        label: 'Completed Tasks',
        type: 'bar',
        data: results.map(result => ({
          x: get(result,'x'),
          y: get(result,'MonthlyActivity.completedTasks'),
        })),
        backgroundColor: 'rgba(0,155,0,0.4)',
        yAxisID: 'y1',
      },
    ],
  };

  return <Chart options={chartOptions} data={data} />;
};
