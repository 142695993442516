import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { get, noop } from 'lodash';
import classNames from 'classnames';

import { useNotify, s3, format, useUser } from '@moved/services';
import { Form, Uploader } from '@moved/ui';

import {
  createCoiUploadFile,
  getS3UploadUrl,
} from '../actions';

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { user } = useUser();
  // file upload management
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState();

  // submission handler
  const submit = async () => {
    if(submitting) return;
    if(!file) return setFileError('Required')
    setSubmitting(true);

    // upload the image to s3 and get the url
    const url = await dispatch(getS3UploadUrl(get(tenantEvent,'building.id'),{
      filename: s3.getUniqueFilename(file.name, { id: get(tenantEvent, 'user_id') || user.id }),
      http_content_type: file.type,
    }))
      .then(({ signed_request, url }) => s3.putFile(file,signed_request).then(() => url))
      .catch(error => { Notify.error(format.error(error)) });

    dispatch(createCoiUploadFile(tenantEvent.id, task.building_task_id, { file_url: url }))
      .then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        Notify.error(format.error(err));
      });

  };

  const handleFileSelection = (files) => {
    setFileError(); // remove any requirement errors
    setFile(files[0]); // set the file in state
  };

  return (
    <Form
      name='artifact-form'
      onSubmit={submit}
      className='stackVertical-40'
    >
      <section>
        <div className={classNames(
          'labelS marginBottom-8',
          fileError ? 'contentError' : 'contentSecondary'
        )}>
          Signed COI document
        </div>
        <Uploader
          id='coi_file_url'
          dzOptions={{
            accept: {
              'image/jpg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'application/pdf': ['.pdf'],
            },
            multiple: false,
            maxFiles: 1,
          }}
          initialFiles={[]}
          onSelect={handleFileSelection}
          icon={{size:'30px'}}
          error={fileError}
        />
        { fileError && (
          <div className='labelS contentError marginTop-4'>{ fileError }</div>
        )}
      </section>
    </Form>
  );
};
