import React from 'react';

import { useUser } from '@moved/services';
import { NavigationMenu } from '@moved/product';

import { ProfileLink } from './ProfileLink';
import CSS from './styles/AppNavigation.module.scss';

export const AppNavigation = ({ logos, navItems, signOut, profileContent }) => {
  // HOOKS
  const { isAuthenticated } = useUser();

  return (
    <div className={CSS.navigation}>
      <div className={CSS.top}>
        {logos && (
          <div className={CSS.logo_section}>
            {logos}
          </div>
        )}
        { isAuthenticated && (
          <NavigationMenu sections={navItems} className={CSS.menu} />
        )}
      </div>
      { isAuthenticated && (
        <div className={CSS.bottom_section}>
          {profileContent && (
            <div className={CSS.profile_content}>
              {profileContent}
            </div>
          )}
          <ProfileLink signOut={signOut}/>
        </div>
      )}
    </div>
  );
};
