import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_FEED_HISTORY',
  request: (id, params, cancelToken) => request.get(`/admins/buildings/${id}/feed-sync-summaries`,{ params, cancelToken }).then(r => get(r,'data')),
  selector: (state, id) => get(state,`buildingFeedHistories.${id}`),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      buildingFeedHistories: {
        ...get(state,'buildingFeedHistories'),
        [id]: {
          history: get(response,'data.feed_sync_summaries',[]),
          pagination: get(response,'pagination'),
        },
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useBuildingFeedHistory,
  usePending: useBuildingFeedHistoryPending,
} = generate.redux(creator);

export const initialState = {
  buildingFeedHistories: {},
};
