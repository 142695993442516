import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './EventList.module.scss';

export const EventList = ({ events }) => {
  // TODO: hide/show with collapsible if longer than 2
  return (
    <div className={CSS.events}>
      { events.map((event) => (
        <div className={classNames(CSS.event, CSS[event.color])} key={event.key}>
          <div className={'flex items-center'}>{ event.title }</div>
          <div className={CSS.event_content}>
            { event.content }
          </div>
        </div>
      ))}
    </div>
  );
}

EventList.propTypes = {
  /** Text to display and copy */
  events: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    title: PropTypes.node.isRequired,
    content: PropTypes.node,
    color: PropTypes.oneOf(['green','red','yellow']),
  })).isRequired,
};
