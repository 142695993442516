import { merge } from 'lodash';

import * as cancelReservation from './cancelReservation';
import * as createBlock from './createBlock';
import * as createKeyPickup from './createKeyPickup';
import * as createReservation from './createReservation';
import * as getBuildingCalendarPendingRequests from './getBuildingCalendarPendingRequests';
import * as getBuildingCalendarRepliedRequests from './getBuildingCalendarRepliedRequests';
import * as getBuildingCalendarSummaries from './getBuildingCalendarSummaries';
import * as getPartnerCalendar from './getPartnerCalendar';
import * as getTenantEvents from './getTenantEvents';
import * as rescheduleKeyPickup from './rescheduleKeyPickup';
import * as rescheduleReservation from './rescheduleReservation';
import * as respondBuildingRequest from './respondBuildingRequest';

export const initialState = merge(
  cancelReservation.initialState,
  createBlock.initialState,
  createKeyPickup.initialState,
  createReservation.initialState,
  getBuildingCalendarPendingRequests.initialState,
  getBuildingCalendarRepliedRequests.initialState,
  getBuildingCalendarSummaries.initialState,
  getPartnerCalendar.initialState,
  rescheduleKeyPickup.initialState,
  rescheduleReservation.initialState,
  respondBuildingRequest.initialState,
);

export const reducers = [
  cancelReservation.reducer,
  createBlock.reducer,
  createKeyPickup.reducer,
  createReservation.reducer,
  getBuildingCalendarPendingRequests.reducer,
  getBuildingCalendarRepliedRequests.reducer,
  getBuildingCalendarSummaries.reducer,
  getPartnerCalendar.reducer,
  getTenantEvents.reducer,
  rescheduleKeyPickup.reducer,
  rescheduleReservation.reducer,
  respondBuildingRequest.reducer,
];
