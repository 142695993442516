import { merge } from 'lodash';

import * as createPayMoveInCostsInstructions from './createPayMoveInCostsInstructions';
import * as getPayMoveInCostsInstructions from './getPayMoveInCostsInstructions';
import * as updatePayMoveInCostsInstructions from './updatePayMoveInCostsInstructions';

export const reducers = [
  createPayMoveInCostsInstructions.reducer,
  getPayMoveInCostsInstructions.reducer,
  updatePayMoveInCostsInstructions.reducer,
];

export const initialState = merge(
  createPayMoveInCostsInstructions.initialState,
  getPayMoveInCostsInstructions.initialState,
  updatePayMoveInCostsInstructions.initialState,
);
