import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Icon } from '../../../sondheim/components/Icon';
import CSS from './KeywordPill.module.scss';

export const KeywordPill = ({ active, label, onSelect }) => {
  const [value, setValue] = useState(active);
  const onChange = (newValue) => {
    setValue(newValue);
    onSelect(newValue);
  };

  // keep value in sync if active changes from parent
  useEffect(() => {
    if(active !== value) onChange(active);
  },[active]); // eslint-disable-line

  return (
    <div className={classNames(CSS.pill, {[CSS.pill_active]: active && active.length > 0})}>
      <div className={CSS.pill_inner}>
        <div className={CSS.search_bar}>
          <input
            className={CSS.search_input}
            id="keyword_search"
            type="text"
            value={value}
            onChange={(e,input) => onChange(e.target.value)}
            placeholder={label}
          />
          <label className={CSS.input_icon} htmlFor="keyword_search">
            <Icon symbol={'Search'} library={'general'} size={'20px'} />
          </label>
        </div>
        <span className={CSS.clear_icon}>
          {(active && active.length > 0) && (
            <Icon symbol={'Close'} library={'navigation'} size={'18px'} onClick={e => { e.stopPropagation(); return onSelect('',true);}} />
          )}
        </span>
      </div>
    </div>
  );
};
