import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AnalyticsProvider, useQuery } from '@moved/services';

import { useClient } from '../../../../common/actions/selectors';

import { DateRangeToolbar } from './metrics/DateRangeToolbar';
import { LeaseEngagement } from './metrics/LeaseEngagement';
import { TotalLeases } from './metrics/TotalLeases';
import { TotalCompletedTasks } from './metrics/TotalCompletedTasks';
import { TotalTimeSavings } from './metrics/TotalTimeSavings';

export const ClientMetrics = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const startDate = useQuery('startDate');
  const endDate = useQuery('endDate');

  const client = useClient(clientId);

  const [activeRange, setActiveRange] = useState([startDate,endDate]);

  useEffect(() => {
    if(startDate !== activeRange[0] || endDate !== activeRange[1]) {
      history.push({
        pathname: history.location.pathName,
        search: `startDate=${encodeURIComponent(activeRange[0])}`+
          `&endDate=${encodeURIComponent(activeRange[1])}`,
      });
    }
  },[activeRange, startDate, endDate, history]);

  return (
    <AnalyticsProvider>
      <DateRangeToolbar activeRange={activeRange} setActiveRange={setActiveRange} />
      <div className='flex mb-30'>
        <TotalLeases client={client} dateRange={activeRange} />
        <TotalCompletedTasks client={client} dateRange={activeRange} />
        <TotalTimeSavings client={client} dateRange={activeRange} />
      </div>
      <div className={CSS.row}>
        <LeaseEngagement client={client} dateRange={activeRange} />
      </div>
    </AnalyticsProvider>
  );
};
