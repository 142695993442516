import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, DynamicForm } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

import { ImageManager } from '../../branding';
import { getAssetUploadUrl } from '../../branding/actions';

import { updateProperty } from '../actions';
import { useProperty, usePropertyPending } from '../actions/selectors';

import CSS from './styles/PropertySettings.module.scss';

export const PropertySettings = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();

  const { property_id } = useParams();
  const { Can, hasAbilities } = useUser();
  const property = useProperty(property_id);
  const updatePending = usePropertyPending();

  const getUploadUrl = (assetType) => getAssetUploadUrl('buildings',property.id,assetType);

  const handleUpdate = ({ name, address, ...data }) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === '') {
          data[key] = null;
      }
    });

    dispatch(updateProperty(property.id, data))
      .then(() => {
        Notify.default(`Property details have been updated!`);
      })
      .catch(error => Notify.error(format.error(error)));;
  }

  const isReadOnly = !hasAbilities('UpdateBuildings');

  const fields = [
    {
      label: 'Name',
      type: 'text',
      name: 'name',
      value: property.name,
      required: true,
      readOnly: true,
    },
    {
      label: 'Address',
      type: 'text',
      name: 'address',
      value: format.address(property),
      required: true,
      readOnly: true,
    },
    {
      label: 'Community Email',
      type: 'text',
      name: 'community_email',
      value: property.community_email,
      readOnly: isReadOnly,
    },
    {
      label: 'Community Phone',
      type: 'text',
      name: 'community_phone',
      value: property.community_phone,
      readOnly: isReadOnly,
    },
    {
      label: 'External Resident App Url',
      type: 'text',
      name: 'external_resident_app_url',
      value: property.external_resident_app_url,
      readOnly: isReadOnly,
    },
    {
      label: 'PMS label',
      type: 'text',
      name: 'pms_label',
      value: property.pms_label,
      readOnly: isReadOnly,
    },
  ];

  return (
    <div className={CSS.main}>

      <div className={CSS.task_title}>
        <h3>General</h3>
      </div>

      <div className={CSS.details_section}>

        <DynamicForm
          id='property-form'
          formStyle='underline'
          fields={fields}
          onSubmit={handleUpdate}
        />

        <Can I="UpdateBuildings">
          <div className={CSS.update_details_btn}>
            <Button
              text='Update'
              color='primary'
              form='property-form'
              disabled={updatePending}
            />
          </div>
        </Can>

        <ImageManager
          name='Property logo'
          description={'The logo appears on both the tenant\'s sign in page and homepage'}
          brandable={property}
          assetType={'logo'}
          getUploadUrl={getUploadUrl}
          update={updateProperty}
          readonly={!hasAbilities('UpdateBuildings')}
        />

        <ImageManager
          name='Welcome image'
          description={'This image appears on the tenant\'s sign in page'}
          brandable={property}
          assetType={'welcome-image'}
          getUploadUrl={getUploadUrl}
          update={updateProperty}
          readonly={!hasAbilities('UpdateBuildings')}
        />

        <ImageManager
          name='Dashboard image'
          description={'This image appears on the tenant\'s dashboard'}
          brandable={property}
          assetType={'dashboard-image'}
          getUploadUrl={getUploadUrl}
          update={updateProperty}
          readonly={!hasAbilities('UpdateBuildings')}
        />

      </div>

    </div>
  );
};
