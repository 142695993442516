import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_GET_UTILITY_CATEGORIES',
  request: () => request.get(`/admins/utility-categories`).then(r => get(r,'data.data.utility_categories')),
  selector: (state) => { return get(state,`utilityCategories`); },
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        utilityCategories: response,
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useUtilityCategories,
  usePending: useUtilityCategoriesPending,
} = generate.redux(creator);

export const initialState = {
  utilityRequirements: [],
};
