import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_ELIGIBLE_FILE_REQUIREMENT_LISTS',
  request: (id, params) => request.get(`/admins/building-tasks/${id}/eligible-document-task-file-requirement-lists`, { params }).then(r => get(r,'data.data.document_task_file_requirement_lists')),
  selector: (state, id) => { return get(state,`eligibleLists.${id}`) || []; },
  reducers: {
    success: (state, { response: properties, params: [id] }) => {
      return {
        ...state,
        eligibleLists: {
          ...state.eligibleLists,
          [id]: properties
        },
      };
    },
    failure: (state, { params: [id] }) => {
      return {
        ...state,
        eligibleLists: {
          ...state.eligibleLists,
          [id]: [],
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useEligibleFileRequirementLists,
  usePending: useEligibleFileRequirementListsPending,
} = generate.redux(creator);

export const initialState = {
  eligibleLists: {},
};
