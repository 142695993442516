import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get, isEqual } from 'lodash';

import { useModal, useUser } from '@moved/services';
import { Icon, FilterPills, Collapsible, Tooltip } from '@moved/ui';

import { CreateExternalUserModal } from './modals/CreateExternalUserModal';

import { tagSearch } from '../actions';

import CSS from '../styles/MovesSearchBar.module.scss';

export const platformDefaultParams = {
  status: 'all',
  chat: false,
  tags: [],
  search: '',
  sort: 'last_active_date',
  order: 'desc',
  page: 1,
  per_page: 10,
};

const Sort = ({name, activeSort, activeOrder, updateSort, className, children}) => {
  const match = activeSort === name;
  return (
    <div onClick={() => updateSort(name)} className={classNames(CSS.sortable, className, { [CSS.active]: match })}>
      { children }
      { match && (
        <Icon symbol={activeOrder === 'desc' ? 'Chevron-down': 'Chevron-up'} library='navigation' size='16px' />)
      }
    </div>
  );
}

export const MovesSearchBar = ({ activeParams, updateQuery, clearQuery, saveSearch }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const Modal = useModal();
  const { Can } = useUser();
  const [personalSearchSaved, setPersonalSearchSaved] = useState();

  const updatePersonalSearch = () => {
    setPersonalSearchSaved(true);
    setTimeout(() => setPersonalSearchSaved(false),2000);
    saveSearch();
  };

  const {
    status,
    tags,
    chat,
    search,
    sort,
    order,
    per_page,
  } = activeParams;

  const [keywords, setKeywords] = useState(search);
  // Debounce keyword search
  const [keywordTimeout, setKeywordTimeout] = useState();
  useEffect(() => {
    if(keywordTimeout) {
      clearTimeout(keywordTimeout);
      setKeywordTimeout(setTimeout(() => updateQuery({ search: keywords }),500));
    }
    else setKeywordTimeout(true);
  },[keywords]); // eslint-disable-line

  const showReset = ['search','status','tags','chat'].find(param => !isEqual(platformDefaultParams[param], activeParams[param]));
  const onReset = () => {
    setKeywords('');
    clearQuery();
  };

  const openAddUserModal = () => Modal.open(
    <CreateExternalUserModal/>,
    {
      onClose: (user) => {
        if(!user) return;
        // after successful creation, update the search and active move to display the new user & move
        setKeywords(get(user,'email'));
        history.push({
          pathname: `/cx/moves/${get(user,'moves[0].id')}`,
          search: `direct=${encodeURIComponent(get(user,'email'))}`,
        });
      }
    }
  );

  const updateSort = (name) => {
    // if same sort, flip direction
    if(sort === name) updateQuery({ order: (order === 'desc' ? 'asc': 'desc') });
    else updateQuery({ sort: name });
  };

  const perPageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
  ];

  // FILTER LIST
  const filters = [
    {
      label: 'Status',
      type: 'singleSelect',
      list: [
        { name: 'Core', value: 'core', id: 'core' },
        { name: 'Active', value: 'active', id: 'active' },
        { name: 'Inactive', value: 'inactive', id: 'inactive' },
        { name: 'Archived', value: 'archived', id: 'archived' },
      ],
      active: status !== platformDefaultParams.status && status,
      props: {
        onSelect: (value) => updateQuery({ status: value || platformDefaultParams.status }),
        title: 'Status Filter',
      },
    },
    {
      label: 'Tags',
      type: 'typeAhead',
      active: tags,
      list: tags.map(tag => ({id:tag,name:tag})),
      props: {
        onSelect: (value) => updateQuery({ tags: value }),
        title: 'Tag Filter',
        activeItems: tags.map(tag => ({id:tag,name:tag})), // array of search results not just IDs
        searchAction: ({keywords},cancelToken) => dispatch(tagSearch(keywords,cancelToken))
          .then(results => results.map(result => ({
            ...result,
            id: get(result,'name'), // needs id for looping
            name: (<>
              <span>{ get(result,'name') }</span>
              <span className={classNames(CSS.hint,'ml-5')}>{get(result,'count')} matches</span>
            </>),
          }))),
      },
    },
    {
      label: 'Chat',
      type: 'toggle',
      active: chat,
      props: {
        onSelect: (value) => updateQuery({ chat: value }),
      },
    },
  ];
  const searchFilter = [
    {
      label: 'Search',
      type: 'keyword',
      active: keywords,
      props: {
        onSelect: (value,immediate) => {
          setKeywords(value);
          if(immediate) updateQuery({ search: value });
        },
      },
    },
  ];

  // state management for advanced settings
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const isAdvancedFilterActive = ['status','tags','chat'].find(param => !isEqual(platformDefaultParams[param], activeParams[param]));

  return (
    <div className={CSS.search_bar}>

      <div className={CSS.search_row}>
        <FilterPills
          filters={searchFilter}
          clearAll={showReset && onReset}
        />
        <Can I='CreateUsers'>
          <Tooltip
            tooltip={'Create User'}
            className={CSS.search_action_icon}
          >
            <Icon library='code' symbol='Plus'
              className={CSS.add_user}
              onClick={openAddUserModal}
            />
          </Tooltip>
        </Can>
        <Tooltip
          tooltip={'Advanced Search'}
          className={CSS.search_action_icon}
        >
          <Icon library='general' symbol='Settings-2'
            className={classNames(CSS.advanced_toggle,{[CSS.active]:isAdvancedFilterActive, [CSS.visible]:advancedSearchVisible})}
            onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
          />
        </Tooltip>
      </div>
      <Collapsible open={advancedSearchVisible}>
        <div className={CSS.advanced_search}>
          <div className={CSS.filter_row}>
            <FilterPills
              filters={filters}
            />
            <Tooltip
              tooltip={'Make Default Search'}
              placement='left'
              className={CSS.save_search}
            >
              { personalSearchSaved ? (
                <Icon library='code' symbol='Done' color='green' />
              ) : (
                <Icon library='general' symbol='Save'
                  className={CSS.save_search_icon}
                  onClick={() => updatePersonalSearch()}
                />
              )}
            </Tooltip>
          </div>
          <div className={CSS.search_options}>
            <div className={CSS.sorts}>
              <div className={CSS.name}>Sort:</div>
              <Sort name={'last_active_date'} activeSort={sort} activeOrder={order} updateSort={updateSort}>Last Active</Sort>
              <Sort name={'move_date'} activeSort={sort} activeOrder={order} updateSort={updateSort}>Move Date</Sort>
            </div>
            <div className={CSS.per_page}>
              <div className={CSS.name}>Results per page:</div>
              { perPageOptions.map((option) => (
                <div
                  key={option.label}
                  className={classNames(CSS.sortable, { [CSS.active]: per_page === option.value })}
                  onClick={() => updateQuery({ per_page: option.value })}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
};
