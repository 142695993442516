import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONFIG_PROPERTIES_GET_PROPERTY',
  request: (id) => request.get(`/admins/buildings/${id}`).then(r => get(r,'data.data.building')),
  selector: (state, id) => get(state,`properties.${id}`),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        properties: {
          ...state.properties,
          [response.id]: response,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  useResource: useProperty,
  usePending: usePropertyPending,
} = generate.redux(creator);

export const initialState = {
  properties: {},
};
