import React from 'react';
import { Helmet } from 'react-helmet';

import { SearchScreen } from '@moved/product';

import { getClientSearch } from '../actions/';
import { useClientSearch, useClientSearchPending, } from '../actions/selectors';
import { ClientResult } from './';

import columnsCSS from './styles/columns.module.scss';

export const ClientsSearchWrapper = () => {

  const sortables = [
    { value:'name', label: 'Client name'},
    { value:'slug', label: 'Slug'},
    { value:'contact_name', label: 'Primary contact'},
    { value:'num_buildings', label: 'Properties'},
    { value:'num_admins', label: 'Admins'},
  ];

  return (
    <>
      <Helmet>
        <title>{`Admin Abode Clients Search : Moved`}</title>
      </Helmet>

      <SearchScreen
        title={'Clients'}
        getSearchItems={getClientSearch}
        useSearchItems={useClientSearch}
        useSearchItemsPending={useClientSearchPending}
        sortables={sortables}
        sortableCSS={columnsCSS}
        Result={ClientResult}
      />
    </>
  )
};
