import { ClientsSearchWrapper } from './components/ClientsSearchWrapper';
import { ClientDetails, ClientDetailsBreadcrumb } from './components/ClientDetails';
import { ClientSettings } from './components/ClientSettings';
import { ClientMetrics } from './components/ClientMetrics';

const routes = [
  {
    path: '/clients',
    name: 'Clients',
    restrict: ['abode','client'],
    breadcrumb: {
      label:"Clients",
      tooltip:  "Clients",
      icon: {
        symbol: 'Crew-3',
        library: 'people',
      },
    },
    children: [
      {
        path: '/',
        name: "Clients Search",
        component: ClientsSearchWrapper,
        exact: true,
        restrict: ['abode'],
      },
      {
        path: '/:clientId(\\d+)',
        name: 'ClientDetails',
        component: ClientDetails,
        breadcrumb: {
          label: ClientDetailsBreadcrumb,
        },
        children: [
          {
            path: '/',
            name: 'Client',
            component: ClientSettings,
            exact: true,
          },
          {
            path: '/:view(metrics)',
            name: 'ClientMetrics',
            component: ClientMetrics,
            restrict: ['abode'],
          },
        ],
      },

    ],
  },

];

export default routes;
