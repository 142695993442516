import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import { SectionDisplay } from './SectionDisplay';
import { AddCoupon } from '../buttons';

import CSS from '../../styles/CouponSection.module.scss';

export const CouponSection = ({ order, move }) => {
  const details = {
    title: 'Coupon',
    actions: [
      (<AddCoupon order={order} move={move} key='add-coupon'/>),
    ].filter(v => v),
  };

  if(get(order, 'coupons.length')) details.columns = [
      order.coupons.map(coupon => ({
        label: 'Amount',
        value: format.currency(coupon.amount),
      })),
      order.coupons.map(coupon => ({
        label: 'Provided by',
        value: get(coupon, 'sponsor_partner.name'),
      })),
    ];
  else details.rows = [(
    <div className={CSS.none} key='no-coupons'>No coupon has been applied</div>
  )];

  return (
    <SectionDisplay section={details}/>
  );

};
