import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_ORIENTATION_CHAPTER_TEMPLATES',
  request: (params={},cancelToken) => request.get(`/admins/content-editor-content-gallery-templates`, { params, cancelToken }).then(r => get(r,'data')),
  selector: (state) => get(state,`orientationChapterTemplates`) || {},
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        orientationChapterTemplates: {
          params: searchParams,
          pagination: get(response,'pagination'),
          results: get(response,'data.content_editor_content_gallery_templates') || [],
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useChapterTemplates,
  usePending: useChapterTemplatesPending,
} = generate.redux(creator);

export const initialState = {
  orientationChapterTemplates: {},
};
