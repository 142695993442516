import React from 'react';
import { get } from 'lodash';
import { PromoColumn } from '@moved/product';

import CSS from './Preview.module.scss';

const Preview = ({ brandable, newUrl }) => {
  const preview = newUrl || get(brandable,'welcome_image_url');
  return (
    <div className={CSS.welcome_image_preview}>
      <PromoColumn
        logo={get(brandable,'logo_url')}
        name={get(brandable,'name')}
        splashBG={preview}
        className={CSS['aspect-ratio']}
      />
    </div>
  );
};

export const welcomeImage = {
  key: 'welcome-image',
  originalKey: 'welcome_image_original_url',
  finalKey: 'welcome_image_url',
  allowCropping: true,
  croppingAspectRatio: 9/16, // width/height ratio as a number
  requirements: {
    types: { // list all acceptable file types
      'image/jpg': ['.jpg', '.jpeg']
    },
    dimensions: {
      minWidth: 600, // in pixels
      minHeight: 1200, // in pixels
      maxHeight: 1600, // in pixels
    },
  },
  Preview: Preview,
};
