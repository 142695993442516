import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useScroller } from '@moved/services';

import { ErrorContent, PendingContent } from '../../shared/components';

import { ReviewContent } from './ReviewContent';
import { ApprovedContent } from './ApprovedContent';
import { DeclinedContent } from './DeclinedContent';
import { ExpiredContent } from './ExpiredContent';

import { getPetRegistration } from '../actions';
import { usePetRegistration } from '../actions/selectors';

export const PetRegistrationLanding = (props) => {
  const { token } = useParams();
  const { ref: scrollParent } = useScroller();
  // state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // redux
  const dispatch = useDispatch();
  const request = usePetRegistration(token) || {};

  useEffect(() => {
    dispatch(getPetRegistration(token))
      .catch(error => setError(get(error, 'response.data.description')))
      .finally(() => setLoading(false));
  },[token]); // eslint-disable-line

  // trigger scroll to top after policy status is updated
  useEffect(() => {
    scrollParent.current.scrollTo({ behavior: 'smooth', top: 0 });
  },[request.status]); // eslint-disable-line

  if(loading) return <PendingContent />;
  if(error) return <ErrorContent error={error} />;
  switch(request.status) {
    case 'pending-approval':
      return <ReviewContent request={request}/>;
    case 'approved':
      return <ApprovedContent request={request}/>;
    case 'rejected':
      return <DeclinedContent request={request}/>;
    default:
      return <ExpiredContent request={request}/>;
  }
};
