import { merge } from 'lodash';

import * as getUtilitySetupRequest from './getUtilitySetupRequest';
import * as reviewUtilitySetupRequest from './reviewUtilitySetupRequest';

export const initialState = merge(
  getUtilitySetupRequest.initialState,
  reviewUtilitySetupRequest.initialState,
);

export const reducers = [
  getUtilitySetupRequest.reducer,
  reviewUtilitySetupRequest.reducer,
];
