import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { get, noop } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Form, FormSelect, FormCheckboxList, AtomSpinner } from '@moved/ui';

import {
  getTenantKeyPickupOptions,
  createKeyPickupAppointment,
} from '../actions';
import { useTenantKeyPickupOptions } from '../actions/selectors';

import { AppointmentSchedule } from './AppointmentSchedule';
import { AppointmentList } from './AppointmentList';

const findById = (id) => x => x.id === id;

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  // state management
  const [pending, setPending] = useState(true);
  const [selectedCalendarId, setSelectedCalendarId] = useState();

  // configuration data
  const {
    building_calendars: calendars=[],
    tenant_keys: tenantKeys=[],
    key_pickup_by_roommate_enabled: allowRoommates,
  } = useTenantKeyPickupOptions(tenantEvent.id,task.building_task_id) || {};

  const myTenantKey = tenantKeys.find(key => get(key,'tenant.id') === get(tenantEvent,'tenant.id'));
  const availableRoommateKeys = allowRoommates ? tenantKeys.filter(key => (
    !key.appointment && key !== myTenantKey
  )) : [];
  const existingAppointments = allowRoommates ? tenantKeys.filter(key => (
    key.appointment && key !== myTenantKey
  )) : [];

  // fetch configuration data
  useEffect(() => {
    dispatch(getTenantKeyPickupOptions(tenantEvent.id,task.building_task_id))
      .then(({ building_calendars=[] }) => {
        if(building_calendars.length === 1) setSelectedCalendarId(building_calendars[0].id);
        setPending(false);
      })
      .catch(err => Notify.error(format.error(err)));
  },[tenantEvent,task,dispatch,Notify]);

  // submission handler
  const submit = async ({ pickup_time, tenant_key_ids, ...data }) => {
    if(submitting) return;
    setSubmitting(true);
    dispatch(createKeyPickupAppointment(tenantEvent.id, task.building_task_id, {
      ...data,
      ...(get(calendars.find(findById(selectedCalendarId)),'booking_type') === 'appointment-slot' ? { pickup_time } : {}),
      tenant_key_ids: [
        myTenantKey.id,
        ...(tenant_key_ids || []),
      ],
    }))
      .then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        Notify.error(format.error(err));
      });
  };

  const validation = Yup.object({
    building_calendar_id: Yup.mixed().required('You must select a pickup option').nullable(),
    pickup_date: Yup.string().required('You must select a pick up date'),
    pickup_time: Yup.string().when('building_calendar_id', (id, schema) => {
      const calendar = calendars.find(calendar => calendar.id === id);
      return get(calendar,'booking_type') === 'appointment-slot' ?
        schema.required('You must select a pick up time').nullable() :
        schema.optional().nullable();
    }),
  });

  if(pending) return <AtomSpinner/>;

  return (
    <Form
      name='artifact-form'
      validation={validation}
      onSubmit={submit}
      className='stackVertical-40'
    >
      <section className='stackVertical-12'>
        <div className='labelL contentPrimary'>
          Appointment details
        </div>
        <FormSelect
          name='building_calendar_id'
          label='Pickup option'
          options={calendars.map(({name, id}) => ({
            label: name,
            value: id,
          }))}
          value={calendars.length === 1 ? calendars[0].id : undefined}
          readOnly={calendars.length === 1}
          onChange={({ building_calendar_id }) => setSelectedCalendarId(building_calendar_id)}
        />
        { selectedCalendarId && (
          <AppointmentSchedule
            calendarId={selectedCalendarId}
            tenantEvent={tenantEvent}
            task={task}
          />
        )}
      </section>

      { availableRoommateKeys.length > 0 && (
        <section className='stackVertical-12'>
          <div className='labelL contentPrimary'>
            Include picking-up for
          </div>
          <FormCheckboxList
            name='tenant_key_ids'
            options={availableRoommateKeys.map(key => ({
              value: key.id,
              label: format.fullname(key.tenant),
            }))}
            value={[]}
          />
        </section>
      )}
      { existingAppointments.length > 0 && (
        <section className='stackVertical-12'>
          <div className='labelM contentPrimary'>
            Existing lease key pickups
          </div>
          <AppointmentList appointments={existingAppointments} />
        </section>
      )}
    </Form>
  );
};
