import React from 'react';

export const MoveDetailsIframe = ({ move, view }) => {
  return (
    <iframe
      src={`${process.env.REACT_APP_MOVED_APP_URL}/admin/moves/${move.id}`}
      height='100%'
      width='100%'
      style={{border:'none', flex: '1 1 auto'}}
      title='embed legacy view'
    />
  );
}
