import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, isNil } from 'lodash';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, LoaderOverlay } from '@moved/ui';

import { getRecommendedCancellationFee, cancelOrder, getOrder } from '../../actions';
import { useRecommendedCancellationFeePending, useCancelOrderPending } from '../../actions/selectors';

import CSS from '../../styles/CancellationModal.module.scss';

export const CancellationModal = ({ order={} }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const recommendedFeePending = useRecommendedCancellationFeePending();
  const cancelPending = useCancelOrderPending();
  const [recommendedFee, setRecommendedFee] = useState();

  // on load fetch the recommended fee for this order
  useEffect(() => {
    dispatch(getRecommendedCancellationFee(get(order,'id')))
      .then(setRecommendedFee);
  },[]); // eslint-disable-line

  if(isNil(recommendedFee) || recommendedFeePending) return <LoaderOverlay />;
  // FORM
  const formFields = [
    {
      label: 'Charge Fee',
      name: 'should_charge',
      type: 'toggle',
      value: !isNil(recommendedFee) && recommendedFee > 0,
    },
    {
      label: 'Amount to charge',
      name: 'charge_fee_amount',
      type: 'currency',
      value: recommendedFee || 0,
      required: true,
      custom: {
        hidden: {
          compare: { field: 'should_charge' },
          boolean: 'eq',
          value: false,
        },
      },
    },
  ];

  const handleSubmit = ({ should_charge, charge_fee_amount }) => {
    if(cancelPending) return;
    // format the data for serverside
    const data = {
      charge_fee_amount: should_charge ? charge_fee_amount : 0,
    };
    dispatch(cancelOrder(get(order,'id'), data))
      .then((order) => {
        Notify.default(`Order has been successfully canceled.`);
        Modal.close(order);
      })
      .catch(error => {
        dispatch(getOrder(get(order,'id'))); // refresh the order in case it changed
        Notify.error(format.error(error));
        // custom handler if card declined error
        if(get(error,'response.data.errors').find(e => e.code === 'stripe-card-declined-error')) {
          Modal.close(order);
        };
      });
  };

  return (<>
    <h3 className={CSS.title}>Customer Cancellation Fee</h3>
    <DynamicForm
      id="order-cancellation-form"
      formStyle={'underline'}
      fields={formFields}
      onSubmit={handleSubmit}
    />
    <section className={classNames(CSS.actions)}>
      <span className={'btn-gray mr-15'} onClick={Modal.close}>Nevermind</span>
      <button className={classNames('btn-cancel',{loading:cancelPending})} type='submit' form='order-cancellation-form'>Cancel Transaction</button>
    </section>
  </>);

}
