import { merge } from 'lodash';

import * as updateAllconnectEnabled from './updateAllconnectEnabled';

export const reducers = [
  updateAllconnectEnabled.reducer,
];

export const initialState = merge(
  updateAllconnectEnabled.initialState,
);
