import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_TASKS_PETSCREENING_VERIFICATION_GET_FILE_UPLOAD_URL',
  request: (buildingId, data) => request.post(`/admins/buildings/${buildingId}/presigned-s3-urls/task-configuration/petscreening-verification`, data)
    .then(r => r?.data?.data?.presigned_s3_url),
};

export const {
  action,
  reducer,
  usePending: useFileUploadUrlPending,
} = generate.redux(creator);
