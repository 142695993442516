import { merge } from 'lodash';

import * as createDemoTenants from './createDemoTenants';
import * as createTask from './createTask';
import * as getEligibleTaskTypes from './getEligibleTaskTypes';
import * as getBuildingFeed from './getBuildingFeed';
import * as getBuildingFeedHistory from './getBuildingFeedHistory';
import * as getBuildingFeedSync from './getBuildingFeedSync';
import * as getBuildingFeedSyncSummary from './getBuildingFeedSyncSummary';
import * as getPropertiesSearch from './getPropertiesSearch';
import * as getProperty from './getProperty';
import * as reorderPropertyTasks from './reorderPropertyTasks';
import * as triggerBuildingFeed from './triggerBuildingFeed';
import * as updateProperty from './updateProperty';

export const reducers = [
  createDemoTenants.reducer,
  createTask.reducer,
  getEligibleTaskTypes.reducer,
  getBuildingFeed.reducer,
  getBuildingFeedHistory.reducer,
  getBuildingFeedSync.reducer,
  getBuildingFeedSyncSummary.reducer,
  getPropertiesSearch.reducer,
  getProperty.reducer,
  reorderPropertyTasks.reducer,
  triggerBuildingFeed.reducer,
  updateProperty.reducer,
];

export const initialState = merge(
  createDemoTenants.initialState,
  createTask.initialState,
  getEligibleTaskTypes.initialState,
  getBuildingFeed.initialState,
  getBuildingFeedHistory.initialState,
  getBuildingFeedSync.initialState,
  getBuildingFeedSyncSummary.initialState,
  getPropertiesSearch.initialState,
  getProperty.initialState,
  reorderPropertyTasks.initialState,
  triggerBuildingFeed.initialState,
);
