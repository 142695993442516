import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'MOVES_GET_MOVED_DISCOUNTS',
  request: () => request.get(`/cx/discounts`).then(r => get(r,'data.data.discounts')),
  selector: (state, id) => get(state,`movedDiscounts`),
  reducers: {
    success: (state, { response: discounts, params: [id] }) => ({
      ...state,
      movedDiscounts: discounts,
    }),
    failure: (state) => ({
      ...state,
      movedDiscounts: initialState.movedDiscounts,
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMovedDiscounts,
  usePending: useMovedDiscountsPending,
} = generate.redux(creator);

export const initialState = {
  movedDiscounts: [],
};
