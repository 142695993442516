import React from 'react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';

import { Icon } from '@moved/ui';
import { useUser, format } from '@moved/services';

import CSS from './styles/AdminResult.module.scss';
import columnsCSS from './styles/columns.module.scss';

const Wrapper = ({ item, children }) => {
  // HOOKS
  const { hasAbilities } = useUser();
  const location  = useLocation();

  return hasAbilities('UpdateAdmins')
    ? (
      <Link
        className={CSS.box}
        to={{
          pathname: `/admin/admins/${item.id}`,
          state: {
            from: `${location.pathname}${location.search}`,
          },
        }}
      >
        {children}
      </Link>
    )
    : (
      <div className={classNames(CSS.box, CSS.disabled)}>{children} </div>
    )
}


export const AdminResult = ({ item, partner }) => {
  // HOOKS
  const { Can } = useUser();

  return (
    <Wrapper item={item}>

      <div className={CSS.box_top}>

        <div className={columnsCSS.icon}>
          <div className={CSS.oval}>
            <span>{format.initials(item)}</span>
          </div>
        </div>

        <div className={classNames(columnsCSS.name, CSS.name)}>
          <span>{format.fullname(item)}</span>
          <span className={CSS.email}>{item.email}</span>
        </div>

        <div className={columnsCSS.num_buildings}>
          {item.buildings.map(building => (building.name)).slice(0,2).join(', ')}
        </div>

        <div className={columnsCSS.partner_name}>
          {item.partner.name}
        </div>

        <div className={columnsCSS.invite_status}>
          <div className={classNames(CSS.pill,CSS[(item.signup_status || '').toLowerCase()])}>
            {item.signup_status}
          </div>
        </div>

        <div className={columnsCSS.arrow}>
          <Can I="UpdateAdmins">
            <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow} />
          </Can>
        </div>

      </div>

    </Wrapper>
  );
};
