import { merge } from 'lodash';

import * as getTaskDetails from './getTaskDetails';
import * as updateTaskDetails from './updateTaskDetails';

export const reducers = [
  getTaskDetails.reducer,
  updateTaskDetails.reducer,
];

export const initialState = merge(
  getTaskDetails.initialState,
  updateTaskDetails.initialState,
);
