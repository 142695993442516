import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, noop } from 'lodash';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Select, Textarea } from '@moved/ui';

import { getInsuranceSettings, createInsuranceOverride } from '../actions';
import { useInsuranceSettings } from '../actions/selectors';

import { PolicyForm } from './PolicyForm';

const POLICY_FORM_OPTION = 'provide-policy';

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  // state management
  const [pending, setPending] = useState(true);
  const [selectedCompletionOption, setSelectedCompletionOption] = useState(POLICY_FORM_OPTION);
  const [notes, setNotes] = useState('');

  // configuration data
  const insuranceSettings = useInsuranceSettings(tenantEvent.id,task.building_task_id) || {};
  const overrideOptions = [
    {
      label: 'Provide policy details',
      value: POLICY_FORM_OPTION,
    },
    ...get(insuranceSettings,'renters_insurance_purchase_options',[]).map(option =>({
      label: option.display_name,
      value: option.id,
    })),
  ];
  // trigger loading of configuration data
  useEffect(() => {
    dispatch(getInsuranceSettings(tenantEvent.id,task.building_task_id))
      .then(() => setPending(false))
      .catch(err => Notify.error(format.error(err)));
  },[tenantEvent,task,dispatch,Notify]);

  // submission handler
  const submitOverride = (e) => {
    if(submitting) return false;
    e.preventDefault(); // keep the form submission from refreshing page
    setSubmitting(true);
    const overrideData = {
      renters_insurance_purchase_option_id: selectedCompletionOption,
      notes,
    };
    dispatch(createInsuranceOverride(tenantEvent.id,task.building_task_id,overrideData))
      .then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        Notify.error(format.error(err));
      });
  };

  // can't initialize the form until the fields are determined
  if(pending) return <AtomSpinner/>;

  return (
    <>
      {overrideOptions.length > 1 && (
        <Select
          name='completionOption'
          label='Resident selection'
          options={overrideOptions}
          value={POLICY_FORM_OPTION}
          onChange={({ completionOption }) => setSelectedCompletionOption(completionOption)}
          className='marginBottom-28'
        />
      )}
      { selectedCompletionOption === POLICY_FORM_OPTION ? (
        <PolicyForm
          task={task}
          tenantEvent={tenantEvent}
          submitting={submitting}
          setSubmitting={setSubmitting}
          onSuccess={onSuccess}
        />
      ) : (
        <form
          id="artifact-form"
          onSubmit={submitOverride}
        >
          <Textarea
            label='Notes'
            name='note'
            value={notes}
            isControlled={true}
            onChange={({ note })=> setNotes(note)}
          />
        </form>
      )}
    </>
  );
};
