import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get} from 'lodash';
import classNames from 'classnames';

import { LoaderOverlay, Icon, DynamicForm, ConfirmationModal } from '@moved/ui';
import { fieldService, format, useModal, useNotify, useUser } from '@moved/services';
import { ProxyModeIcon } from '@moved/product';

import { getAdmin, updateAdmin, triggerAdminInvite } from '../actions/';
import { useAdmin, useAdminPending, useUpdateAdminPending } from '../actions/selectors';
import { AssignedProperty, AssignPropertiesModal } from './';

import CSS from './styles/AdminDetails.module.scss';

const _handleReturn = (state, history) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push('/admin/admins/?filter=upcoming');
};

export const AdminDetails = (props) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const Modal = useModal();
  const Notify = useNotify();
  const { proxyAs, Can } = useUser();

 // Get move id from URL
  const { id } = useParams();

  // MOVE DETAILS STATE
  const admin = useAdmin(id);
  const pending = useAdminPending();
  const updatePending = useUpdateAdminPending();

  const openAssignModal = admin => Modal.open(
    <AssignPropertiesModal admin={admin} />,
    { hideCloseButton: false, theme: 'grey' }
  );

  const handleResendInvitation = () => dispatch(triggerAdminInvite(admin.id)).catch(() => Notify.error());
  const openInvitationModal = () => Modal.open(
    <ConfirmationModal
      title={`${admin.signup_status === 'New' ? 'Send' : 'Resend'} invitation`}
      copy={`Are you sure you wish to send this invitation to ${admin.email}?`}
      confirmText='Send'
      cancelText='Nevermind'
      onConfirm={handleResendInvitation}
    />,
    { sondheim: true },
  );

  const handleUpdate = data => {
    dispatch(updateAdmin(admin.id, data))
    .then(() => {
      Notify.default(`Admin details have been updated!`);
    })
    .catch(error => Notify.error(format.error(error)));;
  }

  useEffect(() => {
    dispatch(getAdmin(id));
  // eslint-disable-next-line
  },[]);

  if(!admin) return (
    <>
      <Helmet>
        <title>{`Admins`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  const fields = [
    {
      label: 'General',
      type: 'title',
    },
    {
      label: 'First name',
      type: 'text',
      name: 'firstname',
      half: true,
      required: true,
    },
    {
      label: 'Last name',
      type: 'text',
      name: 'lastname',
      half: true,
      required: true,
    },
    {
      label: 'Email address',
      type: 'email',
      name: 'email',
      required: true,
    },
  ];

  fieldService.hydrate(fields,admin);

  return (
    <>
      <Helmet>
        <title>{`${get(admin,'firstname')} ${get(admin,'lastname')} Admin Abode Moves : Moved`}</title>
      </Helmet>

      {pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.header}>
          <div className={CSS.title_area}>

            <h1 className={CSS.title}>
              <span className={CSS.back} onClick={e => _handleReturn(location.state, history)}>
                <Icon symbol='Arrow-left' library='navigation' size='32px' />
              </span>
              <span className={CSS.title_text}>{get(admin,'firstname')} {get(admin,'lastname')}</span>
              <div className={classNames(CSS.pill,CSS[admin.signup_status.toLowerCase()])}>
                {admin.signup_status}
              </div>
            </h1>

            <h4 className={CSS.subtitle}>
              {get(admin,'partner.name')}
            </h4>
          </div>
          <div className={CSS.action_area}>
            <Can I={'ProxyAsUsers'}>
              { get(admin,'user_id') && (
                <div className={classNames(CSS.btn_assign,'mr-10')} onClick={e => proxyAs(get(admin,'user_id'))}>
                  <ProxyModeIcon className='mr-10' style={{height:'18px',width:'18px'}}/>
                  <span>Proxy as {get(admin,'firstname')}</span>
                </div>
              )}
            </Can>
            <Can I="CreateAdminInvites">
              <div className={CSS.btn_assign} onClick={openInvitationModal}>
                <Icon symbol='Send' library='communication' size='18px' />
                <span>
                  {admin.signup_status === 'New' ? 'Send invitation' : 'Resend invitation'}
                </span>
              </div>
            </Can>
          </div>
        </div>

        <div className={CSS.attributes}>
          <DynamicForm
            id='admin-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleUpdate}
          />

          <div className={CSS.controls}>
            <label
              htmlFor="admin-form-submit"
              tabIndex="0"
              role="button"
              className={'btn-primary btn--small' + (updatePending  ? ' loading' : '')}
              disabled={updatePending}
            >
              Update
            </label>
          </div>

          <div className={CSS.properties}>
            <div className={CSS.properties_title}>
              <h3>Properties</h3>
              <Can I="CreateBuildingAdmins">
                <div className={CSS.btn_assign} onClick={() => openAssignModal(admin)}>
                  <Icon symbol='Plus' library='navigation' size='18px' />
                  <span>Assign property</span>
                </div>
              </Can>
            </div>
            <div className={CSS.properties_list}>
              {admin.building_admins.map(buildingAdmin => (
                <AssignedProperty buildingAdmin={buildingAdmin} admin={admin} key={buildingAdmin.id} />
              ))}
            </div>
          </div>

        </div>

      </div>
    </>
  );
};
