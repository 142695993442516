import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CREATE_FILE_REQUIREMENT_LIST',
  request: (id, data={}) => request.post(`/admins/building-tasks/${id}/document-task-file-requirement-lists`, data).then(r => get(r,'data.data.document_task_file_requirement_list')),
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        fileRequirementLists: {
          ...state.fileRequirementLists,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateFileRequirementListPending,
} = generate.redux(creator);

export const initialState = {};
