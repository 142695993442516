import React, { useState } from 'react';

import { ChooseChapters } from './ChooseChapters';
import { PreviewChapter } from './PreviewChapter';
import { ChooseProperties } from './ChooseProperties';
import { ConfirmChoices } from './ConfirmChoices';
import { SuccessScreen } from './SuccessScreen';

import CSS from './styles/CloneChaptersModal.module.scss';

export const CloneChaptersModal = ({ id, single = false, initialData = {}}) => {

  // STATE
  const [progress, setProgress] = useState(1);
  const [dataCollection, setDataCollection] = useState(initialData);
  const [success, setSuccess] = useState({});

  const flow = [
    single ? PreviewChapter : ChooseChapters,
    ChooseProperties,
    ConfirmChoices,
    SuccessScreen,
  ];

  const FlowComponent = flow[progress-1]

  return (
    <div className={CSS.content}>
      <FlowComponent
        id={id}
        dataCollection={dataCollection}
        setDataCollection={setDataCollection}
        success={success}
        setSuccess={setSuccess}
        progress={progress}
        setProgress={setProgress}
        length={flow.length - 1}
      />
    </div>
  );
};
