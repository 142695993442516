import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'MOVES_GET_PARTNER_COUPONS',
  request: (id) => request.get(`/cx/partners/${id}/coupons`).then(r => get(r,'data.data.coupons')),
  selector: (state, id) => get(state,`partnerCoupons.${id}`),
  cache: true, // because response is array, this works until page refresh
  reducers: {
    success: (state, { response: coupons, params: [id] }) => ({
      ...state,
      partnerCoupons: {
        ...state.partnerCoupons,
        [id]: coupons,
      },
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      partnerCoupons: {
        ...state.partnerCoupons,
        [id]: null,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: usePartnerCoupons,
  usePending: usePartnerCouponsPending,
} = generate.redux(creator);

export const initialState = {
  partnerCoupons: {},
};
