import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_ADMINS_UPDATE_ADMIN_DETAILS',
  request: (id, data) => request.patch(`/admins/admins/${id}`, data).then(r => get(r,'data.data.admin')),
  selector: (state,id) => get(state,`admins[${id}]`),
  reducers: {
    success: (state, { response: admin }) => {

      return {
        ...state,
        admins: {
          ...state.admins,
          [admin.id]: admin,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateAdminPending,
} = generate.redux(creator);

export const initialState = {
  admins: {},
};
