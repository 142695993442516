import React from 'react';
import classNames from 'classnames';

import { useModal } from '@moved/services';
import { DynamicForm } from '@moved/ui';

export const DeclineReasonModal = ({ submit, usePending }) => {
  // redux
  const reviewPending = usePending();
  const Modal = useModal();

  const fields = [{
    label: 'Reason',
    type: 'textarea',
    name: 'notes',
    value: null,
    minRows: 3,
    required: 'Required.',
    placeholder: 'Enter reason for declining',
  }];

  const submitReview = (data) => {
    if(reviewPending) return;
    submit(data)
      .then(() => Modal.close());
  };

  return (
    <div style={{width:'500px'}}>
      <h2 className='mb-20'>Provide a reason</h2>
      <DynamicForm
        id="notes-form"
        fields={fields}
        onSubmit={submitReview}
        disabled={reviewPending}
      />
      <div>
        <label
          htmlFor="notes-form-submit"
          tabIndex="0"
          role="button"
          className={classNames('btn--full btn-ghost-red mt-20',{'loading': reviewPending})}
          disabled={reviewPending}
        >
          Submit
        </label>
      </div>
    </div>
  );

};
