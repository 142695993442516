import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_MOVES_GET_TENANT_EVENT_DETAILS',
  request: (id) => request.get(`/admins/tenant-events/${id}`).then(r => get(r,'data.data.tenant_event')),
  selector: (state,id) => get(state,`tenantEvents.${id}`),
  reducers: {
    success: (state, { response: tenantEvent, params: [id] }) => ({
      ...state,
      tenantEvents: {
        ...state.tenantEvents,
        [id]: tenantEvent,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useTenantEvent,
  usePending: useTenantEventPending,
} = generate.redux(creator);

export const initialState = {
  tenantEvents: {},
};
