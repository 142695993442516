import { get } from 'lodash';

const orderStatusList = [
  {
    value: 'vendor_pending',
    label: 'Vendor Pending',
    canCancel: true,
    canSendOffer: true,
    canAddCoupon: true,
    canEditOrderDetails: true,
  },
  {
    value: 'booked',
    label: 'Booked',
    canCancel: true,
    canSendOffer: true,
    canAddCoupon: true,
    canReschedule: true,
    canEditRate: true,
    canEditOrderDetails: true,
  },
  {
    value: 'cancelled',
    label: 'Canceled',
  },
  {
    value: 'completed',
    label: 'Completed',
  }
];

const paymentStatusList = [
  {
    value: 'no_payment_method',
    label: 'Needs Payment',
    canAddPayment: false, // FUTURE: toggle this feature flag when ready to activate this feature
  },
  {
    value: 'valid_payment_method',
    label: false, // show no pill for this status
  },
  {
    value: 'pre_authorization_failed',
    label: 'Pre-auth Failed',
    canResolvePayment: true,
  },
  {
    value: 'authorized',
    label: 'Authorized',
  },
  {
    value: 'payment_issue',
    label: 'Payment Issue',
    canResolvePayment: true,
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const hasAcceptedVendor = (order) => !!get(order,'vendor');

const findOrderStatus = (value) => orderStatusList.find(s => s.value === value);
const findPaymentStatus = (value) => paymentStatusList.find(s => s.value === value);

const getStatusPermission = (order, permissionKey) => {
  const orderStatus = findOrderStatus(order.status);
  const paymentStatus = findPaymentStatus(order.payment_status);
  return get(orderStatus,permissionKey) || get(paymentStatus,permissionKey) || false;
};

export const getOrderStatusLabel = (status) => {
  const match = findOrderStatus(status);
  return match ? match.label : '';
};

export const getPaymentStatusLabel = (status) => {
  const match = findPaymentStatus(status);
  return match ? match.label : '';
};

export const isOrderCancellable = (order) => getStatusPermission(order,'canCancel');

export const isOrderOfferable = (order) => getStatusPermission(order,'canSendOffer');

export const canAddPayment = (order) => getStatusPermission(order,'canAddPayment');

export const canAddCoupon = (order) => {
  if(get(order,'coupons.length')) return false; // only allow one coupon ATM
  return getStatusPermission(order,'canAddCoupon');
};

export const isOrderReschedulable = (order) => getStatusPermission(order,'canReschedule');

export const isOrderRateEditable = (order) => getStatusPermission(order,'canEditRate');

export const isOrderDetailsEditable = (order) => getStatusPermission(order,'canEditOrderDetails');

export const canResolvePayment = (order) => getStatusPermission(order,'canResolvePayment');
