import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import { SectionDisplay } from './SectionDisplay';
import { DetailsStopDisplay } from './DetailsStopDisplay';
import { Reschedule, UpdateOrderDetails } from '../buttons';

import CSS from '../../styles/DetailsSection.module.scss';

const PartnerLogo = ({ order }) => (
  <img className={CSS.partner_logo}
    src={`${get(order,'mover_booking.partner.logo_url')}`}
    alt={get(order,'mover_booking.partner.name')}
  />
);

export const DetailsSection = ({ order }) => {
  const details = {
    title: 'Move details',
    actions: [
      (<Reschedule order={order} key='reschedule'/>),
      (<UpdateOrderDetails order={order} key='update-details'/>),
    ],
    columns: [
      [
        {
          label: 'Pickup date',
          value: format.date(get(order,'service_date'),'date'),
        },
        get(order,'mover_booking.time_preference') && {
          label: 'Time preference',
          value: get(order,'mover_booking.time_preference'),
        },
        ...get(order,'mover_booking.stops',[]).map((stop,index,stops) => ({
          label: index === 0 ? 'Origin' : index === stops.length - 1 ? 'Destination' : `Stop ${index}`,
          value: (<DetailsStopDisplay stop={stop}/>),
        })),
      ].filter(v => v),
      [
        get(order,'mover_booking.inventory_text') && {
          label: 'Inventory',
          value: get(order,'mover_booking.inventory_text'),
        },
        get(order,'mover_booking.building.name') && {
          label: 'Building',
          value: get(order,'mover_booking.building.name'),
        },
        get(order,'mover_booking.partner.logo_url') && {
          label: 'Partner',
          value: (<>
            { get(order,'mover_booking.partner.cx_doc_url') ? (
              <a href={get(order,'mover_booking.partner.cx_doc_url')} target='_blank' rel='noopener noreferrer'>
                <PartnerLogo order={order}/>
              </a>
            ) : (
              <PartnerLogo order={order}/>
            )}
          </>),
        },
      ].filter(v => v),
    ],
  };

  return (
    <SectionDisplay section={details}/>
  );

};
