import { merge } from 'lodash';

import * as createBuildingReservation from './createBuildingReservation';
import * as createBuildingReservationOverride from './createBuildingReservationOverride';
import * as getTenantBuildingReservationOptions from './getTenantBuildingReservationOptions';
import * as getTenantBuildingReservationCalendar from './getTenantBuildingReservationCalendar';

export const reducers = [
  createBuildingReservation.reducer,
  createBuildingReservationOverride.reducer,
  getTenantBuildingReservationOptions.reducer,
  getTenantBuildingReservationCalendar.reducer,
];

export const initialState = merge(
  createBuildingReservation.initialState,
  createBuildingReservationOverride.initialState,
  getTenantBuildingReservationOptions.initialState,
  getTenantBuildingReservationCalendar.initialState,
);
