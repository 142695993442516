import React from 'react';
import { merge } from 'lodash';

import { TagRenderer } from '@moved/ui';
import { tagContextBuilder } from '@moved/product';

export const defaultBuilding = {
  name: 'PropertyName',
  partner: {
    name: 'PartnerName',
    id: 123,
    guid: 'property-guid',
  },
  community_email: 'PropertyEmail',
  community_phone: 'PropertyPhone',
  external_resident_app_url: 'http://www.example.com',
  pms_label: 'PMSName'
};
export const defaultLease = {
  external_lease_number: 'LeaseNumber',
  start_date: 'LeaseStartDate',
};
export const defaultTenant = {
  external_contact_id: 'TenantCode',
  firstname: 'TenantFirstName',
  lastname: 'TenantLastName',
  fullname: 'TenantFullName',
  email: 'TenantEmail',
};

export const AdminTagRenderer = ({
  building=defaultBuilding,
  lease=defaultLease,
  tenant=defaultTenant,
  tags={},
  ...props
}) => {
  const adminTags = tagContextBuilder({
    building,
    lease,
    tenant,
  });

  return (
    <TagRenderer
      {...props}
      tags={merge(adminTags,tags)}
    />
  );
};
