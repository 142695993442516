import React from 'react';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { request, format } from '@moved/services';

import { Button } from '../../../../../sondheim/components/controls/Button/Button';

import CSS from './Button.module.scss';

export const ButtonDisplay = ({ data, tool, tags }) => {
  const { text, url, color='secondary', customColor } = data;
  const interpolatedUrl = format.interpolate(unescape(url),tags);
  const interpolatedText = format.interpolate(text,tags);

  const trackClick = () => {
    const { move_id, move_step_id, content_editor_id } = tags;
    if(!content_editor_id || !move_id) return true;
    return request.post(`/residents/content-editor-contents/${content_editor_id}/link-clicks`,{
      move_id,
      move_step_id,
      url: interpolatedUrl,
      cta_text: interpolatedText,
    });
  };

  const handleClick = () => {
    trackClick() && window.open(interpolatedUrl,'_blank','noopener');
  };

  return (
    <div className={classNames(CSS.render,tool.className)}>
      <Button
        text={interpolatedText}
        color={color}
        className={classNames(CSS.button,CSS[color])}
        customColor={customColor}
        onClick={handleClick}
      />
    </div>
  );
};
