import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_FEED_SYNC_DETAILS',
  request: (id, syncId) => request.get(`/admins/buildings/${id}/feed-syncs/${syncId}`).then(r => get(r,'data.data.feed_sync')),
  selector: (state, id, syncId) => get(state,`buildingFeedSyncs.${id}-${syncId}`),
  reducers: {
    success: (state, { response: feedSync, params: [id, syncId] }) => ({
      ...state,
      buildingFeedSyncs: {
        ...get(state,'buildingFeedSyncs'),
        [`${id}-${syncId}`]: feedSync,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useBuildingFeedSync,
  usePending: useBuildingFeedSyncPending,
} = generate.redux(creator);

export const initialState = {};
