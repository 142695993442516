import React, { useState } from 'react';
import moment from 'moment'
import { useUpdateEffect } from 'react-use';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file

import { Icon } from '../../../../sondheim/components/Icon';
import CSS from './styles/FilterDateRangeSelect.module.scss';
import calCSS from './styles/DateRangeSelect.module.scss';

export const FilterDateRangeSelect = ({ list, title = 'Select', week = false, setVisible, active, setActive }) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  // HOOKS
  const [range, setRange] = useState({
    startDate:  active[0] ? new Date(active[0]) : new Date(),
    endDate:  active[1] ? new Date(active[1]) : new Date(),
    key: 'selection',
  });
  const [startInput, setStartInput] = useState(moment(new Date(range.startDate)).format(DATE_FORMAT));
  const [endInput, setEndInput] = useState(moment(new Date(range.endDate)).format(DATE_FORMAT));


  const _handleBlur = e => {
    const isStart = (e.target.id === 'startDateInput');
    const typedDate = moment(new Date(e.target.value));
    const otherDate = isStart ? range.endDate : range.startDate;

    if(typedDate.isValid()) {
      if(isStart)
        setStartInput(typedDate.format(DATE_FORMAT));
      else
        setEndInput(typedDate.format(DATE_FORMAT));

      return setRange({
        ...range,
        startDate: typedDate.isAfter(otherDate) ? otherDate : new Date(e.target.value),
        endDate: typedDate.isAfter(otherDate) ? new Date(e.target.value) : otherDate,
      });

    } else {
        return isStart
        ? setStartInput(active[0])
        : setEndInput(active[1]);
    }
  };

  useUpdateEffect(() => {
    setStartInput(moment(new Date(range.startDate)).format(DATE_FORMAT));
    setEndInput(moment(new Date(range.endDate)).format(DATE_FORMAT));
    setActive(range.startDate !== null ? [
      moment(new Date(range.startDate)).format(DATE_FORMAT),
      moment(new Date(range.endDate)).format(DATE_FORMAT)
    ]: []);
  },[range.startDate, range.endDate]);  // eslint-disable-line

  return (
    <>
      <div className={CSS.date_inputs}>
        <div className={CSS.date_bar}>
          <input
            className={CSS.date_input}
            id="startDateInput"
            value={startInput}
            onChange={(e) => setStartInput(e.target.value)}
            onBlur={_handleBlur}
            onKeyUp={(e,input) => {
              if (e.keyCode === 13) {
                return _handleBlur(e);
              } else {
                return null;
              }
            }}
            type="text"
            autoComplete="off"
          />
          <label className={CSS.input_icon} htmlFor="startDateInput">
            <Icon symbol={'Date'} library={'code'} size={'24px'} />
          </label>
        </div>
        <div className={CSS.date_bar}>
          <input
            className={CSS.date_input}
            id="endDateInput"
            value={endInput}
            onChange={(e) => setEndInput(e.target.value)}
            onBlur={_handleBlur}
            onKeyUp={(e,input) => {
              if (e.keyCode === 13) {
                return _handleBlur(e);
              } else {
                return null;
              }
            }}
            type="text"
            autoComplete="off"
          />
          <label className={CSS.input_icon} htmlFor="endDateInput">
            <Icon symbol={'Date'} library={'code'} size={'24px'} />
          </label>
        </div>
      </div>
      <div className={CSS.calendar}>
        <div className={calCSS.calendarWrapper}>
          <DateRange
            onChange={item => setRange(item.selection)}
            showSelectionPreview={false}
            showMonthAndYearPickers={false}
            weekdayDisplayFormat={'EEEEE'}
            monthDisplayFormat={'MMMM yyyy'}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            ariaLabels={{
              nextButton: 'next'
            }}
            months={2}
            ranges={[range]}
            editableDateInputs={true}
            direction="horizontal"
          />
        </div>
      </div>
    </>
  );
};
