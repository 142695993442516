import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import { format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

export const PolicyDetails = ({ data, updateData, onSubmit, settings }) => {

  const fields = [
    {
      label: 'Policy details',
      type: 'title',
    },
    {
      label: 'Insurance company',
      type: 'text',
      name: 'insurance_provider',
      required: true,
    },
    {
      label: 'Policy number',
      type: 'text',
      name: 'policy_number',
      required: true,
    },
    {
      label: 'Liability coverage amount',
      type: 'currency',
      name: 'liability_coverage_amount',
      required: true,
    },
    {
      label: 'Policy Term',
      type: 'slideToggle',
      name: 'policy_term',
      default: 12,
      required: true,
      toggleProps: { full: true, rectangular: true },
      onChange: (newValue, setFieldValue, name) => {
        if(newValue !== 'other' && get(data,'coverage_start')) {
          const coverageEndDate = moment(get(data,'coverage_start')).add(newValue,'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
      options: [
        { label: '12 months', value: 12, },
        { label: '6 months', value: 6, },
        { label: 'Other', value: 'other', },
      ]
    },
    {
      label: 'Start date',
      type: 'datePicker',
      name: 'coverage_start',
      half: true,
      required: true,
      maxDate: get(settings,'latest_coverage_start_date'),
      onChange: (newValue, setFieldValue, name) => {
        if(get(data,'policy_term') !== 'other') {
          const coverageEndDate = moment(newValue).add(get(data,'policy_term',12),'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
    },
    {
      label: 'End date',
      type: 'datePicker',
      name: 'coverage_end',
      half: true,
      required: true,
      minDate: get(settings,'latest_coverage_start_date'),
      onChange: (newValue, setFieldValue, name) => {
        if(get(newValue,'coverage_start')) {
          const term = moment(newValue).diff(get(data,'coverage_start'),'months',true);
          if(term === 12) { if(get(data,'policy_term') !== 12) setFieldValue('policy_term',12); }
          else if(term === 6) { if(get(data,'policy_term') !== 6) setFieldValue('policy_term',6); }
          else { if(get(data,'policy_term') !== 'other') setFieldValue('policy_term','other'); }
        }
      },
    },
  ];

  const validation = Yup.object().shape({
    liability_coverage_amount: Yup.number()
      .required('Liability coverage amount is required')
      .min(
        settings.minimum_coverage_requirement ? settings.minimum_coverage_requirement : 10000000,
        `Liability coverage amount must be at least ${settings.minimum_coverage_requirement ? format.currency(settings.minimum_coverage_requirement) : '$100,000'}`
      )
      .max(2000000000,'Maximum coverage amount is $20,000,000.00'),
    coverage_end: Yup.date('End date must be a valid date').when('coverage_start',
      (coverage_start, schema) => {
        if(coverage_start && moment(coverage_start).isValid()) {
          return schema
            .min(
              moment(coverage_start).add(1, 'day').toDate(),
              `Coverage end date must be after start date`
            )
            .max(
              moment(coverage_start).add(2,'year').toDate(),
              `Coverage end date must be within two years of start date.`
            );
        } else {
          return schema.min(
            moment().add(1, 'day').toDate(),
            `Coverage end date must be after start date`
          );
        }
      },
    ),
  },['coverage_start','coverage_end']);

  return (
    <DynamicForm
      id="artifact-form"
      fields={fields}
      validation={validation}
      onSubmit={onSubmit}
      onChange={updateData}
      formStyle='underline'
    />
  );
};
