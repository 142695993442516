import React from 'react';
import { useLocation, matchPath, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import CSS from './MenuItem.module.scss';

export const MenuItem = ({ activeRef, urlPath, label, icon, external, isCollapsed }) => {
  const location = useLocation();
  if(external) return (
    <li className={classNames(CSS.menu_item,{[CSS.collapsed]:isCollapsed})}>
      <a href={urlPath} className='flex'>
        <Icon size='24px' {...icon} className={CSS.icon} /><span className={CSS.label}>{label}</span>
      </a>
    </li>
  );
  return (
    <li
      className={classNames(CSS.menu_item,{[CSS.collapsed]:isCollapsed})}
      ref={matchPath(location.pathname, urlPath.split("?")[0]) ? activeRef : null}
    >
      <NavLink to={urlPath} activeClassName={CSS.active} className="flex">
        <Icon size='24px' {...icon} className={CSS.icon} /><span className={CSS.label}>{label}</span>
      </NavLink>
    </li>
  );
};
