import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { useNotify } from '@moved/services';
import { LayoutPromo, Four04 } from '@moved/product';

import { getPartner } from '../actions';

export const Partner = ({ children }) => {
  const { partnerSlug } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    // refresh the partner data if possible
    if(partnerSlug) dispatch(getPartner(partnerSlug))
      .then(() => setInitialized(true))
      .catch((error) => {
        setFailure(true);
        if(get(error,'response.status') === 404) {
          Notify.default(`Uh oh. We don't recognize this partner name.`);
        }
        setInitialized(true);
      });
    // if no partner can be determined
    else setInitialized(true);
  },[partnerSlug]); // eslint-disable-line

  return (
    initialized && (
      failure ? (<LayoutPromo><Four04 /></LayoutPromo>) : children
    )
  );

};

export const withPartner = (Wrapped) => (props) => (<Partner><Wrapped {...props}/></Partner>);
