import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import CSS from '../../styles/DetailsStopDisplay.module.scss';

export const DetailsStopDisplay = ({ stop }) => (
  <>
    <div>
      { format.address(stop.address,{unit:false,city:false,state:false,zipcode:false}) }
    </div>
    <div>
      { format.address(stop.address,{street:false,unit:false}) }
    </div>
    { (get(stop,'address.unit') || get(stop,'floor')) && (
      <div className={CSS.address_items}>
        {get(stop,'address.unit') && (
          <div className={CSS.address_item}>
            <h6>Unit</h6>
            <span>{get(stop,'address.unit')}</span>
          </div>
        )}
        {get(stop,'floor') && (
          <div className={CSS.address_item}>
            <h6>Floor</h6>
            <span>{get(stop,'floor')}</span>
          </div>
        )}
        {(get(stop,'has_elevator') || get(stop,'flights_of_stairs') > 0) && (
          <div className={CSS.address_item}>
            <h6>Elevator/Stairs</h6>
            <span>{get(stop,'has_elevator') ? 'Elevator' : `${get(stop,'flights_of_stairs')} Flights of Stairs`}</span>
          </div>
        )}
      </div>
    )}
  </>
);
