import React from 'react';

import { useAnalyticsQuery, format } from '@moved/services';

import { Statistic } from './Statistic';

export const TotalTimeSavings = ({ client, dateRange }) => {

  const { resultSet, isLoading, error } = useAnalyticsQuery({
    measures: [
      'CompletedTasks.timeSavings'
    ],
    timeDimensions: [
      {
        dimension: 'CompletedTasks.activityTime',
        dateRange
      }
    ],
    filters: [
      {
        member: 'CompletedTasks.partnerId',
        operator: 'equals',
        values: [client.id],
      }
    ]
  });

  let displayValue;
  if(error) displayValue = error.toString();
  else if(resultSet) {
    const [{ 'CompletedTasks.timeSavings': totalTimeSavings }] = resultSet.tablePivot();
    displayValue = format.number(totalTimeSavings);
  }

  return <Statistic label='Total Time Savings' value={!isLoading && `${displayValue}`} description={(<>so many <span className="primary bold">hours</span> saved!</>)} />;

};
