import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '@moved/services';
import { Button } from '@moved/ui';

import { LeaseDetails } from '../../../common/components/LeaseDetails';

export const MoveStepDetails = ({ moveStep }) => {
  const { Can } = useUser();
  const history = useHistory();
  return (
    <>
      <LeaseDetails lease={moveStep.lease} tenant={moveStep.tenant} building={moveStep.building} stepType={moveStep.type} />
      <Can I='ReadTenantEvents'>
        <div className='w-100 flex justify-center'>
          <Button
            size='large'
            color='tertiary'
            text='View Tasks'
            icon={{ library: 'navigation', symbol: 'Chevron-right' }}
            iconPosition='right'
            onClick={() => history.push(`/admin/moves/${moveStep.tenant_event_id}`)}
          />
        </div>
      </Can>
    </>
  );
};
