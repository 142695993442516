import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_CREATE_RENTERS_INSURANCE_POLICY',
  request: (tenantEventId, buildingTaskId, data) => request.post(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/renters-insurance-policies`,data)
    .then(r => get(r,'data.data.renters_insurance_policy')),
  reducers: {
    success: (state, { response: insurancePolicy, params: [tenantEventId] }) => ({
      ...state,
      tenantEvents: {
        ...state.tenantEvents,
        [tenantEventId]: {
          ...state.tenantEvents[tenantEventId],
          renters_insurance_policies: [
            ...(state.tenantEvents[tenantEventId].renters_insurance_policies || []),
            insurancePolicy,
          ],
        },
      },
    })
  }
};

export const {
  action,
  reducer,
  usePending: useCreateInsurancePolicyPending,
} = generate.redux(creator);

export const initialState = {};
