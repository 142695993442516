import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import { SectionDisplay } from './SectionDisplay';
import { AddDiscount } from '../buttons';

import CSS from '../../styles/CouponSection.module.scss';

export const DiscountSection = ({ order, move }) => {
  const details = {
    title: 'Discount',
    actions: [
      (<AddDiscount order={order} move={move} key='add-discount'/>),
    ].filter(v => v),
  };

  if(get(order, 'discounts.length')) details.columns = [
      order.discounts.map(discount => ({
        label: 'Amount',
        value: format.currency(discount.amount),
      })),
      order.discounts.map(discount => ({
        label: 'Provided by',
        value: get(discount, 'sponsor_partner.name'),
      })),
    ];
  else details.rows = [(
    <div className={CSS.none} key='no-discount'>No discount has been applied</div>
  )];

  return (
    <SectionDisplay section={details}/>
  );

};
