import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Icon } from '../Icon';

import CSS from './Pagination.module.scss';

export const Pagination = ({ page, pageCount, onPageChange=noop, className }) => {
  const [pages, setPages] = useState([1]);

  const updatePage = (next) => {
    if(next === page || next === 0 || next === pageCount+1) return;
    if(onPageChange) onPageChange(next);
  };

  useEffect(() => {
    const pager = [];
    if(pageCount <= 10) Array.from({length: pageCount},(v, i) => i).forEach(i => pager.push(i + 1));
    else {
      pager.push(1);
      [-3,-2,-1,0,1,2,3].forEach(i => {
        if(page + i > 1 && page + i < pageCount) pager.push(page + i);
      });
      pager.push(pageCount);
    }
    setPages(pager);
  }, [pageCount, page]);

  return (
    <div className={classNames(CSS.pagination,className)}>
      <span
        className={classNames(CSS.page,CSS.turn_page,{[CSS.disabled]:page === 1})}
        onClick={() => updatePage(page - 1)}
      >
        <Icon symbol='Chevron-left' library='navigation' />
      </span>

      { pages.map((p,i) => (
        <Fragment key={p}>
          { (p > 1 && pages[i-1] !== p - 1) && (
            <span className={classNames('labelM',CSS.spacer)}>&hellip;</span>
          )}
          <span
            className={classNames('labelM',CSS.page, {[CSS.active]: p === page})}
            onClick={() => updatePage(p)}
          >
            { p }
          </span>
        </Fragment>
      ))}

      <span className={classNames(CSS.page,CSS.turn_page,{[CSS.disabled]:page === pageCount})}
        onClick={() => updatePage(page + 1)}>
        <Icon symbol='Chevron-right' library='navigation' />
      </span>
    </div>
  );
};

Pagination.propTypes = {
  /** Current active page number */
  page: PropTypes.number.isRequired,
  /** Total pages to support navigation */
  pageCount: PropTypes.number.isRequired,
  /** Callback to handle firing a page change event */
  onPageChange: PropTypes.func,
  /** (optional) class name to add to the pagination component */
  className: PropTypes.string,
};
