import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { Icon, PopOver, DragDropList, EmptyContent } from '@moved/ui';
import { format, useNotify, useModal } from '@moved/services';

import { reorderFileRequirements } from '../actions';
import { CreateFileReqModal } from './CreateFileReqModal'

import CSS from './styles/FileRequirements.module.scss';

export const FileRequirements = ({ task, fileList }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const Notify = useNotify();
  const Modal = useModal();

 // Get id from URL
  const { task_id } = useParams();

  const { file_requirements: requirements = [] } = fileList;

   // Update state of chapters after drag
  const _onChange = result => {
    if(!result) return null;
    return dispatch(
      reorderFileRequirements(
        fileList.id,
        task_id,
        { document_task_file_requirement_ids: get(result,'docs.list').map(doc => doc.id) }
      )
    )
      .catch(error => Notify.error(format.error(error)));
  };

  const openCreateModal = () => Modal.open(
    <CreateFileReqModal list={fileList} task={task} />,
    {
        theme: 'grey',
    }
  );

  return (
    <div className={CSS.docs}>
      <div className={CSS.section_title}>
        <h3>Documents</h3>
        <div className={CSS.doc_actions}>
          <div className={CSS.new_document} onClick={openCreateModal}>
            <Icon symbol='Plus' library='code' size="20px" />
            <span>Add document</span>
          </div>
        </div>
      </div>
      {requirements.length > 0 ? (
        <DragDropList
          lists={{
            docs: {
              list: requirements,
              contentLeft: document => (
                <div
                  className={CSS.title}
                  onClick={e => {
                    e.preventDefault();
                    return history.push(`${location.pathname}/documents/${document.id}`);
                  }}
                >
                  <Icon symbol={document.icon || 'File'} size={'20px'} className={CSS.doc_icon} />
                  <h4>{document.display_name}</h4>
                </div>
              ),
              contentRight: document => (
                <div className={CSS.icons}>
                  <PopOver
                    id={`more_${document.id}`}
                    customClass={CSS.property_popover}
                    className={classNames(CSS.icon,CSS.icon_more)}
                    hideArrow="true"
                    placement="bottom-end"
                    trigger="click"
                    delayHide={400}
                    tooltip={(
                      <div id={'popover_more_options'}>
                          <div
                            className={CSS.popover_action}
                            onClick={e => {
                              e.preventDefault();
                              return history.push(`${location.pathname}/documents/${document.id}`);
                            }}
                          >
                            <Icon symbol={'Edit'} library={'design'} size={'20px'} className={CSS.popover_icon} />
                            <span className={CSS.popover_link}>Edit</span>
                          </div>
                      </div>
                    )}
                    stopPropagation
                  >
                    {(tooltipShown) => (
                      <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
                    )}
                  </PopOver>
                </div>
              ),
            },
          }}
          onChange={_onChange}
        />
      ) : (
        <EmptyContent
          message={'No documents yet!'}
        />
      )}
    </div>
  );
};
