import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { useProperty } from '../actions/selectors';

export const PropertyTabRedirect = () => {
  const history = useHistory();
  const { property_id } = useParams();
  const property = useProperty(property_id);
  const { pathname } = history.location;

  useEffect(() => {
    history.replace({
      pathname: get(property,'building_task_lists',[]).length > 0
        ? `${pathname}/task-lists/${get(property,'building_task_lists[0].id')}`
        : `${pathname}/settings`,
      state: {
        from: `/admin/properties`,
      }
    });
  },[]); // eslint-disable-line

  return false;
};
