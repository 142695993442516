import adminsRoutes from './admins/routes';
import clientsRoutes from './clients/routes';
import propertiesRoutes from './properties/routes';

const routes = [
  ...adminsRoutes,
  ...clientsRoutes,
  ...propertiesRoutes,
];


export default routes;
