import React from 'react';
import { Four04 } from '@moved/product';

import { Layout } from './Layout';

export const PageNotFound = () => {
  return (
    <Layout>
      <Four04 />
    </Layout>
  );
}
