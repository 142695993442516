import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../../../sondheim/components/Icon';

import CSS from './ClipboardIcon.module.scss';

export const ClipboardIcon = ({ text, icon, className, size }) => {
  // HOOKS
  const [copied, setCopied] = useState(false);

  const _copied = () => {
    setCopied(true);
    return setTimeout(() => setCopied(false),1200)
  }

  if(!text) return null;

  return (
    <CopyToClipboard text={text} onCopy={() => _copied()}>
      <div className={classNames(CSS.oval, {[className]: className, [CSS.copied]: copied})}>
        <div className={CSS.icons}>
          <Icon symbol={'Clipboard-list'} library={'files'} size={size} className={CSS.icon}/>
          <Icon symbol={'Clipboard-check'} library={'files'} size={size} className={classNames(CSS.icon,CSS.check)} />
        </div>
      </div>
    </CopyToClipboard>
  );
};

ClipboardIcon.propTypes = {
  /** Text to copy */
  text: PropTypes.string,
};
