import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_MOVES_MOVES_SEARCH',
  request: (data, cancelToken) => request.post(`/cx/move-summaries/search`, data, { cancelToken }).then(r => get(r,'data')),
  selector: (state) => get(state,'movesSearch'),
  reducers: {
    success: (state, { response: { pagination, data } }) => {
      return {
        ...state,
        movesSearch: {
          moves: data,
          totalResults: pagination.total_items,
          totalPages: pagination.total_pages,
        },
      }
    },
  }
};

export const {
  action,
  reducer,
  useResource: useMovesSearch,
  usePending: useMovesSearchPending,
} = generate.redux(creator);

export const initialState = {
  movesSearch: {},
};
