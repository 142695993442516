import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_BUILDING_CALENDAR',
  request: (id, data={}) => request.patch(`/admins/building-calendars/${id}`, data).then(r => get(r, 'data.data.building_calendar')),
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        buildingCalendars: {
          ...state.buildingCalendars,
          [id]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateBuildingCalendarPending,
} = generate.redux(creator);

export const initialState = {
  buildingCalendars: {},
};
