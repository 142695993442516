import React from 'react';

import CSS from '../styles/UploadImageModal.module.scss';

export const PreviewModal = ({ brandable, asset }) => {
  const { name, description, Preview } = asset;
  return (
    <div className={CSS.modal_contents}>
      <h3 className={CSS.title}>
        Preview { name }
      </h3>
      <p>{ description }</p>
      <div className={CSS.preview_wrapper}>
        <Preview brandable={brandable} />
      </div>
    </div>
  );
};
