import React from 'react';
import { useParams } from 'react-router-dom';

import { useModal, useUser, } from '@moved/services';

import { useProperty } from '../actions/selectors';
import { CreateDemoTenantModal } from './'
import CSS from './styles/PropertySettings.module.scss';

export const PropertyActions = () => {
  const Modal = useModal();
  const { Can } = useUser()
  const { property_id } = useParams();

  // PROPERTY DETAILS STATE
  const property = useProperty(property_id);

  const openCreateModal = () => Modal.open(
    <CreateDemoTenantModal property={property} />
  );

  return (
    <div className={CSS.main}>

      <div className={CSS.task_title}>
        <h3>Admin toolbox</h3>
      </div>

      <div className={CSS.attributes}>
        <Can I={'CreateTenants'}>
        <div className={'btn-gray btn--small'} onClick={openCreateModal}>
          Create demo tenants
        </div>
        </Can>
      </div>

    </div>
  );
};
