import React from 'react';

import { useAnalyticsQuery, format } from '@moved/services';

import { Statistic } from './Statistic';

export const TotalCompletedTasks = ({ client, dateRange }) => {

  const { resultSet, isLoading, error } = useAnalyticsQuery({
    measures: [
      'CompletedTasks.count'
    ],
    timeDimensions: [
      {
        dimension: 'CompletedTasks.activityTime',
        dateRange
      }
    ],
    filters: [
      {
        member: 'CompletedTasks.partnerId',
        operator: 'equals',
        values: [client.id],
      }
    ]
  });

  let displayValue;
  if(error) displayValue = error.toString();
  else if(resultSet) {
    const [{ 'CompletedTasks.count': totalTasks }] = resultSet.tablePivot();
    displayValue = format.number(totalTasks);
  }

  return <Statistic label='Total Completed Tasks' value={!isLoading && displayValue} description={(<>talk about <span className="primary bold">operational efficiency</span></>)}/>;

};
