import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { find } from 'lodash';

import { DynamicForm, AtomSpinner, Icon, PopOver } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services';

import { PropertyAddress } from '../../../../../admins';
import { getEligibleProperties } from '../../actions/';
import { useEligibleProperties, useEligiblePropertiesPending, useOrientationContents } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const ChooseProperties = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {
  // HOOKS
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();

  // PROPS
  const { content_editor_content_ids: chapters } = dataCollection;

  // REDUX
  const eligible = useEligibleProperties(id);
  const pending  = useEligiblePropertiesPending();
  const contents = useOrientationContents(id);

  useEffect(() => {
    dispatch(getEligibleProperties(id, {content_editor_content_ids: chapters.length > 0 ? chapters.join(',') : null,}))
      .catch(error => Notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);

  if(pending) return <AtomSpinner/>;


  const fields = [
    {
      type: 'itemList',
      name: 'building_ids',
      required: 'You must select at least one property.',
      isMulti: true,
      isNumeric: true,
      customItemClass: CSS.building_item,
      value: dataCollection.building_ids,
      options: eligible.map(property => {
        const building = property.building || {};
        return {
          label: (
            <>
            <div className={classNames(CSS.label)}>
              <div
                className={CSS.splash}
                style={{
                  ...{backgroundImage: building.dashboard_image_url ?
                    `url('${building.dashboard_image_url}')`
                    : null,
                  }
                }}
              />
              <div className={CSS.grow}>
                <div>{building.name}</div>
                <div className={CSS.address}>
                  <PropertyAddress address={building} />
                </div>
              </div>

            </div>
            {property.disabled && (
              <div className={CSS.disable_pill_holder}>
                <PopOver
                  customClass={CSS.popover}
                  customArrowClass={CSS.popover_arrow}
                  hideOnScroll={true}
                  placement="top"
                  trigger="hover"
                  tooltip={(
                    <span className={CSS.popover_text}>
                      {property.disabled_description}
                    </span>
                  )}
                >
                  <div className={CSS.disable_pill}>
                    <span className={CSS.pill_text}>{property.disabled_label.replace("_", " ").toLowerCase()}</span>
                  </div>
                </PopOver>
              </div>
            )}
            </>
          ),
          disabled: property.disabled,
          value: property.building.id
        };
      }),
    },
  ];

  const onSubmit = data => {
    setDataCollection({ ...dataCollection, ...data});
    return setProgress(progress+1);
  }

  const chosenChapters = chapters.map(id => find(contents, content => parseInt(content.id) === parseInt(id)));

  return (
      <>
        <h3 className={CSS.title}><span>{progress}/{length}</span>&nbsp;
          Clone {(chosenChapters.length > 1)
            ? (<>{chosenChapters.length} chapters</>)
            : (<>&lsquo;{chosenChapters[0].title}&rsquo;</>)
          } to
        </h3>

        <div className={CSS.content}>
          {eligible.length > 0
            ? (
              <DynamicForm
                id='property-form'
                formStyle='underline'
                fields={fields}
                onSubmit={onSubmit}
              />
            )
            : (
              <p>{`There are no eligible properties for cloning the selected chapter${chosenChapters.length > 1 ? 's' : ''}.`}</p>
            )
          }


        </div>

        <div className={CSS.actions}>
          <div className={CSS.actions_left}>
            {progress > 1 && (
              <label
                tabIndex="2"
                role="button"
                className={CSS.back}
                onClick={e => {
                  e.preventDefault();
                  return setProgress(progress-1);
                }}
              >
                <Icon symbol={'Arrow-left'} library={'navigation'} color={'black'} />
                <span>Back</span>
              </label>
            )}
          </div>
          <div className={CSS.actions_right}>
            <label
              tabIndex="1"
              role="button"
              className={'btn-gray'}
              onClick={e => {
                e.preventDefault();
                return Modal.close();
              }}
            >
              Cancel
            </label>

            <label
              id='property_form_button'
              htmlFor="property-form-submit"
              tabIndex="0"
              role="button"
              className={CSS.next}
            >
              <span>Next</span>
              <Icon symbol={'Arrow-right'} library={'navigation'} color={'white'} />
            </label>
          </div>
        </div>

      </>
  );
};
