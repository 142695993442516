/*
* This context exposes the list of currently rendered snippets on the screen
*
* The hook for this context `useSnippet` provides the following utilities:
*   updateSnippetList: Allows snippet tags to be added or removed from the screen context
*
* Use full context for class component
* example:
*    import { SnippetContext } from '@moved/services';
*    static contextType = SnippetContext;
*    const snippet = this.context.snippet;
*
* Use context hook for functional component
* example:
*    import { useSnippet } from '@moved/services';
*    const { snippets } = useSnippet();
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* example:
*    import { SnippetProvider } from '@moved/services';
*    render (
*      <SnippetProvider> ... </SnippetProvider>
*    )
*/

import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { filter, cloneDeep } from 'lodash';

import { useQuery } from '../utils/hooks/useQuery';

const SnippetContext = createContext();
const useSnippets = () => useContext(SnippetContext);

const SnippetProvider = ({ children }) => {
  const [list, setList] = useState([]);

  // Set visibility for snippet tag names
  const visible = useQuery('snippets');
  if(visible) localStorage.setItem('snippetVisible', visible);

  // function for adding tags to mounted list
  const add = useCallback(
    (id, tag, description) => setList(list => {
      const newList = cloneDeep(list);
      newList.push({tag, description, id});
      return newList;
    }),
    [setList]
  );

  // function for removing tags from mounted list
  const remove = useCallback(
    id => setList(list => filter(list, x => { return x.id !== id })),
    [setList]
  );

  // map the context API
  const context = useMemo(
    () => ({ list, add, remove }),
    [list, add, remove]
  );

  return (
    <SnippetContext.Provider value={context}>{ children }</SnippetContext.Provider>
  );
};

export { SnippetContext, useSnippets, SnippetProvider };
