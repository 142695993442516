import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CLONE_ORIENTATION_CHAPTER',
  request: (id, data={}) => request.post(`/admins/building-tasks/${id}/content-editor-contents/clone`, data).then(r => get(r,'data.data')),
};

export const {
  action,
  reducer,
  usePending: useCloneOrientationChapterPending,
} = generate.redux(creator);

export const initialState = {};
