import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { LoaderOverlay, Icon, TabSlider } from '@moved/ui';
import { useNotify, format, useUser } from '@moved/services';

import { getClient } from '../../../../common/actions';
import { useClient, useClientPending } from '../../../../common/actions/selectors';

import CSS from './styles/ClientDetails.module.scss';

export const ClientDetailsBreadcrumb = ({match, label, isActive}) => {
  const { clientId } = match.params;
  const client = useClient(clientId);
  return (<>{isActive || !client ? 'Client details' : get(client,'name')}</>)
};

const _handleReturn = (history) => {
  const from = get(history,'location.state.from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the previous screen in browser history
  else history.goBack();
};

export const ClientDetails = ({ children, match }) => {
  // HOOKS
  const { clientId }  = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  const { hasDomain } = useUser();

  // CLIENTS STATE
  const client = useClient(clientId);
  const pending = useClientPending();

  useEffect(() => {
    dispatch(getClient(clientId))
      .catch(error => Notify.error(format.error(error)));
  },[clientId, dispatch, Notify]);

  if(!client) return null;

  // Note: must match available child routes
  const tabs = [
    { label: "Settings", value: `${match.url}` },
    hasDomain('abode') && { label: "Metrics", value: `${match.url}/metrics` },
  ].filter(v=>v);

  return (
    <>
      <Helmet>
        <title>{`${client.name} Details : Moved`}</title>
      </Helmet>

      { pending && <LoaderOverlay/> }

      <div className={CSS.content}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            { hasDomain('abode') && (
              <div className={CSS.back_btn} onClick={e => _handleReturn(history)}>
                <Icon symbol='Arrow-left' library='navigation' size='32px' />
              </div>
            )}
            <h1>{ get(client,'name') }</h1>
          </div>
        </div>

        { tabs.length > 1 && (
          <TabSlider
            list={tabs}
            active={history.location.pathname}
            callback={view => history.push(view)}
          />
        )}

        <div className={CSS.details_section}>
          { children }
        </div>

      </div>
    </>
  );
};
