import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_CALENDAR_PENDING_REQUESTS',
  request: (building_id) =>
    request.get(`/partners/buildings/${building_id}/pending-calendar-requests`).then(r => r.data.data.building_calendar_requests),
  selector: (state, building_id) => {
    if(!building_id) return [];
    return (get(state,`calendarPendingRequests[${building_id}]`) || []);
  },
  reducers: {
    success: (state, { response, params: [building_id] }) => {
      return {
        ...state,
        calendarPendingRequests: {
          ...state.calendarPendingRequests,
          [building_id]: response,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useCalendarPendingRequests,
  usePending: useCalendarPendingRequestsPending,
} = generate.redux(creator);

export const initialState = {
  calendarPendingRequests: {},
};
