import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm, AnimatedCheckmark } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { triggerInvite } from '../actions';
import { useInvitePending } from '../actions/selectors';

import CSS from './styles/SendInviteModal.module.scss';

export const SendInviteModal = ({ tenantEventID, partnerID, }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();
  const [submitted, setSubmitted] = useState(false);
  const pending = useInvitePending();

  // helper functions
  const handleSubmit = (data) => {
    dispatch(triggerInvite(partnerID, tenantEventID, data)).then(resp => {
      setTimeout(close, 650);
      setSubmitted(true);
    })
    .catch(error => Notify.error(format.error(error)));
  };

  return (
    <div className={CSS.invite_modal}>
      { submitted && (<div className={CSS.confirm}><AnimatedCheckmark isChecked={true} fast={true} /></div>) }
      <div className={CSS.title}>
        <h3>Add personal note</h3>
      </div>
      <div className={CSS.block_edit_form}>
        <DynamicForm
          fields={[
            {
              label: 'Message',
              type: 'text',
              name: 'message_from_admin',
              flavor: 'optional',
              required: false,
            },
          ]}
          formStyle={'underline'}
          id="block-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.buttons}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="block-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Remind
        </label>
      </div>
    </div>
  );
}
