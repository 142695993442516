import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { Icon } from '@moved/ui';
import { s3, useModal, useNotify, format, helpers } from '@moved/services';

import CSS from '../styles/UploadImageModal.module.scss';

export const PreviewStep = ({ brandable, asset, getUploadUrl, update, fileData, prev }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();
  const preview = helpers.canvas.usePreview(fileData?.final);

  const { name, description, originalKey, finalKey, allowCropping, Preview } = asset;

  const submit = () => {
    if(pending) return;
    setPending(true);

    // Determine which assets need to be saved and create a set of data
    // for how and where to save that file per assetType
    const uploads = [
      // only save an original if cropping was turned on and the original was updated
      allowCropping && !get(fileData,'original.unchanged') && {
        name: `${asset.key}-original`,
        saveKey: originalKey,
        file: fileData.original,
      },
      // always save a final for each assetType
      {
        name: asset.key,
        saveKey: finalKey,
        file: fileData.final,
      },
    ].filter(v=>v);

    const promises = uploads.map(upload => {
      return dispatch(getUploadUrl(upload.name))
        .then(({signed_request, url}) => s3.putFile(upload.file, signed_request).then(() => url))
        .then((url) => dispatch(update(brandable.id,{
          [upload.saveKey]:`${url}?${Date.now()}`,  // append a unix timestamp for cache busting
        })));
    });
    Promise.all(promises)
      .then(() => {
        Notify.default(`Successfully updated ${name}`);
        Modal.close();
      })
      .catch((error) => {
        setPending(false);
        Notify.error(format.error(error));
      });

  };

  return (
    <div className={CSS.modal_contents}>
      <h4 className={CSS.asset_name}>{ name }</h4>
      <p>{ description }</p>
      <div className={CSS.preview_wrapper}>
        <Preview brandable={brandable} newUrl={preview} />
      </div>
      <div className={CSS.actions}>
        <button
          className={classNames('btn-gray','btn--small')}
          onClick={() => prev()}
        >
          <Icon symbol='Arrow-left' library='navigation' size='18px' className='mr-10' />
          Back
        </button>
        <div className={CSS.spacer}></div>
        <button
          className={classNames('btn-primary','btn--small',{loading:pending})}
          onClick={submit}
        >
          Upload
        </button>
      </div>
    </div>
  );
};
