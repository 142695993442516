import { merge } from 'lodash';

import * as common from './common/actions/reducer';
import * as moves from './moves/actions/reducer';
import * as orders from './orders/actions/reducer';

export const reducers = [
  ...common.reducers,
  ...moves.reducers,
  ...orders.reducers,
];

export const initialState = merge(
  common.initialState,
  moves.initialState,
  orders.initialState,
);
