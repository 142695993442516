import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_ORIENTATION_CHAPTER_TEMPLATE_CATEGORIES',
  request: () => request.get(`/admins/content-editor-content-gallery-categories`).then(r => get(r,'data.data.content_editor_content_gallery_categories')),
  selector: (state) => { return get(state,`orientationChapterTemplateCategories`) || [] },
  cache: true,
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        orientationChapterTemplateCategories: response,
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useChapterTemplateCategories,
  usePending: useChapterTemplateCategoriesPending,
} = generate.redux(creator);

export const initialState = {
  orientationChapterTemplateCategories: [],
};
