import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_PET_REGISTRATION_REQUIREMENTS',
  request: (id, params={}) => request.get(`/admins/building-tasks/${id}/pet-registration-building-requirements`, { params }).then(r => get(r,'data.data.pet_registration_building_requirements')),
  selector: (state, id) => { return get(state,`petRequirements.${id}`,{}); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        petRequirements: {
          ...state.petRequirements,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: usePetRegistrationRequirements,
  usePending: usePetRegistrationRequirementsPending,
} = generate.redux(creator);

export const initialState = {
  petRequirements: {},
};
