import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';


import CSS from './Table.module.scss';

export const TableRow = ({
  columns=[],
  disabled=false,
  onClick,
  className,
  options={},
}) => {
  return (
    <div
      className={classNames(
        CSS.row,
        {
          [CSS.disabled]:disabled,
          [CSS.clickable]:!disabled && onClick
        },
        className
      )}
      onClick={() => !disabled && onClick && onClick()}
    >
      { columns.map((column={},index) => (
        <div key={column.id || index} className={classNames('labelM contentPrimary', CSS.column, column.className)}>
          { column.value }
        </div>
      ))}
      { options.showActions && (
        <div className={classNames(CSS.column,CSS.actions)}>
          { onClick && (
            <Icon symbol='Chevron-right' library='navigation' />
          )}
        </div>
      )}
    </div>
  );
};

TableRow.propTypes = {
  /** Array of table cells to use in this row */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** unique key property per column */
    name: PropTypes.string,
    /** Value to use for the contents of this column */
    value: PropTypes.node.isRequired,
    /** (optional) class name to add to the column */
    className: PropTypes.string,
  })),
  /** Flag to treat the row as visually disabled */
  disabled: PropTypes.bool,
  /** Flag whether to append an label-less action column (needed for alignment) */
  hasActions: PropTypes.bool,
  /** (optional) callback to fire when clicking on the row */
  onClick: PropTypes.func,
  /** (optional) class name to add to the row */
  className: PropTypes.string,
  /** Collection of table level options */
  options: PropTypes.shape({
    /** Flag to include an empty column for alignment of an actions column in the table */
    showActions: PropTypes.bool,
  }),
};
