import React from 'react';
import { get } from 'lodash';
import { PromoColumn, LogoBlock } from '@moved/product';

import CSS from './Preview.module.scss';

const Preview = ({ brandable, newUrl }) => {
  const logoPreview = newUrl || get(brandable,'logo_url');
  return (
    <div className={CSS.logo_preview}>
      <div style={{padding:'52px 56px'}}><LogoBlock logo={logoPreview} name={get(brandable,'name')} /></div>
      <PromoColumn logo={logoPreview} name={get(brandable,'name')} splashBG={get(brandable,'welcome_image_url')} />
    </div>
  );
};

export const logo = {
  key: 'logo',
  finalKey: 'logo_url',
  allowCropping: false,
  requirements: {
    types: { // list all acceptable file types
      'image/png': ['.png']
    },
    dimensions: {
      minWidth: 40,
      minHeight: 40,
    },
  },
  Preview: Preview,
};
