import { CXLayout } from './common/components';

import movesRoutes from './moves/routes';

const routes = [
  {
    path: '/:view(embed)/', // legacy unwrapped for iframe (no layout)
    name: 'Embed in Legacy Dashboard',
    children: [
      ...movesRoutes,
    ],
  },
  {
    path: '/:view(cx)',
    name: 'Layout',
    component: CXLayout,
    children: [
      ...movesRoutes,
    ],
  },
];

export default routes;
