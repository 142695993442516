import React from 'react';
import classNames from 'classnames';

import { Icon, ClipboardText, Tooltip } from '@moved/ui';
import { format } from '@moved/services';

import CSS from '../styles/OrderSummaryBar.module.scss';

const JobOfferStatusIcon = ({summary}) => {
  if(summary?.accepted_vendor_job_offer) return (
    <Tooltip
      className={CSS.icon}
      tooltip={(<>Confirmed: {format.date(summary?.accepted_vendor_job_offer?.responded_at,'dateTime')}</>)}
    >
      <Icon symbol='Done-circle' library='code' color='green' size='18px'/>
    </Tooltip>
  );
  else if (summary?.pending_vendor_job_offer) return (
    <Tooltip
      className={CSS.icon}
      tooltip={(<>Expires: {format.date(summary?.pending_vendor_job_offer?.expires_at,'dateTime')}</>)}
    >
      <Icon symbol='Alarm-clock' library='home' color='brown' size='18px'/>
    </Tooltip>
  );
  else return (
    <Tooltip
      className={CSS.icon}
      tooltip='Action Required'
    >
      <Icon symbol='Warning-2' library='code' color='red' size='18px'/>
    </Tooltip>
  );
};

export const OrderSummaryBar = ({summary={}}) => {
  const cancellationFee = (summary?.additional_fees ?? []).find(fee => fee.type_label === 'cancellation');
  const pricingDisplay = summary?.status === 'cancelled' ?
    {
      label: `Cancellation fee ${summary.payment_status === 'payment_issue' ? 'pending' : 'paid'}`,
      amount: cancellationFee ? format.currency(cancellationFee?.amount)  : 'No Fee',
    } : summary.final_price ? {
      label: 'Final price',
      amount: format.currency(summary.final_price),
    } : summary.rate_type === 'hourly' ? {
      label: 'Hourly rate',
      amount: format.currency(summary.rate),
    } : summary.rate_type === 'flat' ? {
      label: 'Quoted price',
      amount: format.currency(summary.estimated_price),
    } : {};
  return (
    <>
      <div className={CSS.left_align}>
        <dl className={CSS.field}>
          <dt>Order number</dt>
          <dd>{ summary.order_number ? <ClipboardText text={summary.order_number}/> : '-' }</dd>
        </dl>
        <dl className={CSS.field}>
          <dt>Service Order ID</dt>
          <dd>{ summary.id ? <ClipboardText text={`${summary.id}`}/> : '-' }</dd>
        </dl>
        <dl className={CSS.field}>
          <dt>
            Vendor { summary.accepted_vendor_job_offer ? 'Confirmed' : summary.pending_vendor_job_offer ? 'Pending' : ''}
            <JobOfferStatusIcon summary={summary}/>
          </dt>
          <dd>{ summary.vendor_name || summary.accepted_vendor_job_offer?.vendor_name || summary.pending_vendor_job_offer?.vendor_name || 'None Accepted' }</dd>
        </dl>
        { summary.earliest_arrival_time && summary.latest_arrival_time && (
          <dl className={CSS.field}>
            <dt>Arrival Window</dt>
            <dd>{ summary.earliest_arrival_time } - { summary.latest_arrival_time }</dd>
          </dl>
        )}
      </div>
      <div className={CSS.right_align}>
        <dl className={classNames(CSS.field, CSS.rate, {[CSS.red]: summary.payment_status === 'payment_issue'})}>
          <dt>{ pricingDisplay.label }</dt>
          <dd>{ pricingDisplay.amount }</dd>
        </dl>
      </div>
    </>
  );
};
