import React from 'react';

import { format } from '@moved/services';
import { FieldList } from '@moved/ui';
import { payMoveInCosts } from '@moved/product';

import { StatusHeader, DashboardLink } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const ApprovedContent = ({ request, children }) => {
  const status = {
    icon: { symbol: 'Done', color: 'green' },
    title: 'Approval Confirmation',
    description: `You have approved the payment of move-in costs for ${format.fullname(request.tenant)}.`,
  };
  const fields = [
    {
      label: 'Date approved',
      value: format.date(request.responded_at,'dateTime'),
    },
    ...payMoveInCosts.getRequestFields(request),
  ];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <FieldList list={fields} />
      <DashboardLink/>
    </>
  );
};
