import React from 'react';

import { useModal, useUser } from '@moved/services';

import { Action } from './Action';
import { UpdateOrderDetailsModal } from '../modals/UpdateOrderDetailsModal';
import { isOrderDetailsEditable } from '../../helpers';

export const UpdateOrderDetails = ({ order, ...props }) => {
  const Modal = useModal();
  const { hasAbilities } = useUser();
  // early exit
  if(!isOrderDetailsEditable(order) || !hasAbilities('UpdateServiceOrders')) return null;
  // create the action button
  const openModal = () => Modal.open(<UpdateOrderDetailsModal order={order} />);
  const icon = {
    library: 'map',
    symbol: 'Marker#1',
  };
  return <Action onClick={openModal} icon={icon} {...props}>Update Stops</Action>;
};
