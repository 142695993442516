import { Four04 } from '@moved/product';

import { Layout } from '../common';
import { withClientAdmin } from '../common/components/ClientAdmin';

import activityRoutes from './activity/routes';
import configurationRoutes from './configuration/routes';

const routes = [
  {
    path: '/admin',
    name: 'Layout',
    component: withClientAdmin(Layout),
    children: [
      ...activityRoutes,
      ...configurationRoutes,
      {
        path: '*',
        name: 'Page not found',
        component: Four04,
      }
    ],
  },
];

export default routes;
