import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import { DrawerHeader, DrawerContent, DrawerActions, Button, LoaderOverlay } from '@moved/ui';

import { getTenantEvent } from '../actions';
import { taskTypes } from '../../../configuration/tasks/types';

export const AdminTaskCompletionDrawer = ({ task, tenantEvent }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const Drawer = useDrawer();
  const [submitting, setSubmitting] = useState();

  const { CompletionForm } = taskTypes[task.task_type] || {};

  const onSuccess = async () => {
    Notify.default(`"${task.title}" task marked as complete`);
    await dispatch(getTenantEvent(tenantEvent.id))
      .catch(err => Notify.error(format.error(err)));
    Drawer.close(true);
  };

  return (
    <>
      { submitting && <LoaderOverlay /> }
      <DrawerHeader title={task.title} description='Complete this task on behalf of the tenant' />
      <DrawerContent>
        { CompletionForm ? (
          <CompletionForm
            task={task}
            tenantEvent={tenantEvent}
            submitting={submitting}
            setSubmitting={setSubmitting}
            onSuccess={onSuccess}
          />
        ) : (
          <div className='contentPrimary labelL'>This task does not yet support this feature.</div>
        )}
      </DrawerContent>
      <DrawerActions>
        <Button text='Cancel' color='secondary' onClick={() => Drawer.close()} />
        { CompletionForm && (
          <Button text='Submit' color='primary' form='artifact-form' type='submit' disabled={submitting} />
        )}
      </DrawerActions>
    </>
  );
};
