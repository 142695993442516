import React from 'react';
import classNames from 'classnames';

import CSS from './FilterPills.module.scss';

export const TogglePill = ({ active, label, onSelect }) => (
  <div className={classNames(CSS.pill, {[CSS.pill_active]: active })} onClick={() => onSelect(active?false:true)}>
    <div className={CSS.pill_inner}>
      <span>{label}</span>
    </div>
  </div>
);
