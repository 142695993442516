import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_ORIENTATION_CONTENTS',
  request: (id, data={}) => request.patch(`/admins/content-editor-contents/${id}`, data).then(r => get(r,'data.data.content_editor_content')),
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        orientationContents: {
          ...state.orientationContents,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateOrientationContentsPending,
} = generate.redux(creator);

export const initialState = {
  orientationContents: {},
};
