import { merge } from 'lodash';

import * as getClient from './getClient';
import * as getPartner from './getPartner';

export const initialState = merge(
  getClient.initialState,
  getPartner.initialState,
);

export const reducers = [
  getClient.reducer,
  getPartner.reducer,
];
