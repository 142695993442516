import { base } from '../base';

import {
  getMoverBookingFields,
  getMoverBookingServiceOrderFields,
} from '../bookMovers/getSummaryFields';

import {
  getSummaryFields,
} from './getSummaryFields';

export const movingPlan = {
  ...base,
  getMoverBookingFields,
  getMoverBookingServiceOrderFields,
  getSummaryFields,
};
