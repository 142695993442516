import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';
import { pull, uniq, clone, noop } from 'lodash';

import { request } from '@moved/services';

import { Icon } from '../../../../sondheim/components/Icon';
import CSS from './styles/FilterTypeAhead.module.scss';

const _handleResultClick = (value, isActive, active, setActive, activeList, setActiveList) => {
  let newList = clone(active);

  if(isActive) return setActive(pull(newList,value));

  newList.push(value);
  return setActive(uniq(newList));
};

export const FilterTypeAhead = ({ list, title = 'Select', searchAction, setVisible, popOverClass, active, activeItems, setActive, single }) => {
  // HOOKS
  const [ term, setTerm ] = useState('');
  const [ termList, setTermList ] = useState(list);
  const [ searchActionPending, setSearchActionPending ] = useState(false);

  // Function to grab moves
  const loadResults = (cancelToken, delay) => {
    setSearchActionPending(true);
    // If cancelToken is present, use for debounce purposes
    if(delay) return setTimeout(() =>
      searchAction({ keywords: term },cancelToken)
      .then(resp => {
        setSearchActionPending(false);
        return setTermList(resp);
      })
      .catch(noop), // if canceled request, need silent handler to avoid sentry errors
      delay
    );
    return searchAction({ keywords: term },cancelToken)
      .then(resp => {
        setSearchActionPending(false);
        return setTermList(resp);
      });
      // .catch(error => setSearchActionPending(false));
  };

  const prevKey = usePrevious(term);

  useEffect(() => {
    // Only use a timeout if keywords is what changed
    setSearchActionPending(false)
    if(term && term.length > 1){
      let delay = 500;
      if(typeof(prevKey) === 'undefined' || prevKey === term) delay = null;

      const { cancel, token } = request.CancelToken.source();

      const timeOutId = loadResults(token, delay);

      return () => cancel("Term updated, query canceled") || clearTimeout(timeOutId);
    }
  // eslint-disable-next-line
  },[term]);

  return (
    <>
      <div className={CSS.search_bar}>
        <input className={CSS.search_input} id="popSearch" type="text" placeholder="Search&hellip;" autoComplete="off" value={term} onChange={(e,input) => setTerm(e.target.value)} />
        <label className={CSS.input_icon} htmlFor="popSearch">
          <Icon symbol={'Search'} library={'general'} size={'24px'} />
        </label>
        {term.length > 0 && (
          <div className={CSS.input_clear}  onClick={e => setTerm('')}>
            <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
          </div>
        )}

        {searchActionPending && (
          <div className={CSS.results_section}>
            <div className={CSS.results_title}>
              <h6>
                Results pending&hellip;
              </h6>
            </div>
          </div>
        )}

        {(term.length > 1 && !searchActionPending) && (
          <div className={CSS.results_section}>
            <div className={CSS.results_title}>
              <h6>
                Results {termList.length}
              </h6>
              {(!single && termList.length > 0) && (
                termList.filter(item => active.indexOf(item.id) !== -1).length < termList.length ? (
                  <span className={classNames(CSS.link,'faux-link')} onClick={e => setActive(termList.map(item => item.id))}>Select all</span>
                ) : (
                  <span className={classNames(CSS.link,'faux-link')} onClick={e => setActive([])}>Deselect all</span>
                )
              )}

            </div>
            {(termList.length > 0) && (
              <ul className={CSS.results}>
                {termList.map(item => {
                  const isActive = active.indexOf(item.id) !== -1;

                  return (
                    <li className={CSS.result} key={item.id} >

                      <input
                        className={CSS.check_input}
                        checked={isActive}
                        id={`check_${item.id}`}
                        value={item.id}
                        type="checkbox"
                        onChange ={
                          single ?
                          (e => setActive([item.id])) :
                          (e => _handleResultClick(item.id, isActive, active, setActive))
                        }
                      />

                      <label htmlFor={`check_${item.id}`}>
                        <Icon className={CSS.on} symbol={'Checkbox-on'} library={'code'} size={'24px'} />
                        <Icon className={CSS.off} symbol={'Checkbox-off'} library={'code'} size={'24px'} />
                        <span>{item.name}</span>
                      </label>
                      { !single && (<span className={classNames(CSS.link,'faux-link')} onClick={e => setActive([item.id])}>Only</span>)}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>
      <div className={CSS.capsules}>
        {activeItems.map(item => (
          <div className={CSS.capsule} key={`${item.name}_${item.id}`}>
            <span>{item.name}</span>
            <Icon symbol={'Close'} library={'navigation'} size={'20px'} onClick={e => _handleResultClick(item.id, true, active, setActive)} />
          </div>
        ))}
      </div>
    </>
  );
};
