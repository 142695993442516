import Cookies from 'js-cookie';
import WebFont from 'webfontloader';

// Define Cookie Defaults
Cookies.defaults.domain = process.env.REACT_APP_COOKIE_DOMAIN;

// Load Webfont
WebFont.load({
  google: { families: ['Material+Icons']}
});
