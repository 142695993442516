import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_CALENDAR',
  request: (id, params) => request.get(`/admins/building-calendars/${id}`, { params }).then(r => get(r, 'data.data.building_calendar')),
  selector: (state, id) => { return get(state,`buildingCalendars.${id}`); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        buildingCalendars: {
          ...state.buildingCalendars,
          [id]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useBuildingCalendar,
  usePending: useBuildingCalendarPending,
} = generate.redux(creator);

export const initialState = {
  buildingCalendars: {},
};
