import adminRoutes from '../features/admin/routes';
import approvalScreenRoutes from '../features/approvalScreens/routes';
import cxRoutes from '../features/cx/routes';
import userRoutes from '../features/user/routes';
import commonRoutes from '../features/common/routes';

export const routeTree = [
  ...adminRoutes,
  ...approvalScreenRoutes,
  ...cxRoutes,
  ...userRoutes,
  ...commonRoutes // must be last
];
