import { merge } from 'lodash';
import { generate } from '@moved/services';

import * as admin from '../features/admin/reducer';
import * as approvalScreens from '../features/approvalScreens/actions/reducer';
import * as common from '../features/common/actions/reducer';
import * as cx from '../features/cx/reducer';

const initialState = merge(
  { requests: {} },
  admin.initialState,
  approvalScreens.initialState,
  common.initialState,
  cx.initialState,
);

const reducers = [
  ...admin.reducers,
  ...approvalScreens.reducers,
  ...common.reducers,
  ...cx.reducers,
];

export const reducer = generate.rootReducer(initialState, reducers);
