import React from 'react';
import classNames from 'classnames';

import { Toggle, Radio } from '@moved/ui';

import CSS from './styles/PolicyCoverage.module.scss';

export const PolicyCoverage = ({ tenants, tenantEvent, data, updateData }) => {
  const { covered_tenants } = data;

  const isTenantCovered = ({ id }) => !!covered_tenants.find(ct => ct.tenant_id === id);
  const isTenantPrimary = ({ id }) => !!covered_tenants.find(ct => ct.tenant_id === id && ct.is_primary_policyholder);

  const changePrimary = (newPrimaryTenant) => {
    updateData({
      covered_tenants: tenants
        .filter(tenant => isTenantCovered(tenant) || tenant === newPrimaryTenant)
        .map(({ id }) => ({
          tenant_id: id,
          is_primary_policyholder: id === newPrimaryTenant.id,
        })),
    });
  };

  const changeCovered = (newCoveredTenant) => {
    updateData({
      covered_tenants: tenants
        .filter(tenant => newCoveredTenant === tenant ? !isTenantCovered(tenant) : isTenantCovered(tenant))
        .map(({ id }) => ({
          tenant_id: id,
          is_primary_policyholder: isTenantPrimary({id}),
        })),
    });
  };

  return (
    <div className={CSS.tenant_list}>
      <div className='labelL contentPrimary marginTop-48 marginBottom-32'>
        Covered tenants
      </div>
      <div className={classNames('contentSecondary paddingTop-0',CSS.tenant_row)}>
        <div className={CSS.field}>
          Resident
        </div>
        <div className={CSS.field}>
          Covered by this plan
        </div>
        <div className={CSS.field}>
          Primary policy holder
        </div>
      </div>
      { tenants.map(tenant => (
        <div key={tenant.id} className={CSS.tenant_row}>
          <div className={CSS.field}>
            {tenant.firstname} {tenant.lastname}
          </div>
          <div className={CSS.field}>
            <Toggle
              name={`tenants_${tenant.id}.is_covered`}
              disabled={tenant.id === tenantEvent.tenant.id || isTenantPrimary(tenant)}
              value={isTenantCovered(tenant)}
              isControlled={true}
              onChange={() => changeCovered(tenant)}
            />
          </div>
          <div className={CSS.field}>
            <Radio
              name={'primary'}
              value={String(tenant.id)}
              selected={isTenantPrimary(tenant)}
              onChange={() => changePrimary(tenant)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
