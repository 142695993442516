import React from 'react';

import { useAnalyticsQuery, format } from '@moved/services';

import { Statistic } from './Statistic';

export const TotalLeases = ({ client, dateRange }) => {

  const { resultSet, isLoading, error } = useAnalyticsQuery({
    measures: [
      'Leases.count'
    ],
    timeDimensions: [
      {
        dimension: 'Leases.startDate',
        dateRange
      }
    ],
    filters: [
      {
        member: 'Leases.partnerId',
        operator: 'equals',
        values: [client.id],
      }
    ]
  });

  let displayValue;
  if(error) displayValue = error.toString();
  else if(resultSet) {
    const [{ 'Leases.count': totalLeases }] = resultSet.tablePivot();
    displayValue = format.number(totalLeases);
  }

  return <Statistic label='Total Leases' value={!isLoading && displayValue} description={(<>that's a lot of <span className="primary bold">lives improved</span></>)}/>;

};
