import { merge } from 'lodash';

import * as getPetRegistration from './getPetRegistration';
import * as reviewPetRegistration from './reviewPetRegistration';

export const initialState = merge(
  getPetRegistration.initialState,
  reviewPetRegistration.initialState,
);

export const reducers = [
  getPetRegistration.reducer,
  reviewPetRegistration.reducer,
];
