import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_KEY_PICKUP_APPOINTMENT_REQUEST',
  request: (token, action, data) => request.post(`/partners/key-pickup-appointment-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.key_pickup_appointment_request')),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        keyPickupAppointmentRequests: {
          ...state.keyPickupAppointmentRequests,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReviewKeyPickupAppointmentRequestPending,
} = generate.redux(creator);

export const initialState = {};
