import React from 'react';

import { CollapsibleSidebar } from './CollapsibleSidebar';

import CSS from './styles/CXLayout.module.scss';

export const CXLayout = ({ logos, navItems=[], children }) => {
  return (
    <div className={CSS.template}>
      <CollapsibleSidebar
        className={CSS.sidebar}
        navItems={navItems}
        logos={logos}
      />
      <section className={CSS.content}>
        {children}
      </section>
    </div>
  );
};
