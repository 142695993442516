// List components here for cross feature use
export const orientationFields = [
  {
    label: 'Title',
    type: 'text',
    name: 'title',
    required: 'required',
  },
  {
    label: 'Subtitle',
    type: 'text',
    name: 'subtitle',
    required: false,
  },
  {
    label: 'Side navigation title',
    type: 'text',
    name: 'label',
    required: 'required',
  },
  {
    label: 'URL slug',
    type: 'text',
    name: 'slug',
    required: 'required',
  },
  {
    label: 'Primary button text',
    type: 'text',
    name: 'cta_text',
    required: 'required',
  },
];
