import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_ADMINS_TRIGGER_INVITE',
  request: (id, params={}) => request.post(`/admins/admins/${id}/invites`, params).then(r => r.data),
};

export const {
  action,
  reducer,
  usePending: useTriggerInvitePending,
} = generate.redux(creator);

export const initialState = {};
