import React from 'react';

import { FieldDisplay } from '../../../common';

import CSS from '../../styles/SectionDisplay.module.scss';

export const SectionDisplay = ({ section = {} }) => {

  const { title, actions=[], columns=[], rows=[], } = section;

  return (
    <div className={CSS.section}>
      <div className={CSS.title_bar}>
        <h3>{title}</h3>
        { actions.length > 0 && (
          <div className={CSS.actions}>
            { actions }
          </div>
        )}
      </div>
      { columns.length > 0 && (
        <div className={CSS.columns}>
          { columns.map((column,index) => (
            <div className={CSS.column} key={`column-${index}`}>
              {column.map((field,index) => (<FieldDisplay key={`${field.label}-${index}`} field={field} />))}
            </div>
          ))}
        </div>
      )}
      { rows.length > 0 && (
        <div className={CSS.rows}>
          { rows }
        </div>
      )}
    </div>
  );
};
