import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_APPROVALS_REQUEST_ACTION',
  request: (type, id, action, data={}) => request.post(`/partners/approvals/${type}/${id}/${action}`, data).then(r => get(r,'data.data.request')),
  reducers: {
    success: (state, { response: request }) => {
      const updatedResultsSet = get(state,'approvalSearch.activeSet',[]).map(item => (item.id === request.id) ? request : item);

      return {
        ...state,
        approvalSearch: {
          ...state.approvalSearch,
          activeSet: updatedResultsSet,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  usePending: useRequestActionPending,
} = generate.redux(creator);

export const initialState = {};
