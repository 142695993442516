import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_BRANDING_GET_ASSET_UPLOAD_URL',
  request: (entity,id,assetType) => request.post(`/admins/${entity}/${id}/presigned-s3-urls/${assetType}`).then(r => get(r,'data.data.presigned_s3_url')),
};

export const {
  action,
  reducer,
  usePending: useAssetUploadUrlPending,
} = generate.redux(creator);
