import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get} from 'lodash';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';
import { Four04 } from '@moved/product';

import { TenantDetails } from './TenantDetails';
import { UnclaimedTenantEvent } from './UnclaimedTenantEvent';
import { LeaseDetails } from '../../../../common/components/LeaseDetails';
import { TaskList } from './TaskList';

import { getTenantEvent } from '../actions';
import { useTenantEvent } from '../actions/selectors';

import CSS from './styles/TenantEventDetails.module.scss';

export const TenantEventDetails = () => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { id } = useParams();

  const tenantEvent = useTenantEvent(id);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getTenantEvent(id))
      .catch(err => Notify.error(format.error(err)))
      .finally(() => setLoading(false));
  },[id, Notify, dispatch]);

  if(!tenantEvent) return (
    <>
      <Helmet>
        <title>{`Moves`}</title>
      </Helmet>
      { loading ? (<LoaderOverlay />) : (<Four04 />) }
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${get(tenantEvent,'tenant.firstname')} ${get(tenantEvent,'tenant.lastname')} Admin Abode Moves : Moved`}</title>
      </Helmet>

      { loading && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <TenantDetails tenantEvent={tenantEvent} />

        <LeaseDetails lease={tenantEvent.lease} tenant={tenantEvent.tenant} building={tenantEvent.building} stepType={get(tenantEvent,'move_step_type.label')} />

        { !tenantEvent.move_step_id && (
          <UnclaimedTenantEvent tenantEvent={tenantEvent} />
        )}

        { get(tenantEvent,'move_tasks',[]).length > 0 && (
          <TaskList tenantEvent={tenantEvent} />
        )}

      </div>
    </>
  );
};
