import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, remove, cloneDeep } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { PropertyAddress } from './AssignedProperty';
import { assignAdminProperty } from '../actions';
import { useCreateAdminPending } from '../actions/selectors';
import { getPropertiesSearch } from '../../properties/actions';
import { usePropertiesSearch, usePropertiesSearchPending } from '../../properties/actions/selectors';

import CSS from './styles/AssignPropertiesModal.module.scss';

export const AssignPropertiesModal = ({ admin }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();
  const pending = useCreateAdminPending();
  const { activeSet: buildings } = usePropertiesSearch();
  const buildingsPending = usePropertiesSearchPending();

  const handleSubmit = (data) => {
    dispatch(assignAdminProperty(admin.id,data))
      .then(resp => {
        close(false);
      })
      .catch(error => Notify.error(format.error(error)));
  };

  useEffect(() => {
    dispatch(getPropertiesSearch({
      partner_ids: get(admin,'partner.id'),
      limit: 500,
    }));
  // eslint-disable-next-line
  },[]);

  const filteredBuildings = cloneDeep(buildings);
  remove(filteredBuildings, building => {
    for (const adminBuilding of admin.building_admins) {
      if(building.id === get(adminBuilding,'building.id')) {
        return true;
      }
    }

  })

  const slotFields = [
    {
      type: 'itemList',
      name: 'building_ids',
      placeholder: 'Select at least one property...',
      isMulti: true,
      customItemClass: CSS.item,
      searchable: true,
      options: filteredBuildings.map(building => {
        return {
          label: (
            <div className={CSS.label}>
              <div
                className={CSS.splash}
                style={{
                  ...{backgroundImage: building.dashboard_image_url ?
                    `url('${building.dashboard_image_url}')`
                    : null,
                  }
                }}
              />
              <div className={CSS.grow}>
                <div>{building.name}</div>
                <div className={CSS.address}>
                  <PropertyAddress address={building} />
                </div>
              </div>
            </div>
          ),
          value: building.id,
          term: building.name,
        };
      }),
    },
  ];

  if(buildingsPending) return (
    <div className={CSS.assignment}>
      <div className={CSS.title}>
        <h3>Assign property</h3>
      </div>
      <AtomSpinner />
    </div>
  );

  const name = get(admin,'partner.name');

  return (
    <div className={CSS.assignment}>
      <div className={CSS.title}>
        <h3>Assign {name ? `${name} ` : ''} properties</h3>
      </div>
      <div className={CSS.form}>
        {filteredBuildings.length > 0 ? (
          <DynamicForm
            fields={slotFields}
            formStyle={'underline'}
            id="assign-property-form"
            onSubmit={handleSubmit}
          />
        ) : (
          <div className={CSS.no_results}>
            <p>Sorry, there are no additional {name} properties to assign to this admin.</p>
          </div>
        )}

      </div>
      <div className={CSS.controls}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        {filteredBuildings.length > 0 && (
          <label
            htmlFor="assign-property-form-submit"
            tabIndex="0"
            role="button"
            className={'btn-primary ' + (pending  ? ' loading' : '')}
            disabled={pending}
          >
            Assign
          </label>
        )}
      </div>
    </div>
  );
}
