import React, { useState } from 'react';
import classNames from 'classnames';

import { UploadStep } from './UploadStep';
import { CropStep } from './CropStep';
import { PreviewStep } from './PreviewStep';

import CSS from '../styles/UploadImageModal.module.scss';

export const UploadImageModal = ({ brandable, asset, update, getUploadUrl, initialData = {} }) => {

  // STATE
  const [progress, setProgress] = useState(1);
  const [fileData, setFileData] = useState(initialData);

  const { allowCropping } = asset;

  const flow = [
    !initialData.original && {
      title: 'Upload',
      component: UploadStep,
    },
    allowCropping && {
      title: 'Crop',
      component: CropStep,
    },
    {
      title: 'Preview',
      component: PreviewStep,
    },
  ].filter(v=>v);

  const currentStep = flow[progress-1];
  const FlowComponent = currentStep.component;

  const next = (data) => {
    setFileData(data);
    setProgress(progress+1);
  }
  const prev = () => setProgress(current => current - 1);

  return (
    <>
      <h3 className={CSS.title}>
        <span>{progress}/{flow.length}</span>{' '}{ currentStep.title }
      </h3>
      <div className={classNames(CSS.content, CSS[asset.key])}>
        <FlowComponent
          brandable={brandable}
          asset={asset}
          update={update}
          getUploadUrl={getUploadUrl}
          fileData={fileData}
          next={progress < flow.length && next}
          prev={progress > 1 && prev}
        />
      </div>
    </>
  );
};
