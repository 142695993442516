import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONFIG_CREATE_DEMO_TENANTS',
  request: (id, data={}) => request.post(`/admins/buildings/${id}/demo-accounts`, data).then(r => get(r,'data.data.demo_tenants')),
};

export const {
  action,
  reducer,
  usePending: useCreateDemoTenantsPending,
} = generate.redux(creator);

export const initialState = {};
