import { base } from '../base';

import {
  getMoverBookingFields,
  getMoverBookingServiceOrderFields,
  getSummaryFields,
} from './getSummaryFields';

export const bookMovers = {
  ...base,
  getMoverBookingFields,
  getMoverBookingServiceOrderFields,
  getSummaryFields,
};
