import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_ORIENTATION_CONTENTS',
  request: (id, params={}) => request.get(`/admins/building-tasks/${id}/content-editor-contents`, { params }).then(r => get(r,'data.data.content_editor_contents')),
  selector: (state, id) => { return get(state,`orientationContents.${id}`); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        orientationContents: {
          ...state.orientationContents,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useOrientationContents,
  usePending: useOrientationContentsPending,
} = generate.redux(creator);

export const initialState = {
  orientationContents: {},
};
