import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, LoaderOverlay, Icon } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { getPetRegistrationRequirements, updatePetRegistrationRequirements } from '../actions';
import { usePetRegistrationRequirements, usePetRegistrationRequirementsPending, usePetRegistrationRequirementsUpdatePending } from '../actions/selectors';
import CSS from './styles/PetRegistrationRequirements.module.scss';

const _handleReturn = (state, history, property_id, task_id) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else go to the task details general tab
  else history.push(`/admin/properties/${property_id}/tasks/${task_id}`);
};


const MandatoryField = ({ label, icon }) => (
  <div className={CSS.mandatory_bar}>
    <div className={CSS.label}>
      <Icon {...icon} />
      <span>{label}</span>
    </div>
  </div>
);

export const PetRegistrationRequirements = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { task_id, property_id } = useParams();
  const Notify = useNotify();
  const location = useLocation();
  const history = useHistory();

  // REDUX
  const req = usePetRegistrationRequirements(task_id);
  const loading  = usePetRegistrationRequirementsPending();
  const pending = usePetRegistrationRequirementsUpdatePending();

  useEffect(() => {
    dispatch(getPetRegistrationRequirements(task_id))
      .catch(error => Notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);

  let fields = [
    {
      label: 'Customize input fields',
      type: 'title',
    },
    {
      type: 'content',
      name: 'ui.optional',
      content: (
        <h4 className={CSS.subtitle}>Optional</h4>
      ),
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Scissors' library='general' />
          <span>Spayed / neutered</span>
        </div>
      ),
      type: 'toggle',
      name: 'neutered_status_enabled',
      value: req.neutered_status_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Uploaded-file' library='files' />
          <span>Upload vaccination files</span>
        </div>
      ),
      type: 'toggle',
      name: 'vaccine_records_enabled',
      value: req.vaccine_records_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Date' library='code' />
          <span>Spayed / neutered date</span>
        </div>
      ),
      type: 'toggle',
      name: 'neutered_date_enabled',
      value: req.neutered_date_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Pictures#1' library='files' />
          <span>Upload pet photo</span>
        </div>
      ),
      type: 'toggle',
      name: 'photo_enabled',
      value: req.photo_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Price#1' library='shopping' />
          <span>Pet License Number</span>
        </div>
      ),
      type: 'toggle',
      name: 'license_number_enabled',
      value: req.license_number_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Mail-heart' library='communication' />
          <span>Service Animal Documentation</span>
        </div>
      ),
      type: 'toggle',
      name: 'service_animal_documentation_enabled',
      value: req.service_animal_documentation_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
    {
      label: (
        <div className={CSS.toggle_label}>
          <Icon symbol='Uploaded-file' library='files' />
          <span>Pet License Documentation</span>
        </div>
      ),
      type: 'toggle',
      name: 'license_documentation_enabled',
      value: req.license_documentation_enabled,
      className: CSS.toggle_bar,
      half: true,
    },
  ];

  const handleSubmit = (data) => {
    dispatch(updatePetRegistrationRequirements(task_id, data))
      .then(() => Notify.default(`Registration requirements updated!`))
      .catch(error => Notify.error(format.error(error)));
  };

  if(loading) return (<LoaderOverlay />);

  return (
    <>
      <div className={CSS.content_top}>
        <div className={CSS.form}>
          <DynamicForm
            id='task-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={CSS.mandatory}>
          <h4 className={classNames(CSS.subtitle,'mb-20')}>Mandatory</h4>
          <div className={CSS.mandatory_fields}>
            {[
              {
                label: 'Name',
                icon: { symbol: "Edit", library: "design" },
              },
              {
                label: 'Owner',
                icon: { symbol: "User", library: "general" },
              },
              {
                label: 'Type of animal',
                icon: { symbol: "Paw", library: "animals" },
              },
              {
                label: 'Is this a service animal',
                icon: { symbol: "Paw", library: "animals" },
              },
              {
                label: 'Age',
                icon: { symbol: "Layout-grid", library: "layout" },
              },
              {
                label: 'Color',
                icon: { symbol: "Color-profile", library: "design" },
              },
              {
                label: 'Breed',
                icon: { symbol: "Dog", library: "animals" },
              },
              {
                label: 'Gender',
                icon: { symbol: "Settings#3", library: "general" },
              },
              {
                label: 'Weight',
                icon: { symbol: "Weight#1", library: "home" },
              },
            ].map(field => (<MandatoryField {...field} key={field.label} />))}
          </div>
        </div>
      </div>
      <div className={CSS.content_bottom}>
          <div className={CSS.buttons}>
            <label
              id='task_form_button'
              htmlFor="task-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', CSS.save, {'loading': pending})}
              disabled={pending}
            >
              Save
            </label>

            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-ghost')}
              disabled={pending}
              onClick={e => {
                e.preventDefault();
                if (pending) return;
                return _handleReturn(location.state, history, property_id, task_id);
              }}
            >
              Discard changes
            </label>
          </div>
      </div>
    </>
  );
};
