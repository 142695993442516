import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Table, Icon } from '@moved/ui';

export const AppointmentList = ({ appointments }) => (
  <Table
    columns={[
      {
        name: 'tenant',
        getValue: (row) => (
          <div className='flex gap-8 items-center'>
            <Icon symbol='User' library='general' className='marginRight-8'/>
            <span>{ format.fullname(get(row,'tenant',{})) }</span>
          </div>
        ),
      },
      {
        name: 'appointment',
        getValue: (row) => (`${format.date(row?.appointment?.pickup_date,'date')} - ${(row?.appointment?.pickup_time ?? '').toLowerCase() || 'All day'}`),
      },
    ]}
    rows={appointments.map(appointment => ({ data: appointment }))}
  />
);
