import React, { useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { format } from '@moved/services';

import buttonCSS from '../../../../../sondheim/components/controls/Button/Button.module.scss';
import CSS from './Button.module.scss';

export const ButtonEditor = ({ button }) => {
  const [showURL, setShowURL] = useState();
  const [URL, setURL] = useState(get(button,'data.url',''));
  const activeColor = get(button,'data.color');
  const customColor = get(button,'data.customColor');

  const customColorStyles = activeColor === 'custom' && customColor ? {
    backgroundColor: customColor,
  } : {};

  const buttonClasses = classNames(
    buttonCSS[`default${format.capitalize(button.getBaseColor())}`],
    activeColor === 'custom' && buttonCSS.customColor,
    CSS.button,
  );

  return (
    <>
      <div
        className={buttonClasses}
        style={customColorStyles}
        onClick={() => setShowURL(!showURL)}
        contentEditable={!button.readOnly}
        suppressContentEditableWarning={true}
        tabIndex={0}
        data-placeholder={get(button,'placeholders.text')}
        dangerouslySetInnerHTML={{__html:get(button,'data.text','')}}
      />
      <input type='hidden' name='urlValue' value={URL}/>
      { showURL && (
        <div className={CSS.url_wrapper}>
          <div className={CSS.label}>URL:</div>
          <input
            className={CSS.url_input}
            tabIndex={0}
            placeholder={get(button,'placeholders.url')}
            onChange={event => setURL(event.target.value)}
            value={URL}
          />
        </div>
      )}
    </>
  );
};
