import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { useUser, format } from '@moved/services';
import { Tooltip, ClipboardText, ClipboardIcon } from '@moved/ui';
import { ProxyModeIcon } from '@moved/product';

import CSS from '../styles/MoveDetails.module.scss';

import { MoveDetailsStatus } from './MoveDetailsStatus';
import { MoveDetailsNotes } from './MoveDetailsNotes';
import { MoveDetailsPreBooking } from './MoveDetailsPreBooking';
import { OrderList } from '../../orders';

export const MoveDetails = ({ move, view }) => {
  // HOOKS
  const { proxyAs, Can } = useUser();

  return (
    <>

      <div className={classNames(CSS.section,CSS.header)}>

        <div className={CSS.title}>
          <h1>{get(move,'user.firstname')} {get(move,'user.lastname')}</h1>

          <div className={CSS.subtitle}>
            <h3>
              <ClipboardText text={`${get(move,'user.email')}`} className={CSS.clipboard}/>
            </h3>

            <div className={CSS.id_row}>
              <div className={CSS.id_item}>
                <span className={CSS.label}>User ID</span>
                <ClipboardText text={`${get(move,'user.id')}`} className={CSS.clipboard}/>
              </div>
              <div className={CSS.id_item}>
                <span className={CSS.label}>Move ID</span>
                <ClipboardText text={`${get(move,'id')}`} className={CSS.clipboard}/>
              </div>
            </div>
          </div>
        </div>

        <div className={CSS.activities}>
          <div className={CSS.actions}>
            <Can I={'ProxyAsUsers'}>
              <div className={CSS.action_item}>
                <Tooltip
                  placement={"bottom"}
                  tooltip={<>Proxy as {get(move,'user.firstname')}</>}
                >
                  <div className={classNames(CSS.oval, CSS.action)} onClick={e => proxyAs(get(move,'user.id'))}>
                    <ProxyModeIcon style={{height:'24px',width:'24px'}}/>
                  </div>
                </Tooltip>
              </div>
            </Can>
            <div className={CSS.action_item}>
              <Tooltip
                placement={"bottom"}
                tooltip={<>Copy link</>}
              >
                <ClipboardIcon className={classNames(CSS.action,CSS.oval)}
                  text={ view === 'embed' ?
                    `${process.env.REACT_APP_MOVED_APP_URL}/?search=${encodeURIComponent(get(move,'user.email'))}&active=${move.id}` :
                    `${process.env.REACT_APP_BASE_URL}/cx/moves/${move.id}?direct=${encodeURIComponent(get(move,'user.email'))}`
                  }/>
              </Tooltip>
            </div>
            <div className={CSS.action_item}>
              <div className={CSS.oval}>
                <span>{format.initials(get(move,'user'))}</span>
              </div>
            </div>
          </div>
          <MoveDetailsStatus move={move}/>
        </div>

      </div>

      <MoveDetailsNotes move={move} />

      <OrderList move={move} />

      <MoveDetailsPreBooking move={move} />

    </>
  );
};
