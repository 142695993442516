import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, noop } from 'lodash';
import classNames from 'classnames';

import { useNotify, s3, format, useUser } from '@moved/services';
import { DynamicForm, AtomSpinner, Uploader } from '@moved/ui';

import {
  getUtilitySetup,
  getUtilityProviders,
  createLeaseUtilityAccount,
  getS3UploadUrl,
} from '../actions';
import { useUtilitySetupDetails, useUtilityProviders } from '../actions/selectors';

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { user } = useUser();
  // state management
  const [pending, setPending] = useState(true);
  // file upload management
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState();

  // configuration data
  const {
    account_number_required,
    confirmation_number_required,
    upload_required,
  } = useUtilitySetupDetails(task.building_task_id) || {};
  const providers = useUtilityProviders(task.building_task_id) || [];

  // trigger loading of configuration data
  useEffect(() => {
    Promise.all([
      dispatch(getUtilitySetup(task.building_task_id)),
      dispatch(getUtilityProviders(task.building_task_id)),
    ])
      .then(() => setPending(false))
      .catch(err => Notify.error(format.error(err)));
  },[task,dispatch,Notify]);

  // submission handler
  const submit = async (data) => {
    // don't allow multiclicking
    if(submitting) return;
    // validate the file upload
    if(upload_required && !file) return setFileError('Proof of account setup is required')

    setSubmitting(true);

    if(upload_required) {
      // upload the image to s3 and get the url
      const url = await dispatch(getS3UploadUrl(get(tenantEvent,'building.id'),{
        filename: s3.getUniqueFilename(file.name, { id: get(tenantEvent, 'user_id') || user.id }),
        http_content_type: file.type,
      }))
        .then(({ signed_request, url }) => s3.putFile(file,signed_request).then(() => url))
        .catch(error => { Notify.error(format.error(error)) });

      if(!url) return;
      data = { ...data, file_url: url };
    }

    dispatch(createLeaseUtilityAccount(tenantEvent.id, task.building_task_id, data))
      .then(resp => {
        onSuccess(resp); // notify the parent of success
      })
      .catch(err => {
        setSubmitting(false); // notify the parent of error
        Notify.error(format.error(err));
      });

  };

  const handleFileSelection = (files) => {
    setFileError(); // remove any requirement errors
    setFile(files[0]); // set the file in state
  };

  const fields = [
    {
      name: 'utility_provider_id',
      label: 'Provider',
      type: 'select',
      options: providers.map(provider => ({
        label: provider.name,
        value: provider.id,
      })),
      value: providers.length === 1 && providers[0].id,
      readOnly: providers.length === 1,
      required: 'You must select a provider',
    },
    account_number_required && {
      name: 'account_number',
      label: 'Account number',
      type: 'text',
      required: 'You must provide an account number',
    },
    confirmation_number_required && {
      name: 'confirmation_number',
      label: 'Confirmation number',
      type: 'text',
      required: 'You must provide a confirmation number',
    },
  ].filter(v=>v);

  // can't initialize the form until the fields are determined
  if(pending) return <AtomSpinner/>;

  return (
    <>
      <DynamicForm
        id="artifact-form"
        fields={fields}
        onSubmit={submit}
        formStyle='underline'
      />
      { upload_required && (<>
        <div className={classNames(
          'labelS marginBottom-8 marginTop-16',
          fileError ? 'contentError' : 'contentSecondary'
        )}>
          Proof of setup
        </div>
        <Uploader
          id='utility_file_url'
          dzOptions={{
            accept: {
              'image/jpg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'application/pdf': ['.pdf'],
            },
            multiple: false,
            maxFiles: 1,
          }}
          initialFiles={[]}
          onSelect={handleFileSelection}
          icon={{size:'30px'}}
          error={fileError}
        />
        { fileError && (
          <div className='labelS contentError marginTop-4'>{ fileError }</div>
        )}
      </>)}
    </>
  );
};
