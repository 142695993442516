import React from 'react';

import CSS from '../styles/shared.module.scss';

export const RenderAttributes = ({attributes}) => attributes
  .filter(attribute => attribute.value)
  .map(attribute => (
    <div className={CSS.field_wrapper} key={attribute.label}>
      <span className={CSS.field_label}>{attribute.label}</span>
      <span className={CSS.field_value}>{attribute.value}</span>
    </div>
  ));
