import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_UTILITY_SETUP_REQUEST',
  request: (token, action, data) => request.post(`/partners/utility-setup-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.utility_setup_request')),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        utilityRequests: {
          ...state.utilityRequests,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReviewUtilitySetupRequestPending,
} = generate.redux(creator);

export const initialState = {};
