import React from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';

import { useMoveDetails } from '../actions/selectors';

export const MoveStepRedirect = () => {
  // HOOKS
  const { moveId } = useParams();
  const location = useLocation();
  const { move_steps: moveSteps=[] } = useMoveDetails(moveId);
  const stepTypeOrder = ['move-out','move-in'];
  const stepSort = (a,b) => stepTypeOrder.indexOf(a.type) - stepTypeOrder.indexOf(b.type);
  const activeMoveStep = moveSteps.sort(stepSort)[0];

  return activeMoveStep ? <Redirect to={{
    pathname: `${location.pathname}/steps/${activeMoveStep.id}`,
    search: location.search,
  }}/> : null;
};
