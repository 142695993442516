import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { TabSlider } from '@moved/ui';

import CSS from './styles/TaskNavigation.module.scss';

export const TaskNavigation = ({screens}) => {
  const history = useHistory();
  const { property_id, task_id } = useParams();
  const basePath = `/admin/properties/${property_id}/tasks/${task_id}`;

  // allow screens to be defined relative to the task basePath for convenience and DRYness
  const tabs  = screens.map(screen => ({
    ...screen,
    value: `${basePath}${screen.value}`,
  }))

  // hide navigation if not more than one screen
  if(screens && screens.length <= 1) return null;

  return (
    <TabSlider
      className={CSS.navbar}
      list={tabs}
      active={history.location.pathname}
      callback={view => history.push(view)}
    />
  );
}
