import React from 'react';

import { ProxyModeBadge, RouteTree } from '@moved/product';

import { routeTree } from '../routes';
import { Snow } from './Snow';

import CSS from '../styles/App.module.scss';

export const App = () => (
  <div className={CSS.admin_app}>
    <RouteTree routeTree={routeTree} defaultDomains={['cx','abode','client']} />
    <ProxyModeBadge/>
    <Snow />
  </div>
);
