import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { MoveStepTypeIcon } from '@moved/product';

import CSS from '../styles/NotificationsMenu.module.scss';

export const RequestInfo = ({request, calendarMap}) => (
  <div className={CSS.request_info}>
    <div className={CSS.tenant_info}>
      <h4>{`${request.firstname} ${request.lastname}`}</h4>
      <span>{format.date(request.start_time,'MMM D, YYYY [at] h:mm')}{format.date(request.end_time,' [-] h:mma')}</span>
    </div>
    <div className={CSS.move_info}>
      <div className={CSS.move_type}>
        <MoveStepTypeIcon type={get(request,'move_step_type.label')} size='24px' />
        <span>{request.unit}</span>
      </div>
      <span>{calendarMap[request.building_calendar_id]}</span>
    </div>
  </div>
);
