import React from 'react';
import classNames from 'classnames';

import { useUser } from '@moved/services';
import { Icon, PopOver } from '@moved/ui';

import CSS from './styles/ProfileLink.module.scss';

const UserPopOverActions = ({ signOut, }) => {
  return (
    <div id={'popover_sign_out'} className={CSS.popover_action} onClick={signOut}>
      <Icon symbol={'Sign-out'} size={'20px'} className={CSS.popover_icon} />
      <span className={CSS.popover_link}>Sign out</span>
    </div>
  );
}

export const ProfileLink = ({ signOut, }) => {
  const { user } = useUser();

  return (
    <div className={CSS.wrapper}>
      <PopOver
        id={'user_profile'}
        customClass={CSS.user_popover}
        className={CSS.trigger}
        hideArrow="true"
        placement="bottom"
        trigger="click"
        tooltip={<UserPopOverActions signOut={signOut} />}
      >
        {(tooltipShown) => (
          <div className={classNames(CSS.profile_link,[{[CSS.profile_active]: tooltipShown}])}>
            <div className={CSS.content}>
              <span className={CSS.name}>{ user.fullname }</span>
              <span className={CSS.label}>Admin</span>
              <span className={CSS.arrow}>
                <Icon className={CSS.icon} symbol='Chevron-down' library='navigation' alt='Profile' role='img'/>
              </span>
            </div>
        </div>
        )}
      </PopOver>
    </div>
  );
};
