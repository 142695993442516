import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_CREATE_RENTERS_INSURANCE_OVERRIDE',
  request: (tenantEventId, buildingTaskId, data) => request.post(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/renters-insurance-task-overrides`,data)
    .then(r => get(r,'data.data.renters_insurance_task_override')),
  reducers: {
    success: (state, { response: rentersInsuranceOverride, params: [tenantEventId] }) => ({
      ...state,
      tenantEvents: {
        ...state.tenantEvents,
        [tenantEventId]: {
          ...state.tenantEvents[tenantEventId],
          renters_insurance_task_overrides: [
            ...(state.tenantEvents[tenantEventId].renters_insurance_task_overrides || []),
            rentersInsuranceOverride,
          ],
        },
      },
    })
  }
};

export const {
  action,
  reducer,
  usePending: useCreateInsuranceOverridePending,
} = generate.redux(creator);

export const initialState = {};
