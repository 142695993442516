import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_PAY_MOVE_IN_COSTS_REQUEST',
  request: (token) => request.get(`/partners/pay-move-in-costs-requests/token/${token}`).then(r => r.data.data.pay_move_in_costs_request),
  selector: (state, token) => get(state, `payMoveInCosts.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => ({
      ...state,
      payMoveInCosts: {
        ...state.petRegistrations,
        [token]: request,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: usePayMoveInCosts,
  usePending: usePayMoveInCostsPending,
} = generate.redux(creator);

export const initialState = {
  payMoveInCosts: {},
};
