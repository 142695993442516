import { merge } from 'lodash';

import * as admins from './admins/actions/reducer';
import * as clients from './clients/actions/reducer';
import * as properties from './properties/actions/reducer';
import * as tasks from './tasks/redux';

export const reducers = [
  ...admins.reducers,
  ...clients.reducers,
  ...properties.reducers,
  ...tasks.reducers,
];

export const initialState = merge(
  admins.initialState,
  clients.initialState,
  properties.initialState,
  tasks.initialState,
);
