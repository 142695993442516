import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { DynamicForm, LoaderOverlay, Button } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../common';
import {
  getPayMoveInCostsInstructions as getInstructions,
  createPayMoveInCostsInstructions as createInstructions,
  updatePayMoveInCostsInstructions  as updateInstructions,
} from '../actions';
import {
  usePayMoveInCostsInstructions,
  useUpdatePayMoveInCostsInstructionsPending,
} from '../actions/selectors';

import CSS from '../../../components/styles/DefaultConfiguration.module.scss';

const defaults = {
  title: 'How to pay your move-in costs',
  subtitle: 'Please ensure that you complete the payment instructions before moving in.',
  cta_text: 'I have paid',
};

export const PaymentInstructions = ({ task }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  const instructions = usePayMoveInCostsInstructions(task.id);
  const [initialized, setInitialized] = useState(false);
  const pending = useUpdatePayMoveInCostsInstructionsPending();

  useEffect(() => {
    dispatch(getInstructions(task.id))
      .catch(error => {
        // handle known 404 error if this task hasn't been previously configured
        if(get(error,'response.status') === 404) return dispatch(createInstructions(task.id,defaults));
        else Notify.error(format.error(error));
      })
      .finally(() => setInitialized(true));
  },[task.id]); // eslint-disable-line

  const fields = [
    {
      label: 'Payment screen details',
      type: 'title',
    },
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      value: get(instructions,'title',''),
      required: 'required',
    },
    {
      label: 'Subtitle',
      type: 'text',
      name: 'subtitle',
      value: get(instructions,'subtitle',''),
    },
    {
      label: 'Primary button text',
      type: 'text',
      name: 'cta_text',
      value: get(instructions,'cta_text',''),
      required: 'required',
    },
    {
      label: 'Instructions content',
      type: 'editor',
      name: 'content',
      value: get(instructions,'content'),
      renderer: (value) => (<AdminContentRenderer content={value} />),
      uploadUrl: `/admins/building-orientation-contents/${instructions?.id}/files`,
      required: true, // TODO: this is not respected in the form component
    },
  ];

  const handleSubmit = (data) => {
    if(pending) return;
    dispatch(updateInstructions(task.id, data))
      .then(() => Notify.default(`Instructions updated!`))
      .catch(error => Notify.error(format.error(error)))
  };

  if(!initialized) return <LoaderOverlay />;

  return (
    <>

      <div className={CSS.content_top}>
        <div className={CSS.form}>
          <DynamicForm
            id='instructions-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
          />
        </div>
      </div>

      <div className={CSS.content_bottom}>
        <div className={CSS.buttons}>
          <Button
            text='Save'
            color='primary'
            form="instructions-form"
            disabled={pending}
            className={CSS.save}
          />
          <Button
            text='Discard changes'
            color='tertiary'
            disabled={pending}
            onClick={() => history.push({
              ...history.location,
              search: 'view=general',
            })}
          />
        </div>
      </div>

    </>
  );
};
