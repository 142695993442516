import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_GET_VENDOR_LIST',
  request: () => request.get('/cx/vendor-list').then(r => ({ all: get(r,'data.data.vendor_list',[]) })),
  selector: (state) => state.vendorList,
  cache: { hours: 1 },
  reducers: {
    success: (state, { response: vendorList }) => ({
      ...state,
      vendorList
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useVendorList,
  usePending: useVendorListPending,
} = generate.redux(creator);

export const initialState = {
  vendorList: {},
};
