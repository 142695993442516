import React from 'react';
import { useHistory} from 'react-router-dom';
import { get} from 'lodash';
import classNames from 'classnames';

import { useUser, format } from '@moved/services';
import { Icon, ClipboardText, Tooltip, Button } from '@moved/ui';
import { ProxyModeIcon } from '@moved/product';

import CSS from './styles/TenantDetails.module.scss';

const _handleReturn = (history) => {
  const from = get(history,'location.state.from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push('/admin/moves/?filter=upcoming');
};

export const TenantDetails = ({ tenantEvent }) => {
  const history = useHistory();
  const { proxyAs, Can, hasDomain } = useUser();

  return (
    <div className={CSS.header}>
      <div className={CSS.title}>
        <h1>
          { format.fullname(get(tenantEvent,'tenant')) }
          { get(tenantEvent,'move.id') && hasDomain('cx') && (
            <Can I="ReadMoves">
              <Button
                size='small'
                color='tertiary'
                text='View in CX'
                icon={{ library: 'navigation', symbol: 'Chevron-right' }}
                iconPosition='right'
                onClick={() => history.push(`/cx/moves/${get(tenantEvent,'move.id')}/steps/${get(tenantEvent,'move_step_id')}?search=${encodeURIComponent(get(tenantEvent,'tenant.email'))}`)}
              />
            </Can>
          )}
        </h1>
        <div className={CSS.subtitle}>
          <div className={CSS.id_row}>
            <div className={CSS.id_item}>
              <span className={CSS.label}>Email</span>
              <ClipboardText text={`${get(tenantEvent,'tenant.email')}`} className={CSS.clipboard}/>
            </div>
            {get(tenantEvent,'user_id') && (
              <div className={CSS.id_item}>
                <span className={CSS.label}>User ID</span>
                <ClipboardText text={`${get(tenantEvent,'user_id')}`} className={CSS.clipboard}/>
              </div>
            )}
            {get(tenantEvent,'move_id') && (
              <div className={CSS.id_item}>
                <span className={CSS.label}>Move ID</span>
                <ClipboardText text={`${get(tenantEvent,'move_id')}`} className={CSS.clipboard}/>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={CSS.oval_area}>
        <span className={CSS.back} onClick={e => _handleReturn(history)}>
          <Icon symbol='Arrow-left' library='navigation' size='18px' />
          <span>Back to search</span>
        </span>
        <div className={CSS.actions}>
          { get(tenantEvent,'user_id') && (
            <Can I={'ProxyAsUsers'}>
              <div className={CSS.action_item}>
                <Tooltip
                  customClass={CSS.popover}
                  placement={"bottom"}
                  tooltip={<>Proxy as {get(tenantEvent,'tenant.firstname')}</>}
                >
                  <div className={classNames(CSS.oval, CSS.action)} onClick={e => proxyAs(get(tenantEvent,'user_id'))}>
                    <ProxyModeIcon style={{height:'24px',width:'24px'}}/>
                  </div>
                </Tooltip>
              </div>
            </Can>
          )}
          <div className={CSS.oval}>
            <span>{format.initials(get(tenantEvent,'tenant'))}</span>
          </div>
        </div>
      </div>
    </div>

  );
};
