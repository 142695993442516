import React from 'react';
import {  useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { TabSlider } from '@moved/ui';
import { useQuery } from '@moved/services';

import { DefaultConfiguration } from '../../../components/DefaultConfiguration';
import { PetRegistrationRequirements } from './PetRegistrationRequirements';

import CSS from './styles/PetRegistration.module.scss';

export const Configuration = ({ task }) => {
  const history = useHistory();
  const location = useLocation();
  const view = useQuery('view') || 'general';

  // Update query string in URL when view is selected
  const setView = (view) => {
    return history.replace({
      pathname: location.pathName,
      search: view ? `?view=${encodeURIComponent(view)}` : '',
    });
  };

  return (
    <>

      <div className={CSS.header}>
        <div className={CSS.title}>
          <h1>{get(task,'title')}</h1>
        </div>
      </div>

      <TabSlider
        className={CSS.slider}
        list={[
          {label: "General", value: 'general'},
          {label: "Custom Inputs", value: 'requirements'},
        ]}
        active={view}
        callback={view => setView(view)}
      />

      <div className={CSS.view}>
        {(function() {
          switch(view) {
            case 'requirements':
              return(<PetRegistrationRequirements task={task} />);
            default:
              return (<DefaultConfiguration task={task} header={false} />);
          };
        })()}
      </div>
    </>
  );
};
