import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { Icon } from '@moved/ui';

import CSS from './styles/TaskHeader.module.scss';

export const TaskHeader = ({ task }) => {
  // HOOKS
  const history = useHistory();
  const location = useLocation();
  const { property_id } = useParams();

  const handleReturn = () => {
    const from = get(location, 'state.from');
    // If user came from search, it will be stored in state.from
    if(from) history.push(from);
    // else just go to the standard moves url
    else history.push(`/admin/properties/${property_id}`);
  };

  return (
    <div className={CSS.header}>
      <div className={CSS.title}>
        <div className={CSS.back_btn} onClick={handleReturn}>
          <Icon symbol='Arrow-left' library='navigation' size='32px' />
        </div>
        <h1>{get(task,'title')}</h1>
      </div>
    </div>
  );
};
