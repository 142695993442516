import { TenantEventsSearchWrapper } from './components/TenantEventsSearchWrapper';
import { TenantEventDetails } from './components/TenantEventDetails';

const routes = [
  {
    path: '/moves',
    name: 'AdminMoves',
    component: TenantEventsSearchWrapper,
    exact: true,
    breadcrumb: {
      label: "Moves",
      tooltip:  "Moves",
      icon: {
        symbol: 'Sign-in',
        library: 'navigation',
      },
    },
  },
  {
    path: '/moves/:id(\\d+)',
    name: 'AdminMoveDetails',
    component: TenantEventDetails,
    exact: true,
    breadcrumb: { label: "Move details" }
  },
];

export default routes;
