import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { Icon, ConfirmationModal } from '@moved/ui';
import { useModal, useUser } from '@moved/services';

import { getPartnerCalendar, cancelReservation } from '../../actions/';
import CSS from '../../styles/WeeklyCalendar.module.scss';

export const AvailabilityBlock = ({ block, calendarId, buildingId, }) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const { Can } = useUser();

  // Times for placement
  const midnight = moment.utc(block.start_time).set({hour:0,minute:0,second:0,millisecond:0});
  const slotStart = moment.utc(block.start_time);
  const slotEnd = moment.utc(block.end_time);

  const blockCancel = appointment => dispatch(cancelReservation(
    'building-appointments',
    appointment.id,
    calendarId,
  ))
  .then(dispatch(getPartnerCalendar(calendarId, moment(block.start_time).startOf('week').format('YYYY-MM-DD'))));

  const cancelAppointment = appointment => Modal.open(
    <ConfirmationModal
      title={'Cancel time block'}
      copy={`Are you sure wish to cancel this block?`}
      confirmText={'Proceed with cancellation'}
      cancelText={'Never mind'}
      onConfirm={e => blockCancel(appointment)}
    />,
    { sondheim: true },
  );

  const slotClasses = classNames(
    CSS.block,
    {
      [CSS.block_small]: slotEnd.diff(slotStart,'minutes') < 31,
    },
  );

  return (
    <div className={slotClasses} style={{ top: `${slotStart.diff(midnight,'minutes')-1}px`, height: `${slotEnd.diff(slotStart,'minutes')+1}px`,}}>
      <div className={CSS.block_content}>
        <div className={CSS.block_line} />
        <div className={CSS.block_appointments}>
          <div className={CSS.block_appointment}>
            <span>{block.title}</span>
            <Can I="CancelBuildingAppointments">
              <span className={CSS.cancel_block} onClick={e => {e.stopPropagation(); return cancelAppointment(block);}}>
                <Icon library={'general'} symbol={'Trash'} size={'16px'}  />
              </span>
            </Can>
          </div>
        </div>
      </div>
    </div>
  );
}
