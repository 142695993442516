import { merge } from 'lodash';

import * as createHappycoInspectionInstructions from './createHappycoInspectionInstructions';
import * as getHappycoInspectionInstructions from './getHappycoInspectionInstructions';
import * as getFileUploadUrl from './getFileUploadUrl';
import * as updateHappycoInspectionInstructions from './updateHappycoInspectionInstructions';

export const reducers = [
  createHappycoInspectionInstructions.reducer,
  getHappycoInspectionInstructions.reducer,
  getFileUploadUrl.reducer,
  updateHappycoInspectionInstructions.reducer,
];

export const initialState = merge(
  createHappycoInspectionInstructions.initialState,
  getHappycoInspectionInstructions.initialState,
  updateHappycoInspectionInstructions.initialState,
);
