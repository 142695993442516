import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, forEach, cloneDeep, noop } from 'lodash';

import {
  DragAndDropContext,
  DragAndDropArea,
  DragAndDropItem,
  useDragAndDropItem,
} from '../../../sondheim/components/DragAndDrop';
import { Icon } from '../../../sondheim/components/Icon';
import CSS from './DragDropList.module.scss';


// Component for each item in list
const DragItem = ({ item, contentLeft=noop, contentRight=noop, className, isDragDisabled }) => {
  const { dragHandleProps, isDragging } = useDragAndDropItem();
  return (
    <div className={classNames(CSS.box, typeof className === "function" ? className(item) : className, { [CSS.dragging]: isDragging })}>

      <div className={CSS.content}>

        <div className={CSS.content_left}>
          { typeof contentLeft === "function" ? contentLeft(item) : contentLeft }
        </div>

        <div className={CSS.content_right}>

          <div className={CSS.additional}>
            { typeof contentRight === "function" ? contentRight(item) : contentRight }
          </div>

          { !isDragDisabled && (
            <div className={CSS.icon_area}>
              <div className={classNames(CSS.icon,CSS.drag)} {...dragHandleProps}>
                <Icon symbol='Drag' library='general' size='24px' />
              </div>
            </div>
          )}

        </div>

      </div>
    </div>
  );
};


// Logic to reorder itmes in lists after drag
const _onDragEnd = (lists, result) => {
  if(!result.destination) return false;

  // Changing index within same bucket
  const moveSameBucket = (arr, from, to) => {
    const newArr = [...arr];
    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);
    return newArr;
  };

  // Moving from one bucket to another
  const moveDifferentBucket = (tasks, source, destination, sourceIdx, destinationIdx) => {
    const newSourceList = [...tasks[source].list];
    const newDestinationList = [...tasks[destination].list]

    const item = newSourceList.splice(sourceIdx, 1)[0];
    newDestinationList.splice(destinationIdx, 0, item);

    return {
      ...tasks,
      [source]: {
        ...tasks[source],
        list: newSourceList,
      },
      [destination]: {
        ...tasks[destination],
        list: newDestinationList,
      },
    };
  };

  // Task list to be submitted
  let updatedLists = cloneDeep(lists);

  const source = get(result,'source.droppableId');
  const sourceIdx = get(result,'source.index');
  const destination = get(result,'destination.droppableId');
  const destinationIdx = get(result,'destination.index');

  // Same bucket
  if (source === destination) {
    updatedLists[destination].list = moveSameBucket(updatedLists[destination].list, sourceIdx, destinationIdx);
  }
  // Different bucket
  else {
    updatedLists = moveDifferentBucket(updatedLists, source, destination, sourceIdx, destinationIdx);
  }

  // set currentTasks which will hit the server off the useEffect
  return updatedLists;
};

// Pimrary List Component
export const DragDropList = ({ lists, onChange, isDisabled }) => {
  return (
    <div className={CSS.wrapper}>
      <DragAndDropContext
        onDragEnd={result => {
          return onChange(_onDragEnd(lists,result));
        }}
      >
        {(function() {
          const buckets = [];
          forEach(lists,(value, bucket) => {
            buckets.push((
              <div className={CSS.bucket} key={bucket}>
                <div className={CSS.bucket_bar}>
                  {value.title}
                  {value.actions}
                </div>
                <DragAndDropArea id={bucket} className={CSS.items} activeClassName={value.list.length === 0 ? CSS.dragover : ''}>
                  { value.list.map((item, idx) => (
                    <DragAndDropItem key={item.id} id={`item_${item.id || idx}`} index={idx} className={CSS.item}>
                      <DragItem
                        item={item}
                        contentLeft={value.contentLeft}
                        contentRight={value.contentRight}
                        className={value.className}
                        isDragDisabled={isDisabled}
                      />
                    </DragAndDropItem>
                  ))}
                </DragAndDropArea>
              </div>
            ));
          })
          return buckets;
        })()}
      </DragAndDropContext>
    </div>
  );
};

DragDropList.propTypes = {
  /** List of buckets containing ordered lists */
  lists: PropTypes.objectOf(PropTypes.shape({
    /** List of items in bucket */
    list: PropTypes.arrayOf(PropTypes.shape({
      /** Id of item in list */
      id: PropTypes.number.isRequired,
    }).isRequired).isRequired,
    /** Title for bucket */
    title: PropTypes.element,
    /** Content for left section */
    contentLeft: PropTypes.func,
    /** Content for right section */
    contentRight: PropTypes.func,
  })).isRequired,
  // Function to run when items are reordered
  onChange: PropTypes.func.isRequired,
};
