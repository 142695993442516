import React from 'react';

import { format } from '@moved/services';
import { moveOutInspection } from '@moved/product';

import { StatusHeader, DashboardLink, RenderAttributes } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const DeclinedContent = ({ request }) => {
  const status = {
    icon: { symbol: 'Error', color: 'red' },
    title: `You've declined this move out inspection request.`,
    description: (<>
      <p className='mb-15'>
        We’ll let the resident know that the request has been declined
        and they need to request another appointment time. The requested details are below.
      </p>
      { request.notes && (<p className='italic'>{request.notes}</p>) }
    </>),
  };
  const fields = [{
    label: 'Date declined',
    value: format.date(request.responded_at,'dateTime'),
  }];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <RenderAttributes attributes={fields} />
      <RenderAttributes attributes={moveOutInspection.getRequestFields(request)} />
      <DashboardLink/>
    </>
  );
};
