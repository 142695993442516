import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_APPROVALS_GET_TASK_TITLES',
  request: () => request.get(`/partners/approvals/building-task-titles`).then(r => get(r,'data.data.building_task_titles')),
  selector: (state) => get(state,'taskTitles'),
  reducers: {
    success: (state, { response: titles }) => {
      return {
        ...state,
        taskTitles: titles,
      };

    }
  }
};

export const {
  action,
  reducer,
  useResource: useTaskTitles,
  usePending: useTaskTitlesPending,
} = generate.redux(creator);

export const initialState = {
  taskTitles: [],
};
