import { merge } from 'lodash';

import * as addChapterTemplates from './addChapterTemplates';
import * as cloneOrientationChapter from './cloneOrientationChapter';
import * as createOrientationChapter from './createOrientationChapter';
import * as createOrientationProgressOverride from './createOrientationProgressOverride';
import * as getChapterTemplates from './getChapterTemplates';
import * as getChapterTemplateCategories from './getChapterTemplateCategories';
import * as getEligibleProperties from './getEligibleProperties';
import * as getFileUploadUrl from './getFileUploadUrl';
import * as getOrientationContents from './getOrientationContents';
import * as removeOrientationChapter from './removeOrientationChapter';
import * as reorderOrientationContents from './reorderOrientationContents';
import * as updateOrientationContents from './updateOrientationContents';

export const reducers = [
  addChapterTemplates.reducer,
  cloneOrientationChapter.reducer,
  createOrientationChapter.reducer,
  createOrientationProgressOverride.reducer,
  getChapterTemplates.reducer,
  getChapterTemplateCategories.reducer,
  getEligibleProperties.reducer,
  getFileUploadUrl.reducer,
  getOrientationContents.reducer,
  removeOrientationChapter.reducer,
  reorderOrientationContents.reducer,
  updateOrientationContents.reducer,
];

export const initialState = merge(
  addChapterTemplates.initialState,
  cloneOrientationChapter.initialState,
  createOrientationChapter.initialState,
  createOrientationProgressOverride.initialState,
  getChapterTemplates.initialState,
  getChapterTemplateCategories.initialState,
  getEligibleProperties.initialState,
  getOrientationContents.initialState,
  removeOrientationChapter.initialState,
  reorderOrientationContents.initialState,
  updateOrientationContents.initialState,
);
