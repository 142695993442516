import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_CREATE_RESERVATION',
  request: (data={}) => request.post(`/partners/building-reservations`, data).then(r => get(r,'data.data.appointment')),
  reducers: {
    success: (state, { response: reservation, params: [{building_calendar_id}] }) => {

      let appointments = [...get(state,`partnerCalendar.${building_calendar_id}.appointments`)];
      appointments.push(reservation);

      return {
        ...state,
        partnerCalendar: {
          ...state.partnerCalendar,
          [building_calendar_id]: {
            ...state.partnerCalendar[building_calendar_id],
            appointments,
          },
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReservationPending,
} = generate.redux(creator);

export const initialState = {};
