import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { format } from '@moved/services';
import { Icon, EventList } from '@moved/ui';

import { PaymentMethod } from './PaymentMethod';
import CSS from '../../styles/PaymentHistory.module.scss';

const getPaymentDisplayLabel = (payment) => {
  const labelMap = {
    customer_initial: 'initial',
    customer_overage: 'overage',
    customer_cx_retry: 'retry',
    vendor_payout: 'payout',
  }
  return labelMap[payment.label] || payment.label; // backward compatibility
}

export const PaymentHistory = ({ payments=[], preAuths=[] }) => {
  const events = [];

  // Add preauth attempts to history
  preAuths.forEach((preAuth,index) => {
    const isSuccessful = get(preAuth,'status') === 'placed_hold';
    const isCanceled = get(preAuth,'status') === 'canceled';
    events.push({
      key: `preAuth-${get(preAuth,'created_at')}`, // no unique id :(
      color: isSuccessful ? 'yellow' : 'red',
      title: `${isSuccessful ? 'Successful' : (isCanceled ? 'Canceled' : 'Declined')} authorization (${format.currency(preAuth.amount_in_cents)})`,
      subtitle: get(preAuth,'stripe_payment_intent.payment_method') && (<PaymentMethod payment={get(preAuth,'stripe_payment_intent.payment_method')}/>),
      link: get(preAuth,'stripe_payment_intent.stripe_id') && `${process.env.REACT_APP_STRIPE_CLIENT_URL}/payments/${get(preAuth,'stripe_payment_intent.stripe_id')}`,
      timestamp: preAuth.created_at,
    });
  });

  // Add payment attempts to history
  payments.forEach((payment) => {
    const isSuccessful = get(payment,'status') === 'paid_in_full';
    const event = {
      key: payment.id,
      color: isSuccessful ? 'green' : 'red',
      timestamp: payment.created_at,
    };
    switch (payment.label) {
      case 'customer_initial':
      case 'customer_overage':
      case 'customer_cx_retry':
        event.title = `${isSuccessful ? 'Successful' : 'Declined'} ${getPaymentDisplayLabel(payment)} capture (${format.currency(payment.amount)})`;
        event.subtitle = get(payment,'stripe_payment_intent') && (<PaymentMethod payment={get(payment,'stripe_payment_intent.payment_method')}/>);
        event.link = get(payment,'stripe_payment_intent') && `${process.env.REACT_APP_STRIPE_CLIENT_URL}/payments/${get(payment,'stripe_payment_intent.stripe_id')}`;
        break;
      case 'vendor_payout':
        event.title = `${isSuccessful ? 'Successful' : 'Declined'} ${getPaymentDisplayLabel(payment)} (${format.currency(payment.amount)})`;
        event.subtitle = `${get(payment,'payee.vendor.name')} via stripe transfer`;
        event.link = get(payment,'stripe_transfer_id') && `${process.env.REACT_APP_STRIPE_CLIENT_URL}/connect/transfers/${get(payment,'stripe_transfer_id')}`;
        break;
      default: return;
    }
    events.push(event);
  });

  if(events.length) events.sort((a,b) => {
    return moment.utc(a.timestamp).isBefore(b.timestamp) ? 1 : -1;
  });
  else events.push({
    key: 'default',
    color: 'yellow',
    title: `Card will be authorized 48 hours prior to move`,
  });

  return (
    <EventList events={events.map(({ subtitle, timestamp, link, ...event }) => ({
      ...event,
      content: (<>
        { subtitle && <div className={CSS.event_content}>{ subtitle }</div> }
        { timestamp && <div className={CSS.event_content}>{ format.date(timestamp,'dateTime') }</div> }
        { link && (
          <a className={CSS.stripe_link} href={link} target='_blank' rel="noopener noreferrer">
            View in Stripe <Icon library='navigation' symbol='Up-right' size='16px' color='blue'/>
          </a>
        )}
      </>)
    }))}/>
  );
};
