import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { ModalTitle, ModalContent, ModalActions, Button } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services';

import { addChapterTemplates } from '../../actions';
import { useAddChapterTemplatesPending } from '../../actions/selectors';

import CSS from './styles/ConfirmTemplates.module.scss';

// TODO: filter chips as UI component

export const ConfirmTemplates = ({ data={}, taskId, onBack }) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const pending = useAddChapterTemplatesPending();

  const selections = get(data,'selected',[]).map(chapter => JSON.parse(chapter));

  const submit = () => {
    dispatch(addChapterTemplates(taskId, { content_gallery_template_ids: selections.map(template => template.id) }))
      .then(() => {
        Notify.default('Template successfully added to this task.');
        Modal.close(true);
      })
      .catch(error => Notify.error(format.error(error)));;
  }

  return (
    <>
      <ModalTitle>
        <span className='contentSecondary mr-10'>3/3</span>
        Confirm selection
      </ModalTitle>

      <ModalContent className={CSS.content}>
        <div className='labelM contentPrimary mb-15 mt-10'>
          Selected templates
          <span className='contentSecondary ml-10'>{ selections.length }</span>
        </div>
        <div className='stackHorizontal-8 mb-20'>
          { selections.map(template => (
            <div key={template.id} className={classNames('labelM contentPrimary', CSS.filter_chip)}>{ template.title }</div>
          ))}
        </div>
      </ModalContent>

      <ModalActions>
        <Button
          text='Back'
          icon={{ library: 'navigation', symbol: 'Arrow-left' }}
          color='secondary'
          disabled={pending}
          onClick={() => !pending && onBack()}
        />
        <div className={CSS.spacer} />
        <Button
          text='Cancel'
          color='secondary'
          disabled={pending}
          onClick={() => !pending && Modal.close()}
        />
        <Button
          text="Confirm & Add templates"
          color='primary'
          onClick={() => !pending && submit()}
          disabled={pending}
        />
      </ModalActions>
    </>
  );
};
