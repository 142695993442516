import React, { memo, createRef, useEffect } from 'react';

import weekCSS from '../../styles/WeeklyCalendar.module.scss';
import { AvailabilityDay, AvailabilityKey } from '../../'

//If calendar data is pending, don't rerender
const shouldSkipUpdate = (oldProps, newProps) => newProps.getCalendarPending;


export const AvailabilityWeek = memo(({ week, selected, calendarId, buildingId, type }) => {
  const calRef = createRef();

  useEffect(() => {
    if(calRef.current) {
      calRef.current.scrollTop = 420;
    }
  // eslint-disable-next-line
  },[]);

  return (
    <div className={weekCSS.week_row} ref={calRef}>
      <div className={weekCSS.week_holder}>

      <AvailabilityKey />

      { week.map(day => (
        <AvailabilityDay
          key={day.date}
          date={day.date}
          slots={day.slots}
          blocks={day.blocks}
          active={day.date === selected}
          calendarId={calendarId}
          type={type}
          buildingId={buildingId}
        />
      ))}
      </div>
    </div>
  );
}, shouldSkipUpdate);
