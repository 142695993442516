import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_FEED',
  request: (id, params, cancelToken) => request.get(`/admins/buildings/${id}/feeds`, { params, cancelToken }).then(r => r.data.data.feed),
  selector: (state, id) =>  get(state,`buildingFeeds.${id}`),
  reducers: {
    success: (state, { response: feed, params: [id] }) => ({
      ...state,
      buildingFeeds: {
        ...state.buildingFeeds,
        [id]: feed,
      },
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      buildingFeeds: {
        ...state.buildingFeeds,
        [id]: null,
      }
    })
  }
};

export const {
  action,
  reducer,
  useResource: useBuildingFeed,
  usePending: useBuildingFeedPending,
} = generate.redux(creator);

export const initialState = {
  buildingFeeds: {},
};
