import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_HAPPYCO_INSPECTION_INSTRUCTIONS',
  request: (taskId) => request.get(`/admins/building-tasks/${taskId}/happyco-inspection-instructions`).then(r => get(r, 'data.data.instruction_content')),
  selector: (state, taskId) => get(state,`happycoInspectionInstructions.${taskId}`),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      happycoInspectionInstructions: {
        ...state.happycoInspectionInstructions,
        [id]: response,
      }
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      happycoInspectionInstructions: {
        ...state.happycoInspectionInstructions,
        [id]: null,
      }
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useHappycoInspectionInstructions,
  usePending: useHappycoInspectionInstructionsPending,
} = generate.redux(creator);

export const initialState = {
  happycoInspectionInstructions: {},
};
