import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useNotify, format, useUser } from '@moved/services';
import { SlideToggle, LoaderOverlay } from '@moved/ui';

import { updateMove } from '../actions/';
import { useUpdateMovePending } from '../actions/selectors';

import CSS from '../styles/MoveDetailsStatus.module.scss';

const statusList = [
  {
    label: 'Core',
    name: 'core',
    value: 0,
  },
  {
    label: 'Active',
    name: 'active',
    value: 1,
  },
  {
    label: 'Inactive',
    name: 'inactive',
    value: 2,
  },
  {
    label: 'Archived',
    name: 'archived',
    value: 3,
  },
];

export const MoveDetailsStatus = ({ move }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const pending = useUpdateMovePending();
  const { hasAbilities } = useUser();

  const submitStatus = option => {
    dispatch(updateMove(move.id, { status: option }))
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <>
      { pending && <LoaderOverlay /> }
      <SlideToggle
        className={CSS.status_toggle}
        callback={submitStatus}
        options={statusList.map(status => ({
          label: (
            <div className={CSS.status_label}>
              <span className={classNames(CSS.status_icon,CSS[status.name])} />
              <span>{status.label}</span>
            </div>
          ),
          value: status.value,
        }))}
        disabled={!hasAbilities('UpdateMoves')}
        active={get(move,'status')}
      />
    </>
  );
};
