import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_DOCUMENT_SUBMISSION_REQUEST',
  request: (token) => request.get(`/partners/document-submission-requests/token/${token}`).then(r => r.data.data.document_submission_request),
  selector: (state, token) => get(state, `documentSubmissions.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        documentSubmissions: {
          ...state.documentSubmissions,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useDocumentSubmission,
  usePending: useDocumentSubmissionPending,
} = generate.redux(creator);

export const initialState = {
  documentSubmissions: {},
};
