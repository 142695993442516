import { merge } from 'lodash';

import * as assignAdminProperty from './assignAdminProperty';
import * as createAdmin from './createAdmin';
import * as getAdminSearch from './getAdminSearch';
import * as getAdmin from './getAdmin';
import * as removeAdminBuilding from './removeAdminBuilding';
import * as triggerAdminInvite from './triggerAdminInvite';
import * as updateAdmin from './updateAdmin';
import * as updateNotificationPreferences from './updateNotificationPreferences';


export const reducers = [
  assignAdminProperty.reducer,
  createAdmin.reducer,
  getAdminSearch.reducer,
  getAdmin.reducer,
  removeAdminBuilding.reducer,
  triggerAdminInvite.reducer,
  updateAdmin.reducer,
  updateNotificationPreferences.reducer,
];

export const initialState = merge(
  assignAdminProperty.initialState,
  createAdmin.initialState,
  getAdminSearch.initialState,
  getAdmin.initialState,
  removeAdminBuilding.initialState,
  triggerAdminInvite.initialState,
  updateAdmin.initialState,
  updateNotificationPreferences.initialState,
);
