import React from 'react';

import { format } from '@moved/services';
import { CreditCardLogo } from '@moved/ui';

export const getMoverBookingFields = (moverBooking) => {
  return [
    moverBooking?.long_distance && {
      label: 'Requested long distance quotes',
      value: moverBooking?.contact_email ? 'Yes' : 'No thanks',
      icon: { symbol: 'Sending', library: 'communication' },
    },
    moverBooking?.requested_pickup_date && {
      label: 'Move date',
      value: format.date(moverBooking?.requested_pickup_date),
      icon: { symbol: 'Date', library: 'code' },
    },
    // removed from inputs, but data might still exist for older customers (keep for a while -- 2024-09-24)
    moverBooking?.time_preference && {
      label: 'Time preference',
      value: moverBooking?.time_preference,
      icon: { symbol: 'Clock', library: 'home' },
    },
    ...(moverBooking?.stops ?? []).map((stop, index) => {
      let label = `Stop ${index}`;
      let icon = { symbol: 'Marker#1', library: 'map' };
      if(index === 0) {
        label = 'Origin';
        icon = { symbol: 'Home', library: 'home' };
      }
      if(index === moverBooking?.stops?.length - 1) {
        label = 'Destination';
        icon = { symbol: 'Building', library: 'home' };
      }
      return {
        label,
        value: format.stopDisplay(stop),
        valueText: format.stopDisplayText(stop),
        icon,
      }
    }),
    // removed from inputs, but data might still exist for older customers (keep for a while -- 2024-09-24)
    moverBooking?.number_of_rooms && {
      label: 'Move size',
      value: moverBooking?.number_of_rooms === 'Studio' ? 'Studio' : `${moverBooking?.number_of_rooms} Bedrooms`,
      icon: { symbol: 'Layout-top-panel-3', library: 'layout' },
    },
    moverBooking?.special_items?.length && {
      label: 'Special items',
      value: (moverBooking?.special_items ?? []).map(item => item.label).join(', '),
      icon: { symbol: 'Warning-2', library: 'code' },
    },
    moverBooking?.inventory_text && {
      label: 'Inventory list (from partner)',
      value: moverBooking?.inventory_text,
      icon: { symbol: 'Clipboard-list', library: 'files' },
    },
    moverBooking?.contact_name && {
      label: 'Contact name',
      value: moverBooking?.contact_name,
      icon: { symbol: 'User', library: 'general' },
    },
    moverBooking?.contact_email && {
      label: 'Contact email',
      value: moverBooking?.contact_email,
      icon: { symbol: 'Mail', library: 'communication' },
    },
    moverBooking?.contact_phone && {
      label: 'Contact phone',
      value: format.phone(moverBooking?.contact_phone),
      icon: { symbol: 'Active-call', library: 'communication' },
    },
  ].filter(v=>v);
};

export const getMoverBookingServiceOrderFields = (serviceOrder) => {
  return [
    serviceOrder?.confirmation_number && {
      label: 'Confirmation number',
      value: `#${serviceOrder?.confirmation_number}`,
      icon: { symbol: 'Check', library: 'navigation' },
    },
    serviceOrder?.purchase_date_time && {
      label: 'Booking date',
      value: format.date(serviceOrder?.purchase_date_time,'dateTime'),
      icon: { symbol: 'Date', library: 'code' },
    },
    serviceOrder?.rate_type && {
      label: 'Rate type',
      value: serviceOrder?.rate_type === 'flat' ? 'Flat rate' : 'Hourly',
      icon: { symbol: 'Money', library: 'shopping' },
    },
    serviceOrder?.crew_size && {
      label: 'Crew size',
      value: `${serviceOrder?.crew_size} movers`,
      icon: { symbol: `Crew-${serviceOrder?.crew_size}`, library: 'people' },
    },
    serviceOrder?.payment_information && {
      label: 'Payment method',
      value: (
        <>
          <CreditCardLogo symbol={serviceOrder?.payment_information.data.card_type} />
          <span className={'ml-10'}>
            {`${format.capitalize(serviceOrder?.payment_information.data.card_type)} ending in ${serviceOrder?.payment_information.data.card_last4}`}
          </span>
        </>
      ),
      icon: { symbol: 'Credit-card', library: 'shopping' },
    },
  ].filter(v=>v);
};

export const getSummaryFields = (moverBooking) => {
  return [
    ...getMoverBookingFields(moverBooking),
    ...getMoverBookingServiceOrderFields(moverBooking?.booked_service_order ?? {}),
  ].filter(v=>v&&v.value);
};
