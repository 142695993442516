import React from 'react';

import { useUser } from '@moved/services';

import { TaskNavigation } from '../../../components';

export const OrientationNavigation = () => {
  // HOOKS
  const { hasAbilities } = useUser();

  // Note: must match available child routes
  const screens = [
    { label: 'General', value: '' },
    hasAbilities('ReadContentEditorContents') && { label: 'Chapters', value: `/chapters` },
  ].filter(v=>v);

  return <TaskNavigation screens={screens}/>;
}
