import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_UPDATE_UTILITY_SETUP_DETAILS',
  request: (id, data={}) => request.patch(`/admins/building-tasks/${id}/building-utility-provider-requirements`, data).then(r => get(r,'data.data.requirements')),
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        utilityRequirements: {
          ...state.utilityRequirements,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateUtilitySetupPending,
} = generate.redux(creator);

export const initialState = {};
