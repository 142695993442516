import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CREATE_ORIENTATION_CHAPTER',
  request: (id, data={}) => request.post(`/admins/building-tasks/${id}/content-editor-contents`, data).then(r => get(r,'data.data.content_editor_content')),
  reducers: {
    success: (state, { response: {fetched_at, ...chapter}, params: [id] }) => {
      return {
        ...state,
        orientationContents: {
          ...state.orientationContents,
          [id]: [
            ...state.orientationContents[id],
            chapter,
          ],
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateOrientationChapterPending,
} = generate.redux(creator);

export const initialState = {};
