import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_CREATE_COI_UPLOAD_FILE',
  request: (tenantEventId, buildingTaskId, data) => request.post(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/coi-upload-files`,data)
    .then(r => get(r,'data.data.coi_upload_file')),
  reducers: {
    success: (state, { response: coiUploadFile, params: [tenantEventId] }) => ({
      ...state,
      tenantEvents: {
        ...state.tenantEvents,
        [tenantEventId]: {
          ...state.tenantEvents[tenantEventId],
          coi_upload_files: [
            ...(state.tenantEvents[tenantEventId].coi_upload_files || []),
            coiUploadFile,
          ],
        },
      },
    })
  }
};

export const {
  action,
  reducer,
  usePending: useCreateCoiUploadFilePending,
} = generate.redux(creator);

export const initialState = {};
