import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import { CardSetupForm } from '@moved/ui';

import { addPaymentMethod } from '../../actions';

import CSS from '../../styles/AddPaymentModal.module.scss';

export const AddPaymentModal = ({ order={} }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();

  const addCard = ({ setupIntent }) => {
    dispatch(addPaymentMethod(order.id, { stripe_payment_method_id: setupIntent.payment_method }))
      .then(() => {
        Notify.default(`Payment Method Updated`);
        Modal.close();
      })
      .catch(error => {
        setPending(false);
        Notify.error(format.error(error))
      });
  };

  const handleStripeError = (error) => {
    setPending(false);
    Notify.error(
      format.error(error,false,format.stripeError), // map stripe errors
      { autoHideDuration: 6000 }, // slightly longer than normal display times
    );
  };

  return (<>
    <h3 className={CSS.title}>Add Payment Method</h3>
    <CardSetupForm
      onSubmit={() => setPending(true)}
      onSuccess={addCard}
      onError={handleStripeError}
      className={CSS.form}
      submitLabel={false}
      formId='stripe-card-form'
    />
    <div className={CSS.actions}>
      <button
        className={classNames(CSS.action,'btn-gray')}
        onClick={Modal.close}
      >
        Cancel
      </button>
      <button
        className={classNames(CSS.action,'btn-primary',{loading:pending})}
        disabled={pending}
        type='submit'
        form='stripe-card-form'
      >
        Save Card
      </button>
    </div>
  </>);

}
