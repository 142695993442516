import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TRIGGER_BUILDING_FEED',
  request: (id, data) => request.post(`/admins/buildings/${id}/feed-syncs`, data).then(r => get(r,'data.data.feed_sync')),
  reducers: {
    success: (state, { response: feedSync, params: [id] }) => {
      const newTotalItems = get(state,`buildingFeedHistories.${id}.pagination.total_items`) + 1;
      const newPageCount = Math.ceil(newTotalItems / 10) // hardcode PER_PAGE_COUNT for now
      const historyToRetain = newTotalItems > 10 ? // hardcode PER_PAGE_COUNT for now
              get(state,`buildingFeedHistories.${id}.history`,[]).slice(0,-1) :
              get(state,`buildingFeedHistories.${id}.history`,[]);
      return {
        ...state,
        buildingFeeds: {
          ...get(state,'buildingFeeds'),
          [id]: {
            ...get(state,`buildingFeeds.${id}`),
            // update the last feed sync
            last_feed_sync_summary: feedSync,
          },
        },
        buildingFeedHistories: {
          ...get(state,'buildingFeedHistories'),
          [id]: {
            ...get(state,`buildingFeedHistories.${id}`),
            // append the feedSync as the new first item in history
            history: [
              feedSync,
              ...historyToRetain,
            ],
            pagination: {
              ...get(state,`buildingFeedHistories.${id}.pagination`),
              total_items: newTotalItems, // update the count for the search now that we've added one
              total_pages: newPageCount, // update the number of pages in case it's increased by one
            },
          },
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useTriggerBuildingFeedPending,
} = generate.redux(creator);

export const initialState = {};
