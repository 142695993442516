import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { find } from 'lodash';

import { Icon } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import { cloneOrientationChapter } from '../../actions';
import { useEligibleProperties, useCloneOrientationChapterPending, useOrientationContents } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';


export const ConfirmChoices = ({ id, dataCollection, setSuccess, progress, setProgress, length }) => {
  // HOOKS
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();

  // REDUX
  const eligible = useEligibleProperties(id);
  const updatePending = useCloneOrientationChapterPending();
  const contents = useOrientationContents(id);

  const {
    content_editor_content_ids,
    building_ids
  } = dataCollection;

  const chosenChapters = content_editor_content_ids.map(id => find(contents, content => parseInt(content.id) === parseInt(id)));
  const chosenBuildings = building_ids.map(id => find(eligible, prop => parseInt(prop.building.id) === parseInt(id)));

  const _handleSubmit = (data) => {
    dispatch(cloneOrientationChapter(id, dataCollection))
      .then(resp => {
        Notify.default(`Chapters cloned!`);
        setSuccess(resp);
        return setProgress(progress+1);
      })
      .catch(error => Notify.error(format.error(error)));
  };


  return (
      <>
        <h3 className={CSS.title}><span>{progress}/{length}</span> Confirm selection</h3>

        <div className={CSS.content}>

          <h4 className={CSS.selected_count}>Selected chapters <span>{chosenBuildings.length}</span></h4>
          <div className={CSS.pills}>
            {chosenChapters.map(chapter => (<div className={CSS.pill} key={chapter.id}>{chapter.title}</div>))}
          </div>

          <h4 className={CSS.selected_count}>Selected properties <span>{chosenBuildings.length}</span></h4>
          <div className={CSS.pills}>
            {chosenBuildings.map(property => (<div className={CSS.pill} key={property.building.id}>{property.building.name}</div>))}
          </div>
        </div>

        <div className={CSS.actions}>
          <div className={CSS.actions_left}>
            {progress > 1 && (
              <label
                tabIndex="2"
                role="button"
                className={CSS.back}
                onClick={e => {
                  e.preventDefault();
                  return setProgress(progress-1);
                }}
              >
                <Icon symbol={'Arrow-left'} library={'navigation'} color={'black'} />
                <span>Back</span>
              </label>
            )}
          </div>
          <div className={CSS.actions_right}>
            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-gray')}
              disabled={updatePending}
              onClick={e => {
                e.preventDefault();
                if (updatePending) return;
                return Modal.close();
              }}
            >
              Cancel
            </label>

            <label
              id='property_form_button'
              htmlFor="property-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', CSS.save, {'loading': updatePending})}
              disabled={updatePending}
              onClick={_handleSubmit}
            >
              Confirm &amp; Clone
            </label>
          </div>
        </div>

      </>
  );
};
