import React from 'react';

import CSS from '../styles/shared.module.scss';
import { StatusHeader } from './StatusHeader';

export const ErrorContent = ({error}) => (
  <StatusHeader
    icon={{ symbol: 'Warning', color: 'red' }}
    title='Sorry'
    description={<>
      We were unable to process your request.<br />
      <span className={CSS.error_message}>Error: {error}</span>
    </>}
  />
);
