import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_CANCEL_ORDER',
  request: (id, data) => request.post(`/cx/service-orders/${id}/cancel`, data).then(r => get(r,'data.data.service_order')),
  reducers: {
    success: (state, { response: order, params: [id] }) => ({
      ...state,
      orders: {
        ...state.orders,
        [id]: order,
      }
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useCancelOrderPending,
} = generate.redux(creator);

export const initialState = {};
