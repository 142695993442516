import React from 'react';
import { Providers, generate } from '@moved/services';

import '../styles/Root.scss';

import '../defaults';
import { App } from './App';
import { reducer } from '../reducers';

const store = generate.reduxStore(reducer);

export const Root = () => (
  <Providers store={store} exclude={['Track']}>
    <App />
  </Providers>
);
