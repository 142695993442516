import { generate, request } from '@moved/services';
import { get } from 'lodash';

const getUniqueKey = (...params) => params.join('-');

const creator = {
  namespace: 'ADMIN_GET_TENANT_KEY_PICKUP_OPTIONS',
  request: (tenantEventId, buildingTaskId) => request.get(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/key-pickup-task-options`).then(r => get(r, 'data.data.key_pickup_task_options')),
  selector: (state, ...params) => { return get(state,`tenantKeyPickupOptions.${getUniqueKey(...params)}`); },
  reducers: {
    success: (state, { response, params }) => {
      return {
        ...state,
        tenantKeyPickupOptions: {
          ...state.tenantKeyPickupOptions,
          [getUniqueKey(...params)]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useTenantKeyPickupOptions,
  usePending: useTenantKeyPickupOptionsPending,
} = generate.redux(creator);

export const initialState = {
  tenantKeyPickupOptions: {},
};
