import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_ADMINS_UPDATE_NOTIFICATION_PREFERENCES',
  request: (adminID, buildingAdminID, data) => request.patch(`/admins/admins/${adminID}/building-admins/${buildingAdminID}/notification-preferences`, data).then(r => get(r,'data.data.building_admin')),
  selector: (state,id) => get(state,`admins[${id}]`),
  reducers: {
    success: (state, { response: buildingAdmin, params: [adminID] }) => {

      // Update the appropriate buildingAdmin inside of the admin
      const admin = get(state,`admins[${adminID}]`);
      const updatedBuildingAdmins = get(admin,'building_admins').map(ba => {
        return ba.id === buildingAdmin.id
          ? buildingAdmin
          : ba;
      });

      return {
        ...state,
        admins: {
          ...state.admins,
          [adminID]: {
            ...admin,
            building_admins: updatedBuildingAdmins,
          },
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateNotificationPreferencePending,
} = generate.redux(creator);

export const initialState = {
  admins: {},
};
