import React from 'react';

import { useModal, useUser } from '@moved/services';

import { RescheduleModal } from '../modals/RescheduleModal';
import { Action } from './Action';
import { isOrderReschedulable } from '../../helpers';

export const Reschedule = ({ order, ...props }) => {
  const Modal = useModal();
  const { hasAbilities } = useUser();
  // early exit
  if(!isOrderReschedulable(order) || !hasAbilities('UpdateServiceOrders')) return null;
  // create the action button
  const openModal = () => Modal.open(<RescheduleModal order={order} />);
  const icon = {
    library: 'code',
    symbol: 'Time-schedule',
  };

  return <Action onClick={openModal} icon={icon} {...props}>Reschedule</Action>;
};
