import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Icon } from '@moved/ui';

import CSS from '../styles/MovesSearchResult.module.scss';

const messageTypeMap = [
  {name:'text', type:'text'},
  {name:'status', type:'system'},
  {name:'image', type:'image', preview:'Special: Image Upload'},
  {name:'quote', type:'quotes', preview:'Special: Quotes'},
  {name:'payment', type:'payment', preview:'Special: Payment'},
  {name:'task_link', type:'task', preview:'Special: Task Link'},
  {name:'resident_contact_message', type:'text', preview:'Special: Contact Form Message'},
];

const getLatestMessagePreview = (move) => {
  const message = get(move,'last_customer_message') || get(move,'last_message');
  if(get(move,'user.external') || !message) return 'No messages';
  let type = messageTypeMap.find(m => m.name === message.type);
  if(type && type.preview) return type.preview;
  if(type) return message.body;
  return 'Invalid Message';
};

export const MovesSearchResult = ({ move, isActive, onClick }) => {
  // HOOKS
  const needsResponse = get(move,'last_message') && (get(move,'last_message.sender.id') === get(move,'user.id'));

  return (
    <div
      className={classNames(CSS.box, { [CSS.active]: isActive })}
      onClick={() => onClick(move)}
    >
      { get(move,'needs_quotes') && (
        <div className={CSS.needs_quotes}>QUOTES</div>
      )}
      <div className={CSS.row}>

          <div className={CSS.name}>{ get(move,'user.fullname') }</div>

          <div className={classNames(CSS.status,CSS[get(move,'status')])}>{ get(move,'status') }</div>

      </div>

      <div className={CSS.row}>

        <div className={CSS.move_date}>{format.date(get(move,'move_date'),'ddd M/D/YY') || (<span className="italic">Unknown move date</span>)}</div>

        <div className={CSS.last_active_date}>
          <Icon library='home' symbol='Clock' size='16px' className={CSS.icon}/>
          {format.date(get(move,'last_active'),'M/D/YY h:mma')}
        </div>

      </div>

      <div className={classNames(CSS.message,{[CSS.needs_response]:needsResponse})}>
        { needsResponse && (
          <Icon library='code' symbol='Warning-1-circle' color='orange' size='20px' className={CSS.icon}/>
        )}
        <span>{ getLatestMessagePreview(move) }</span>
      </div>

    </div>
  );
};
