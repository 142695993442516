import React from 'react';
import classNames from 'classnames';

import css from './styles/Minibar.module.scss';

const _completeCount = (items) => {
  let number = 0;
  items.forEach((item) => {
    if(item.status === "complete") number++;
  })
  return number;
};

const _renderDash = (complete, total) => {
  let dashArr = [];
  for(let i = 0; i < total; i++) {
    dashArr.push(
      <span key={i} className={i < complete ? css.completed : null} />
    );
  }
  return dashArr;
};

export const Minibar = ({ tasks, shadow = false }) => {
  if(!tasks) return null;
  const complete = _completeCount(tasks);
  const total = tasks.length;
  return (
    <div className={classNames(css.progress, { [css.progress_complete]: complete === total, [css.shadow] : shadow })}>
      <div className={css.minibar}>
        {_renderDash(complete,total)}
      </div>
      <div className={css.number}>{Math.round((complete/total)*100)}%</div>
    </div>
  );
};
