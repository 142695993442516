import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_BUILDING_FEED_SYNC_SUMMARY',
  request: (id, syncId) => request.get(`/admins/buildings/${id}/feed-sync-summaries/${syncId}`).then(r => get(r,'data.data.feed_sync_summary')),
  reducers: {
    success: (state, { response: feedSync, params: [id, syncId] }) => ({
      ...state,
      buildingFeeds: {
        ...get(state,'buildingFeeds'),
        [id]: {
          ...get(state,`buildingFeeds.${id}`),
          // if the fetched feed sync is the last feed sync, update it here as well
          ...(get(state,`buildingFeeds.${id}.last_feed_sync_summary.id`) === syncId ? { last_feed_sync_summary: feedSync } : {}),
        },
      },
      buildingFeedHistories: {
        ...get(state,'buildingFeedHistories'),
        [id]: {
          ...get(state,`buildingFeedHistories.${id}`),
          history: get(state,`buildingFeedHistories.${id}.history`,[]).map(sync => sync.id === syncId ? feedSync : sync),
        },
      },
    }),
  }
};

export const {
  action,
  reducer,
} = generate.redux(creator);

export const initialState = {};
