import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'APPROVALS_GET_COI_UPLOAD',
  request: (token) => request.get(`/partners/coi-uploads/token/${token}`).then(r => r.data.data.coi_upload),
  selector: (state, token) => get(state,`coiUploads.${token}`),
  reducers: {
    success: (state, { response: coiUpload, params: [token] }) => {
      return {
        ...state,
        coiUploads: {
          ...state.coiUploads,
          [token]: coiUpload,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useCoiUpload,
  usePending: useCoiUploadPending,
} = generate.redux(creator);

export const initialState = {
  coiUploads: {},
};
