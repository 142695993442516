import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { DataBar, Button } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

import { getBuildingFeedSyncSummary, triggerBuildingFeed } from '../actions';
import { useProperty, useBuildingFeed, useBuildingFeedPending, useTriggerBuildingFeedPending } from '../actions/selectors';

import { PropertyIntegrationHistory } from './PropertyIntegrationHistory';

import CSS from './styles/PropertyIntegrations.module.scss';

const PMSLabelMap = {
  moved: 'Moved',
  entrata: 'Entrata',
  yardi: 'Yardi',
  realpage: 'RealPage',
  resman: 'Resman',
  durst: 'Durst',
};

const TriggerLabelMap = {
  'lease-signed': 'Lease signed',
  'application-approved': 'Application approved',
  'notice': 'Notice',
};

export const PropertyIntegrationSummary = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { property_id, activeIntegrationType } = useParams();
  const property = useProperty(property_id);
  const { Can } = useUser();

  const [page, setPage] = useState(1); // promoted from history to give control
  const feed = useBuildingFeed(property_id);
  const pending = useBuildingFeedPending();
  const triggerPending = useTriggerBuildingFeedPending();
  const lastSync = get(feed,'last_feed_sync_summary');
  const isFeedPending = triggerPending || (lastSync && !lastSync.completed_at);

  // if a lastSync is pending set a timer that will refresh it at an interval until it is no longer pending
  useEffect(() => {
    let interval;
    if(lastSync && !lastSync.completed_at) {
      interval = setInterval(() => {
        dispatch(getBuildingFeedSyncSummary(property_id, lastSync.id))
          .catch(error => Notify.error(format.error(error)));
      }, 1000 * 30); // refresh every 30 sec.
    }
    return () => clearInterval(interval);
  },[lastSync, property_id]); // eslint-disable-line

  // declare the attributes to be displayed in the DataBar component
  const feedOverview = [
    {
      key:'pms',
      label: 'PMS',
      value: PMSLabelMap[get(feed,'pms_name')] || 'Unknown',
    },
    {
      key:'trigger',
      label: 'Trigger',
      value: TriggerLabelMap[get(feed,'trigger_type')] || 'Unknown',
    },
    {
      key:'last-sync',
      label: 'Last Sync',
      value: lastSync ? format.date(get(lastSync,'completed_at') || get(lastSync,'created_at'),'MMM D, h:mm a') : 'Never',
    },
  ];

  const triggerFeed = () => {
    if(isFeedPending) return;
    if(page !== 1) setPage(1);
    dispatch(triggerBuildingFeed(property_id, { move_step_type: activeIntegrationType }))
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <>
      <DataBar className='mt-15' attributes={feedOverview} />

      <div className={CSS.section_title}>
        <div className='labelL contentPrimary'>Sync History</div>
        { get(feed,'manual_sync_enabled') && (
          <Can I={'CreateFeedSyncs'}>
            <Button
              text={isFeedPending ? 'Syncing' : 'Sync now'}
              size='small'
              color='tertiary'
              icon={{ library: 'general', symbol: 'Update'}}
              className={classNames({
                [CSS.feed_pending]: isFeedPending,
              })}
              disabled={pending || isFeedPending }
              onClick={triggerFeed}
            />
          </Can>
        )}
      </div>

      <PropertyIntegrationHistory
        property={property}
        activeIntegration={activeIntegrationType}
        page={page}
        setPage={setPage}
      />

    </>
  );
};
