import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, Notebox } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { updateFileRequirementList, } from '../actions';
import { useUpdateFileRequirementListPending } from '../actions/selectors';
import { FileRequirements } from './FileRequirements';

import CSS from './styles/FileRequirementListForm.module.scss';

const _handleReturn = (state, history, property_id) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push(`/admin/properties/${property_id}`);
};

export const FileRequirementListForm = ({ task, fileList }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const Notify = useNotify();

 // Get id from URL
  const { property_id } = useParams();

  // REDUX
  const updatePending = useUpdateFileRequirementListPending();
  const pending = updatePending;

  const handleSubmit = (data) => {
    dispatch(updateFileRequirementList(fileList.id, data))
      .then(() => { Notify.default(`Task updated!`);})
      .catch(error => Notify.error(format.error(error)));
  };

  let fields = [
    {
      label: 'Document list details',
      type: 'title',
    },
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      value: fileList.title,
      required: true,
    },
  ];

  return (
    <>
      <div className={CSS.content_top}>

        <div className={CSS.attributes}>
          {fileList.building_task_count > 1 && (
            <Notebox
              heading='Changes affect linked lists'
              body={`Any changes to this section will also affect all other building tasks linked to this requirement list`}
              color='yellow'
              icon={{
                symbol: 'Warning-2',
                library: 'code',
              }}
              className={CSS.note_box}
            />
          )}

          <DynamicForm
            id='task-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
          />
        </div>

        <div>
          <FileRequirements fileList={fileList} task={task} />
        </div>

      </div>

      <div className={CSS.content_bottom}>
          <div className={CSS.buttons}>
            <label
              id='task_form_button'
              htmlFor="task-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', CSS.save, {'loading': pending})}
              disabled={pending}
            >
              Save
            </label>

            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-ghost')}
              disabled={pending}
              onClick={e => {
                e.preventDefault();
                if (pending) return;
                return _handleReturn(location.state, history, property_id);
              }}
            >
              Discard changes
            </label>
          </div>
      </div>
    </>
  );
};
