import { merge } from 'lodash';

import * as getBuildings from './getBuildings';
import * as getTenantEvent from './getTenantEvent';
import * as getTenantEventSearch from './getTenantEventSearch';
import * as triggerInvite from './triggerInvite';

export const reducers = [
  getBuildings.reducer,
  getTenantEvent.reducer,
  getTenantEventSearch.reducer,
  triggerInvite.reducer,
];

export const initialState = merge(
  getBuildings.initialState,
  getTenantEvent.initialState,
  getTenantEventSearch.initialState,
  triggerInvite.initialState,
);
