import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { orientation } from '@moved/product';

import { StatusHeader, DashboardLink, RenderAttributes } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const ApprovedContent = ({ request, children }) => {
  const status = {
    icon: { symbol: 'Done', color: 'green' },
    title: 'Approval Confirmation',
    description: `You have approved the ${get(request,'orientation_content_title')} submission for ${format.fullname(request.tenant)}.`,
  };
  const fields = [{
    label: 'Date approved',
    value: format.date(request.responded_at,'dateTime'),
  }];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <RenderAttributes attributes={fields} />
      <RenderAttributes attributes={orientation.getRequestFields(request)} />
      <DashboardLink/>
    </>
  );
};
