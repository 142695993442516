import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_ALLCONNECT_ENABLED',
  request: (id, data={}) => request.post(`/admins/buildings/${id}/settings/allconnect`, data).then(r => get(r, 'data.data.allconnect_enabled')),
};

export const {
  action,
  reducer,
  usePending: useUpdateAllconnectEnabledPending,
} = generate.redux(creator);

export const initialState = {};
