import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_PAY_MOVE_IN_COSTS_INSTRUCTIONS',
  request: (taskId) => request.get(`/admins/building-tasks/${taskId}/pay-move-in-costs-instructions`).then(r => get(r, 'data.data.instruction_content')),
  selector: (state, taskId) => get(state,`payMoveInCostsInstructions.${taskId}`),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      payMoveInCostsInstructions: {
        ...state.payMoveInCostsInstructions,
        [id]: response,
      }
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      payMoveInCostsInstructions: {
        ...state.payMoveInCostsInstructions,
        [id]: null,
      }
    }),
  }
};

export const {
  action,
  reducer,
  useResource: usePayMoveInCostsInstructions,
  usePending: usePayMoveInCostsInstructionsPending,
} = generate.redux(creator);

export const initialState = {
  payMoveInCostsInstructions: {},
};
