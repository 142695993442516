import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CREATE_PAY_MOVE_IN_COSTS_INSTRUCTIONS',
  request: (taskId,data) => request.post(`/admins/building-tasks/${taskId}/pay-move-in-costs-instructions`,data).then(r => get(r, 'data.data.instruction_content')),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      payMoveInCostsInstructions: {
        ...state.payMoveInCostsInstructions,
        [id]: response,
      }
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useCreatePayMoveInCostsInstructionsPending,
} = generate.redux(creator);

export const initialState = {};
