import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_GET_UTILITY_PROVIDERS',
  request: (id) => request.get(`/admins/building-tasks/${id}/utility-providers`).then(r => get(r,'data.data.utility_providers')),
  selector: (state, id) => get(state,`utilityProviders.${id}`),
  reducers: {
    success: (state, { response, params:[id] }) => ({
      ...state,
      utilityProviders: {
        ...state.utilityProviders,
        [id]: response,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useUtilityProviders,
  usePending: useUtilityProvidersPending,
} = generate.redux(creator);

export const initialState = {
  utilityProviders: {},
};
