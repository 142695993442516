import React from 'react';
import classNames from 'classnames';

import { getOrderStatusLabel, getPaymentStatusLabel } from '../helpers';

import CSS from '../styles/OrderStatusPills.module.scss';

export const OrderStatusPills = ({ order }) => (
  <>
    <span className={classNames(CSS.pill, CSS[order.status])}>{getOrderStatusLabel(order.status) || '¯\\(º_o)/¯'}</span>
    { getPaymentStatusLabel(order.payment_status) && (
      <span className={classNames(CSS.pill, CSS[order.payment_status])}>{getPaymentStatusLabel(order.payment_status)}</span>
    )}
  </>
);
