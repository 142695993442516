import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_GET_UTILITY_SETUP_DETAILS',
  request: (id, params={}) => request.get(`/admins/building-tasks/${id}/building-utility-provider-requirements`, { params }).then(r => get(r,'data.data.requirements')),
  selector: (state, id) => { return get(state,`utilityRequirements.${id}`); },
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        utilityRequirements: {
          ...state.utilityRequirements,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useUtilitySetupDetails,
  usePending: useUtilitySetupDetailsPending,
} = generate.redux(creator);

export const initialState = {
  utilityRequirements: {},
};
