import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_GET_RECOMMENDED_CANCELLATION_FEE',
  request: (id) => request.get(`/cx/service-orders/${id}/recommended-cancellation-fee`).then(r => get(r,'data.data.cancellation_fee')),
};

export const {
  action,
  reducer,
  usePending: useRecommendedCancellationFeePending,
} = generate.redux(creator);

export const initialState = {};
