import React from 'react';

import { format } from '@moved/services';
import { insurance } from '@moved/product';

import { StatusHeader, DashboardLink, RenderAttributes } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const DeclinedContent = ({ policy }) => {
  const status = {
    icon: { symbol: 'Error', color: 'red' },
    title: 'Declined Confirmation',
    description: (<>
      <p className='mb-15'>
        You have declined the renters insurance policy for {format.fullname(insurance.getPrimaryPolicyHolder(policy))}.
      </p>
      <p className='italic'>{policy.notes}</p>
    </>),
  };
  const fields = [{
    label: 'Date declined',
    value: format.date(policy.responded_at,'dateTime'),
  }];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(insurance.getPrimaryPolicyHolder(policy))}</span>
      </div>
      <RenderAttributes attributes={fields} />
      <RenderAttributes attributes={insurance.getRequestFields(policy)} />
      <DashboardLink/>
    </>
  );
};
