import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay } from '@moved/ui';
import { useUser } from '@moved/services';

import { updateAllconnectEnabled } from '../actions';
import { getProperty } from '../../../../properties/actions'
import { useProperty } from '../../../../properties/actions/getProperty';

import { DefaultConfiguration } from '../../../components';

export const Configuration = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { property_id } = useParams();
  const [pending, setPending] = useState();

  // REDUX
  const { hasAbilities } = useUser();

  const building = useProperty(property_id);
  const allconnectEnabled = get(building,'settings.allconnect_enabled');

  const _handleSubmit = data => {
    if(!hasAbilities('ConfigureCableInternetTasks')) return false;
    setPending(true);
    return dispatch(updateAllconnectEnabled(property_id, data))
      .then(() => dispatch(getProperty(property_id))) // refresh property since settings were updated
      .finally(() => setPending(false));
  };

  const fields = [
    {
      label: 'Show Allconnect integration',
      type: 'toggle',
      name: 'value',
      value: allconnectEnabled,
      disabled: !hasAbilities('ConfigureCableInternetTasks'),
    }
  ];

  return (
    <>
      { pending && <LoaderOverlay /> }
      <DefaultConfiguration
        task={task}
        typeFields={fields}
        typeSubmit={_handleSubmit}
        typePending={pending}
      />
    </>
  );
};
