import React from 'react';
import { get } from 'lodash';

import CSS from './Preview.module.scss';
import overlay from './desktop-image-preview-overlay.png';

const Preview = ({ brandable, newUrl }) => {
  const preview = newUrl || get(brandable,'dashboard_image_url');
  return (
    <div className={CSS.dashboard_image_preview}>
      <div className={CSS['aspect-ratio']} style={{ backgroundImage: `url(${preview})`}} />
      <div className={CSS.overlay} style={{ backgroundImage: `url(${overlay})`}} />
    </div>
  );
};

export const dashboardImage = {
  key: 'dashboard-image',
  originalKey: 'dashboard_image_original_url',
  finalKey: 'dashboard_image_url',
  allowCropping: true,
  croppingAspectRatio: 16/9, // width/height ratio as a number
  requirements: {
    types: { // list all acceptable file types
      'image/jpg': ['.jpg', '.jpeg']
    },
    dimensions: {
      minWidth: 1200, // in pixels
      minHeight: 600, // in pixels
      maxWidth: 1600, // in pixels
    },
  },
  Preview: Preview,
};
