import React from 'react';

import { format } from '@moved/services';
import { FieldList } from '@moved/ui';

import { StatusHeader, DashboardLink } from '../../shared/components';
import CSS from '../../shared/styles/shared.module.scss';

export const ExpiredContent = ({ request }) => {
  const status = {
    icon: { symbol: 'Error', color: 'red' },
    title: 'Pay Move-in Costs Canceled',
    description: `This request to review payment for ${format.fullname(request.tenant)} has been canceled.`,
  };
  const fields = [
    {
      label: 'Date of cancellation',
      value: format.date(request.cancelled_at,'dateTime'),
    }
  ];

  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <FieldList list={fields} />
      <DashboardLink/>
    </>
  );
};
