import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'MOVES_GET_MULTIPLE_PARTNER_COUPONS',
  // This request actually performs N parallel requests depending on the length of the array passed in
  request: (ids) => Promise.all(ids.map(id => request.get(`/cx/partners/${id}/coupons`).then(r => get(r,'data.data.coupons')))),

  // This selector combines the results for each id from store into a single array
  // and is memoized since every call returns a new array instance
  memoized: [
    (state, ids) => state.partnerCoupons, // input 1
    (state, ids) => ids, // input 2
  ],
  selector: (coupons, ids) => ids.reduce((list,id) => ([...list,...get(coupons,id,[])]),[]),

  // This reducer takes an array of responses, and updates state one time with all results
  reducers: {
    success: (state, { response: results, params: [ids] }) => ({
      ...state,
      partnerCoupons: {
        ...state.partnerCoupons,
        ...results.reduce((list,result,index) => ({
          ...list,
          [ids[index]]: result,
        }),{}),
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMultiplePartnerCoupons,
  usePending: useMultiplePartnerCouponsPending,
} = generate.redux(creator);

export const initialState = {
  partnerCoupons: {},
};
