import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

export const TableGenerator = ({
  columns,
  rows,
  options,
}) => {
  const tableOptions = {
    showActions: !!rows.find(row => row.onClick),
    ...options,
  };
  return (
    <>
      { columns && !isNil(columns.find(({label}) => label)) && (
        <TableHeader columns={columns} options={tableOptions} />
      )}
      { rows.length > 0 && columns && (
        rows.map((row,index) => (
          <TableRow
            key={row.key || index}
            columns={columns.map(({ getValue, value, ...column }) => ({
              ...column,
              value: getValue ? getValue(row.data) : value,
            }))}
            onClick={row.onClick}
            className={row.className}
            options={tableOptions}
          />
        ))
      )}
    </>
  );
};

TableGenerator.propTypes = {
  /** Array of columns to render */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** Unique key name for this column */
    name: PropTypes.string,
    /** Label to use in the header row for this column */
    label: PropTypes.node,
    /** TODO: Flag to enable sorting for this column */
    sortable: PropTypes.bool,
    /** (optional) Function to get the dynamic value for this column from a single row's data */
    getValue: PropTypes.func,
    /** (optional) Static display value for this column (overridden by getValue if defined) */
    value: PropTypes.node,
    /** (optional) class name to add to all column cells */
    className: PropTypes.string,
  })).isRequired,
  /** Data representing one row, can be any shape, will be passed to column.getValue(row) for each column */
  rows: PropTypes.array.isRequired,
};
