import React from 'react';
import classNames from 'classnames';

import { DynamicForm, AtomSpinner, Icon } from '@moved/ui';
import { useModal } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../../common';
import { useOrientationContents, useOrientationContentsPending } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const ChooseChapters = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {

  // REDUX
  const chapters = useOrientationContents(id);
  const pending  = useOrientationContentsPending();
  const updatePending = false;

  const Modal = useModal();

  const _showPreviewModal = (e, content) => {
    e.stopPropagation();
    Modal.open(
      <div className={CSS.editor_preview}>
       <AdminContentRenderer content={content} />
      </div>
    );
  };

  const onSubmit = data => {
    setDataCollection({ ...dataCollection, ...data, building_ids: [] });
    return setProgress(progress+1);
  }

  const fields = [
    {
      type: 'itemList',
      name: 'content_editor_content_ids',
      required: 'You must select at least one chapter.',
      isMulti: true,
      isNumeric: true,
      customItemClass: CSS.item,
      value: dataCollection.content_editor_content_ids,
      options: chapters.map(chapter => {
        return {
          label: (
            <div className={CSS.label}>
              <div className={CSS.grow}>
                <div>{chapter.title}</div>
                <div className={CSS.chapter_actions}>
                  <div className={CSS.preview} onClick={e => _showPreviewModal(e, chapter.content)}>Preview content</div>
                </div>
              </div>
            </div>
          ),
          value: chapter.id,
        };
      }),
    },
  ];

  if(pending) return <AtomSpinner/>;

  return (
      <>

        <h3 className={CSS.title}><span>{progress}/{length}</span> Select chapters</h3>

        <div className={CSS.content}>
          <DynamicForm
            id='chapter-form'
            formStyle='underline'
            fields={fields}
            onSubmit={onSubmit}
          />

        </div>

        <div className={CSS.actions}>
          <div>
          </div>
          <div className={CSS.actions_right}>
            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-gray')}
              disabled={updatePending}
              onClick={e => {
                e.preventDefault();
                if (updatePending) return;
                return Modal.close();
              }}
            >
              Cancel
            </label>

            <label
              id='chapter_form_button'
              htmlFor="chapter-form-submit"
              tabIndex="0"
              role="button"
              className={classNames(CSS.next, {'loading': updatePending})}
              disabled={updatePending}
            >
              <span>Next</span>
              <Icon symbol={'Arrow-right'} library={'navigation'} color={'white'} />
            </label>
          </div>
        </div>

      </>
  );
};
