import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import { Icon } from '../../../sondheim/components/Icon';
import CSS from './TabSlider.module.scss';


const moveBar = (active, bar) => {
  if(active.current) {
    bar.current.style.left = active.current.offsetLeft + 'px';
    bar.current.style.width = active.current.offsetWidth + 'px';
  } else {
    bar.current.style.left = '0';
    bar.current.style.width = '0';
  }
};

const Tab = ({ content, active, activeRef, callback }) => {

  const activeTab = active === content.value;

  return (
    <li
      onClick={e => { e.preventDefault(); callback(content.value) }}
      ref={activeTab ? activeRef : null}
      className={classNames(
        CSS.tab,
        {
          [CSS.tab_active] : activeTab
        },
      )}
    >
      {content.icon && content.icon.symbol && (<Icon size='14px' symbol={content.icon.symbol} library={content.icon.library} className={CSS.icon} />)}
      <span>{content.label}</span>
    </li>
  );
};

export const TabSlider = ({list, active, callback, spacer, className}) => {

  const activeRef = useRef();
  const barRef = useRef();

  useEffect(() => {
    moveBar(activeRef, barRef);
  });

  return (
    <ul className={classNames(CSS.tabs, className)}>
      {
        list.map((tab,index) => ([
          <Tab key={tab.value} content={tab} active={active} activeRef={activeRef} callback={callback} />,
          index !== list.length-1 && (<div key={`${tab.value}-spacer`} className={CSS.spacer}>{ spacer }</div>),
        ].filter(v=>v)))
      }
      <span className={CSS.bar} ref={barRef} />
    </ul>

  );
};
