import { generate, request } from '@moved/services';
import { get, cloneDeep, remove } from 'lodash';

const creator = {
  namespace: 'ADMIN_ADMINS_REMOVE_ADMIN_BUILDING',
  request: (id,admin_id) => request.delete(`/admins/building-admins/${id}`).then(r => get(r,'data.data.admin')),
  reducers: {
    success: (state, { response: admin, params: [id, admin_id] }) => {

      const updatedAdmin = cloneDeep(get(state,`admins[${admin_id}]`));

      const updatedBuildings = updatedAdmin.building_admins;

      remove(updatedBuildings, building => building.id === id);

      updatedAdmin.building_admins = updatedBuildings;

      return {
        ...state,
        admins: {
          ...state.admins,
          [admin_id]: updatedAdmin,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  usePending: useRemoveAdminBuildingPending,
} = generate.redux(creator);

export const initialState = {};
