import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useNotify, useUser } from '@moved/services';
import { LayoutPromo, Four04 } from '@moved/product';

import { getClient } from '../actions';

export const ClientAdmin = ({ children }) => {
  const { getDomainId } = useUser();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const clientId = getDomainId('client');
  const [initialized, setInitialized] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    // refresh the client data if possible
    if(clientId) dispatch(getClient(clientId))
      .then(() => setInitialized(true))
      .catch((error) => {
        setFailure(true);
        if(get(error,'response.status') === 404) {
          Notify.default(`Uh oh. We don't recognize this client.`);
        }
        setInitialized(true);
      });
    // if no client can be determined
    else setInitialized(true);
  },[clientId]); // eslint-disable-line

  return (
    initialized && (
      failure ? (<LayoutPromo><Four04 /></LayoutPromo>) : children
    )
  );

};

export const withClientAdmin = (Wrapped) => (props) => (<ClientAdmin><Wrapped {...props}/></ClientAdmin>);
