import React from 'react';
import { get } from 'lodash';

import { ClipboardText } from '@moved/ui';

import { SectionDisplay } from './SectionDisplay';
import { SendJobOffer } from '../buttons';
import { VendorOfferHistory } from './VendorOfferHistory';

export const VendorSection = ({ order }) => {

  const details = {
    title: 'Vendor',
    actions: [
      (<SendJobOffer order={order} key='send-offer'/>),
    ].filter(v => v),
    columns: [
      [
        {
          label: 'Vendor name',
          value: get(order, 'vendor.name') || 'Pending',
        },
        {
          label: 'Arrival window',
          value: <>{get(order,'earliest_arrival_time')} - {get(order,'latest_arrival_time')}</>,
        },
        {
          label: 'Job ID',
          value: <ClipboardText text={get(order, 'order_number')} />,
        },
      ].filter(v => v),
      [
        {
          label: 'Status',
          value: <VendorOfferHistory offers={get(order, 'vendor_job_offers')} />,
        },
      ].filter(v => v),
    ],
  };

  return (
    <SectionDisplay section={details}/>
  );

};
