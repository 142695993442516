import React from 'react';
import moment from 'moment';

import weekCSS from '../../styles/WeeklyCalendar.module.scss';

export const AvailabilityKey = () => {

  // 24 hours til midnight (utc to avoid DST shenanigans)
  const midnight = moment.utc().set({hour:0,minute:0,second:0,millisecond:0});
  const hour = midnight.clone().add(1, 'hours');
  midnight.add(1,'days');

  const hours = [];

  while(hour < midnight) {
    hours.push(hour.format('h A'));
    hour.add(1, 'hours');
  };

  return (
    <>
      <div className={weekCSS.key_column}>
        <div className={weekCSS.key_header} />

        <div className={weekCSS.key_midnight}>
          <label className={weekCSS.key_times}>12 AM</label>
        </div>

        { hours.map(hour => (
          <div key={`${hour}`} className={weekCSS.key_slot}>
            <div className={weekCSS.key_slot_content}>
              <label className={weekCSS.key_times}>{hour}</label>
            </div>
          </div>
        ))}
      </div>
      <div className={weekCSS.key_background}>
        { hours.map(hour => (
          <div key={hour} className={weekCSS.key_grid}>
            <div className={weekCSS.key_grid_line} />
          </div>
        ))}
      </div>
    </>
  );
}
