import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { TabSlider, Icon } from '@moved/ui';

import { MoveStepDetails } from './MoveStepDetails';
import { useMoveDetails } from '../actions/selectors';

import CSS from '../styles/MoveSteps.module.scss';


export const MoveSteps = () => {
  // HOOKS
  const history = useHistory();
  const location = useLocation();
  const { moveId, stepId, view } = useParams();
  const { move_steps: moveSteps } = useMoveDetails(moveId);
  const activeMoveStep = moveSteps.find(step => step.id === parseInt(stepId));
  const stepTypeOrder = ['move-out','move-in'];
  const stepSort = (a,b) => stepTypeOrder.indexOf(a.type) - stepTypeOrder.indexOf(b.type);
  const navList = moveSteps.sort(stepSort).map(step => ({
    label: `${step.display_name} - ${get(step,'building.name')}`,
    value: step.id,
  }));

  return (
    <>

      <TabSlider
        list={navList}
        active={activeMoveStep.id}
        callback={selected => history.push({
          pathname: `${view?'/'+view:''}/moves/${moveId}/steps/${selected}`,
          search: location.search,
        })}
        spacer={(
          <Icon library='navigation' symbol={'Arrow-right'} className={CSS.spacer}/>
        )}
      />

      { activeMoveStep && (
        <MoveStepDetails moveStep={activeMoveStep} />
      )}

    </>
  );
};
