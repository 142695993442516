import { merge } from 'lodash';

import * as requestAction from './requestAction';
import * as getApprovals from './getApprovals';
import * as getTaskTitles from './getTaskTitles';

export const reducers = [
  requestAction.reducer,
  getApprovals.reducer,
  getTaskTitles.reducer,
];

export const initialState = merge(
  requestAction.initialState,
  getApprovals.initialState,
  getTaskTitles.initialState,
);
