import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_CALENDAR_SUMMARIES',
  request: (building_id) =>
    request.get(`/partners/buildings/${building_id}/building-calendar-summaries`).then(r => r.data.data.building_calendars),
  selector: (state, building_id) => {
    if(!building_id) return [];
    return (get(state,`calendarSummaries[${building_id}]`) || []);
  },
  reducers: {
    success: (state, { response, params: [building_id] }) => {
      return {
        ...state,
        calendarSummaries: {
          ...state.calendarSummaries,
          [building_id]: response,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useCalendarSummaries,
  usePending: useCalendarSummariesPending,
} = generate.redux(creator);

export const initialState = {
  calendarSummaries: {},
};
