import { generate, request } from '@moved/services';
import { get, remove } from 'lodash';

const creator = {
  namespace: 'CALENDAR_CANCEL_RESERVATION',
  request: (type, id, calendar_id) =>
    request.post(`/partners/${type}/${id}/cancel`).then(r => r.data.data.appointment),
  reducers: {
    success: (state, { response: reservation, params: [type, id, calendar_id] }) => {

      let appointments = [...get(state,`partnerCalendar.${calendar_id}.appointments`)];
      remove(appointments,appt => appt.id === id);

      return {
        ...state,
        partnerCalendar: {
          ...state.partnerCalendar,
          [calendar_id]: {
            ...state.partnerCalendar[calendar_id],
            appointments,
          },
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCancelReservationPending,
} = generate.redux(creator);

export const initialState = {};
