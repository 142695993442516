import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { useUser } from '@moved/services';

import { MenuItem } from './MenuItem';
import CSS from './NavigationMenu.module.scss';

const moveBar = (active, bar) => {
  if(active.current) {
    bar.current.style.top = active.current.offsetTop + 'px';
    bar.current.style.height = active.current.offsetHeight + 'px';
  } else {
    bar.current.style.top = '0';
    bar.current.style.height = '0';
  }
};

export const NavigationMenu = ({ sections=[], className, isCollapsed }) => {
  const { Can } = useUser();

  const location = useLocation();
  const activeRef = useRef();
  const barRef = useRef();

  useEffect(() => {
    if(activeRef.current && barRef.current) moveBar(activeRef, barRef);
  },[location]);

  if(sections.length === 0) return null;
  return (
    <ul className={classNames(CSS.menu, className)}>
      <span className={CSS.bar} ref={barRef} />
      {sections.map(section => (
        <div key={sections.length > 1 ? section.label: 'section'}>
          {section.label && (
            <div className={CSS.section_label}>
              {section.label}
            </div>
          )}
          {section.items.map(item => (
            <Can I={item.ability} key={item.urlPath}>
              <MenuItem
                {...item}
                isCollapsed={isCollapsed}
                activeRef={activeRef}
              />
            </Can>
          ))}
        </div>
      ))}
    </ul>
  );
};
