import { merge } from 'lodash';

import * as getKeyPickupAppointmentRequest from './getKeyPickupAppointmentRequest';
import * as reviewKeyPickupAppointmentRequest from './reviewKeyPickupAppointmentRequest';

export const initialState = merge(
  getKeyPickupAppointmentRequest.initialState,
  reviewKeyPickupAppointmentRequest.initialState,
);

export const reducers = [
  getKeyPickupAppointmentRequest.reducer,
  reviewKeyPickupAppointmentRequest.reducer,
];
