import approvalsRoutes from './approvals/routes';
import calendarRoutes from './calendar/routes';
import movesRoutes from './moves/routes';

const routes = [
  ...approvalsRoutes,
  ...calendarRoutes,
  ...movesRoutes,
];

export default routes;
