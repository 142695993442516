import { merge } from 'lodash';

import * as getOrder from './getOrder';
import * as cancelOrder from './cancelOrder';
import * as getRecommendedCancellationFee from './getRecommendedCancellationFee';
import * as sendJobOffer from './sendJobOffer';
import * as getVendorList from './getVendorList';
import * as addPaymentMethod from './addPaymentMethod';
import * as resolvePaymentIssue from './resolvePaymentIssue';
import * as retryPaymentAuthorization from './retryPaymentAuthorization';
import * as addCoupon from './addCoupon';
import * as reschedule from './reschedule';
import * as updateRate from './updateRate';
import * as updateOrderDetails from './updateOrderDetails';

export const initialState = merge(
  getOrder.initialState,
  cancelOrder.initialState,
  getRecommendedCancellationFee.initialState,
  sendJobOffer.initialState,
  getVendorList.initialState,
  addPaymentMethod.initialState,
  resolvePaymentIssue.initialState,
  retryPaymentAuthorization.initialState,
  addCoupon.initialState,
  reschedule.initialState,
  updateRate.initialState,
  updateOrderDetails.initialState,
);

export const reducers = [
  getOrder.reducer,
  cancelOrder.reducer,
  getRecommendedCancellationFee.reducer,
  sendJobOffer.reducer,
  getVendorList.reducer,
  addPaymentMethod.reducer,
  resolvePaymentIssue.reducer,
  retryPaymentAuthorization.reducer,
  addCoupon.reducer,
  reschedule.reducer,
  updateRate.reducer,
  updateOrderDetails.reducer,
];
