import { get, isNil } from 'lodash';

import { request } from './request';
import { redux } from '../utils/generators/redux';

export const { action: logoutAction, reducer: logoutReducer } = redux({
  namespace: 'GLOBAL_LOGOUT',
  request: () => request.post(`/users/logout`,undefined,{ withCredentials: true }),
  reducers: {
    success: (state) => {
      window.localStorage.removeItem('state');
      return undefined; // reset state
    },
  }
});

// User service functions
export const userService = {

  /* user specific API endpoints */
  login: (data) => request.post(`/users/login`,data,{ withCredentials: true }).then(r => get(r,'data.data')),
  refreshToken: () => request.post('/users/token/refresh',undefined,{ withCredentials: true, allowExpired: true }).then(r => get(r,'data.data')),

  getUser: () => request.get(`/users/current`).then(r => get(r,'data.data.user')),
  getActiveMove: () => request.get(`/moves/active-move-id`).then(r => get(r,'data.data.active-move-id')),

  setPassword: (data) => request.post(`/users/password`, data).then(r => get(r,'data.data')),

  proxyStart: (userId) => request.post(`/users/${userId}/proxy_as`,undefined,{ withCredentials: true }).then(r => get(r,'data.data')),
  proxyEnd: () => request.post('/users/end_proxy',undefined,{ withCredentials: true }).then(r => get(r,'data.data')),

  /* user convenience functions */
  getDomain: (user, domainName) =>  get(user,'domains',[]).find(domain => domain.name === domainName),

  hasDomain: (user, domainName) => {
    // special case for customer routing (user with no domains)
    if(domainName === 'customer' && get(user,'created_at') && get(user,'domains.length') === 0) return true;
    return userService.getDomain(user, domainName) ? true : false;
  },

  getPrimaryDomain: user => {
    // special case for customer routing (no domains)
    if(get(user,'domains.length') === 0 && get(user,'created_at')) return 'customer';
    return get(user,'domains') ? get(user,'domains[0].name') : false;
  },

  // ability schema evaluator, all of the following examples are valid structures:
  // 'XYZ': by default if the param is a string, we just ensure that string exists in the abilities array
  // ['XYZ','YZX','ZXY']: arrays will be treated as `allOf` automatically, support nested schemas
  // { anyOf: ['XYZ', { allOf: ['YZX','ZXY'] }]}, not: 'ABC' }: objects support keywords `allOf`,`anyOf`,`not`, support nested schemas
  hasAbilities: (user, abilities) => {
    // if no abilities are specified, then user has all of them
    if(isNil(abilities)) return true;
    // directly check a string value
    if(typeof abilities === 'string') return userService.hasAbility(user,abilities);
    // assume a 'naked' array is `allOf` by default, wrap it as such and recurse the call
    if(Array.isArray(abilities)) return userService.hasAbilities(user, { allOf: abilities });
    // handle the keywords
    if(typeof abilities === 'object') { // must be after array check since array is also considered an object
      return Object.keys(abilities).every(keyword => {
        switch(keyword) {
          case 'anyOf':
            if(!Array.isArray(abilities[keyword])) throw new Error('Invalid [anyOf] ability. Must be an array')
            return abilities[keyword].some(ability => userService.hasAbilities(user,ability));
          case 'allOf':
            if(!Array.isArray(abilities[keyword])) throw new Error('Invalid [allOf] ability. Must be an array')
            return abilities[keyword].every(ability => userService.hasAbilities(user,ability));
          case 'not':
            return !userService.hasAbilities(user, abilities[keyword]);
          default:
            throw new Error('Invalid ability comparison keyword. Only [anyOf, allOf, not] are allowed.')
        }
      })
    }
  },

  // convenience function that just checks for one ability in the abilities list
  hasAbility: (user, ability) => get(user,'abilities',[]).some(userAbility => userAbility === ability),

  redirect: async (user, history) => {
    // if current state has a redirect, we must be returning to existing url prior to login event
    if(get(history,'location.state.redirect')) return history.push(get(history,'location.state.redirect'));
    const currentAppUrl = process.env.REACT_APP_BASE_URL;
    const redirectUrl = await userService.getRedirectUrl(user);
    // improvement to use internal routing instead of full page reload if staying within the existing app
    if(history && redirectUrl.startsWith(currentAppUrl)) history.push(
      redirectUrl.replace(currentAppUrl,'') || '/',
      userService.hasDomain(user,'guest') && { redirect:history.location } // if redirecting a guest, store current url for later return
    );
    else window.top.location = redirectUrl;
  },

  getRedirectUrl: async (user) => {
    // redirect mapping to proper domain based destination
    switch(userService.getPrimaryDomain(user)) {
      case 'guest':
        return `${process.env.REACT_APP_CONSUMER_APP_URL}/signin`;
      case 'client':
        return `${process.env.REACT_APP_ADMIN_APP_URL}/admin/moves?filter=upcoming`;
      case 'vendor':
        return `${process.env.REACT_APP_VENDOR_APP_URL}/jobs`;
      case 'cx':
        return userService.hasAbility(user,'ReadMoves')
          ? `${process.env.REACT_APP_ADMIN_APP_URL}/cx/moves`
          : userService.hasAbility(user,'ReadVendors')
            ?`${process.env.REACT_APP_MOVED_APP_URL}/admin/vendors`
            : `${process.env.REACT_APP_ADMIN_APP_URL}/cx`;
      case 'abode':
        return `${process.env.REACT_APP_ADMIN_APP_URL}/admin/moves`;
      case 'customer':
        return userService.getActiveMove()
          .then(move => userService.getMoveUrl(move))
          .catch(() => `${process.env.REACT_APP_CONSUMER_APP_URL}/moves`);
      default:
        return process.env.REACT_APP_CONSUMER_APP_URL;
    }
  },

  getMoveUrl: (move) => {
    if(!move) return process.env.REACT_APP_CONSUMER_APP_URL;
    switch(move.app) {
      case 'storage':
      case 'resident':
      case 'consumer':
        return `${process.env.REACT_APP_CONSUMER_APP_URL}/moves/${move.move_id}`;
      case 'direct':
      default:
        return process.env.REACT_APP_MOVED_APP_URL;
    }
  },

  getDomainBasedGreeting: (user) => {
    const timeGreeting = userService.getTimeBasedGreeting(user);
    if(userService.hasDomain(user,'client')) return `${timeGreeting}`;
    if(userService.hasDomain(user,'vendor')) return `${timeGreeting} Let's book more jobs!`;
    if(userService.hasDomain(user,'cx')) return `${timeGreeting} Let's win more moves!`;
    if(userService.hasDomain(user,'customer')) return `${timeGreeting} Let's get you moved!`;
    return timeGreeting;
  },

  getTimeBasedGreeting: (user) => {
    if (!user || userService.hasDomain(user,'guest')) return 'Hello.';
    const hour = (new Date()).getHours();
    if(hour < 2 || hour >= 22) return `Up late, ${user.firstname}?`;
    if(hour < 6) return `Early morning, ${user.firstname}?`;
    if(hour < 10) return `Good morning, ${user.firstname}!`;
    if(hour < 14) return `Hello ${user.firstname}!`;
    if(hour < 18) return `Good afternoon, ${user.firstname}!`;
    if(hour < 22) return `Good evening, ${user.firstname}!`;
    return `Hello ${user.firstname}!`;
  },

};
