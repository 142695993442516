import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_UTILITY_SETUP_REQUEST',
  request: (token) => request.get(`/partners/utility-setup-requests/token/${token}`).then(r => r.data.data.utility_setup_request),
  selector: (state, token) => get(state, `utilityRequests.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        utilityRequests: {
          ...state.utilityRequests,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useUtilitySetupRequest,
  usePending: useUtilitySetupRequestPending,
} = generate.redux(creator);

export const initialState = {
  utilityRequests: {},
};
