import { merge } from 'lodash';

import * as createPetscreeningVerificationInstructions from './createPetscreeningVerificationInstructions';
import * as getPetscreeningVerificationInstructions from './getPetscreeningVerificationInstructions';
import * as getFileUploadUrl from './getFileUploadUrl';
import * as updatePetscreeningVerificationInstructions from './updatePetscreeningVerificationInstructions';

export const reducers = [
  createPetscreeningVerificationInstructions.reducer,
  getPetscreeningVerificationInstructions.reducer,
  getFileUploadUrl.reducer,
  updatePetscreeningVerificationInstructions.reducer,
];

export const initialState = merge(
  createPetscreeningVerificationInstructions.initialState,
  getPetscreeningVerificationInstructions.initialState,
  updatePetscreeningVerificationInstructions.initialState,
);
