import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_GET_ORDER',
  request: (id) => request.get(`/cx/service-orders/${id}`).then(r => get(r,'data.data.service_order')),
  selector: (state, id) => {
    if(!id) return;
    return get(state,`orders.${id}`);
  },
  reducers: {
    success: (state, { response: order, params: [id] }) => ({
      ...state,
      orders: {
        ...state.orders,
        [id]: order,
      }
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useOrder,
  usePending: useOrderPending,
} = generate.redux(creator);

export const initialState = {
  orders: {},
};
