import { Authentication } from './components/Authentication';
import { withPartner } from '../common/components/Partner';

const routes = [
  {
    path: '/signin/:partnerSlug',
    name: 'Authentication',
    component: withPartner(Authentication),
    exact: true,
    restrict: ['guest'],
  },
  {
    path: '/signin',
    name: 'Authentication',
    component: Authentication,
    exact: true,
    restrict: ['guest'],
  },
];

export default routes;
