import { merge } from 'lodash';

import * as approvals from './approvals/actions/reducer';
import * as calendar from './calendar/actions/reducer';
import * as moves from './moves/actions/reducer';

export const reducers = [
  ...approvals.reducers,
  ...calendar.reducers,
  ...moves.reducers,
];

export const initialState = merge(
  approvals.initialState,
  calendar.initialState,
  moves.initialState,
);
