import {
  FeedSyncDetails,
  PropertiesSearch,
  PropertyTabRedirect,
  PropertyDetails,
  PropertySettings,
  PropertyActions,
  PropertyTasks,
  PropertyWrapper,
  PropertyDetailsBreadcrumb,
  PropertyDetailsTooltip,
  PropertyIntegration,
  PropertyIntegrationSummary,
} from './';

import tasksRoutes from '../tasks/routes';

const routes = [
  {
    path: '/properties',
    name: 'Properties',
    breadcrumb: {
      label:"Properties",
      tooltip:  "Properties",
      icon: {
        symbol: 'Building',
        library: 'home',
      },
    },
    children: [
      {
        path: '/',
        name: 'PropertiesSearch',
        component: PropertiesSearch,
        exact: true,
      },
      {
        path: '/:property_id(\\d+)',
        name: 'PropertyWrapper',
        component: PropertyWrapper,
        breadcrumb: {
          label: PropertyDetailsBreadcrumb,
          tooltip:  PropertyDetailsTooltip,
        },
        children: [
          ...tasksRoutes,
          {
            path: '/syncs/:feedSyncId(\\d+)',
            name: 'PropertyPMSIntegrationFeedSyncDetails',
            component: FeedSyncDetails,
            breadcrumb: { label: 'Sync details' },
          },
          {
            path: '/',
            name: 'PropertyDetails',
            component: PropertyDetails,
            children: [
              {
                path: '/',
                name: 'PropertyTabRedirect',
                component: PropertyTabRedirect,
                exact: true,
              },
              {
                path: '/:activeTab(task-lists)/:taskListID(\\d+)',
                name: 'PropertyTaskList',
                component: PropertyTasks,
              },
              {
                path: '/:activeTab(settings)',
                name: 'PropertySettings',
                component: PropertySettings,
              },
              {
                path: '/:activeTab(actions)',
                name: 'PropertyActions',
                component: PropertyActions,
              },
              {
                path: '/:activeTab(integrations)',
                name: 'PropertyPMSIntegrations',
                children: [
                  {
                    path: '/:activeIntegrationType?',
                    name: 'PropertyPMSIntegration',
                    component: PropertyIntegration,
                    children: [
                      {
                        path: '/',
                        name: 'PropertyPMSIntegrationSummary',
                        component: PropertyIntegrationSummary,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
