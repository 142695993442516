import React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';

import { format, useModal, useNotify, useUser } from '@moved/services';
import { Button, LoaderOverlay } from '@moved/ui';

import { requestAction } from '../actions/';
import { useRequestActionPending, } from '../actions/selectors';
import { DeclineReasonModal } from './';

const abilityMap = {
  'badge-upload-requests': 'BadgeUploadRequests',
  'building-reservation-requests': 'BuildingReservationRequests',
  'coi-uploads': 'CoiUploads',
  'document-submission-requests': 'DocumentSubmissionRequests',
  'key-pickup-appointment-requests': 'KeyPickupAppointmentRequests',
  'orientation-requests': 'UserOrientationRequests',
  'pay-move-in-costs-requests': 'PayMoveInCostsRequests',
  'pet-registration-requests': 'PetRegistrationRequests',
  'move-out-inspection': 'BuildingReservationRequests',
  'renters-insurance-policy-requests': 'RentersInsurancePolicyRequests',
  'utility-setup-requests': 'UtilitySetupRequests',
  'vehicle-registration-requests': 'VehicleRegistrationRequests',
}

export const ApprovalActions = ({ requestId, requestType, onSubmit=noop }) => {
  // HOOKS
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const { Can } = useUser();

  const pending = useRequestActionPending();

  const approveRequest = () => dispatch(requestAction(requestType, requestId, 'approve'))
    .then(onSubmit)
    .catch(err => Notify.error(format.error(err)));

  const rejectRequest = (data) => dispatch(requestAction(requestType, requestId, 'reject', data))
    .then(onSubmit)
    .catch(err => Notify.error(format.error(err)));

  const rejectConfirmationModal = () => {
    Modal.open(<DeclineReasonModal submit={(data) => rejectRequest(data)} usePending={useRequestActionPending} />);
  };

  return (
    <>
      { pending && <LoaderOverlay />}
      <Can I={`Reject${abilityMap[requestType]}`}>
        <Button
          color='tertiary'
          showWarning={true}
          text='Decline'
          disabled={pending}
          onClick={rejectConfirmationModal}
        />
      </Can>
      <Can I={`Approve${abilityMap[requestType]}`}>
        <Button
          text='Approve'
          disabled={pending}
          onClick={approveRequest}
        />
      </Can>
    </>
  );
};
