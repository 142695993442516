import React from 'react';
import { merge } from 'lodash';

import { ContentRenderer } from '@moved/ui';
import { tagContextBuilder } from '@moved/product';

import { defaultBuilding, defaultLease, defaultTenant } from './AdminTagRenderer';

export const AdminContentRenderer = ({
  building=defaultBuilding,
  lease=defaultLease,
  tenant=defaultTenant,
  content,
  tags={},
  className,
}) => {
  const adminTags = tagContextBuilder({
    building,
    lease,
    tenant,
  })

  return <ContentRenderer content={content} className={className} tags={merge({},adminTags,tags)}/>;
};
