import { merge } from 'lodash';

import * as getOrientationRequest from './getOrientationRequest';
import * as reviewOrientationRequest from './reviewOrientationRequest';

export const initialState = merge(
  getOrientationRequest.initialState,
  reviewOrientationRequest.initialState,
);

export const reducers = [
  getOrientationRequest.reducer,
  reviewOrientationRequest.reducer,
];
