import React from 'react';

import { Icon } from '../../../../sondheim/components/Icon';
import CSS from './styles/FilterSingleSelect.module.scss';

export const FilterSingleSelect = ({ list, title = 'Select', setVisible, active, setActive }) => {
  return (
    <>
      <div className={CSS.options_section}>
        <ul className={CSS.options}>
          {list.map((item, idx) => {
            const isActive = active === item.id;

            return (
              <li className={CSS.option} key={item.id} >

                <input
                  className={CSS.check_input}
                  checked={isActive}
                  id={`check_${item.id}`}
                  value={item.id}
                  type="checkbox"
                  onChange={e => setActive(isActive ? undefined : item.id)}
                />

                <label htmlFor={`check_${item.id}`}>
                  <Icon className={CSS.on} symbol={'Radio-on'} library={'code'} size={'24px'} />
                  <Icon className={CSS.off} symbol={'Radio-off'} library={'code'} size={'24px'} />
                  <span>{item.name}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
