import React from 'react';

import { useModal } from '@moved/services';
import { ModalTitle, ModalContent, ModalActions, Button } from '@moved/ui';

import { AdminContentRenderer } from '../../../../../../common';

import CSS from './styles/ChapterPreviewModal.module.scss';

export const ChapterPreviewModal = ({ chapter, title }) => {
  const Modal = useModal();
  return (
    <>
      <ModalTitle>{ title }</ModalTitle>

      <ModalContent className={CSS.content}>
        <div className={CSS.sizer}>
          <div className={CSS.container}>
            <AdminContentRenderer content={chapter.content} />
          </div>
          {chapter.side_column_image_url && (
            <div className={CSS.side_column} style={{ backgroundImage: `url(${chapter.side_column_image_url})`}} />
          )}
        </div>
      </ModalContent>

      <ModalActions>
        <Button
          text='Close'
          color='primary'
          onClick={() => Modal.close()}
        />
      </ModalActions>
    </>
  );

};
