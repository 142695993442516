import React from 'react';

import { AtomSpinner } from '@moved/ui';

import CSS from './styles/Statistic.module.scss';

export const Statistic = ({ label, value, description }) => (
  <div className={CSS.container}>
    <label className='labelM contentPrimary mb-8'>{ label }</label>
    <div className='displayL contentPrimary'>
      { value || <AtomSpinner size={32} className={CSS.spinner} /> }
    </div>
    { description && (
      <div className={'labelS contentSecondary'}>{ description }</div>
    )}
  </div>
);
