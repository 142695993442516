import { generate, request } from '@moved/services';
import { get, filter } from 'lodash';

const creator = {
  namespace: 'ADMIN_REMOVE_ORIENTATION_CHAPTER',
  request: (id, taskID, data={}) => request.delete(`/admins/content-editor-contents/${id}`, data).then(r => get(r,'data.data')),
  reducers: {
    success: (state, { response, params: [id, taskID] }) => {
      const updatedBook = filter([...state.orientationContents[taskID]], chapter => id !== chapter.id);

      return {
        ...state,
        orientationContents: {
          ...state.orientationContents,
          [taskID]: updatedBook,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useRemoveOrientationChapterPending,
} = generate.redux(creator);

export const initialState = {};
