import React from 'react';

import { useModal, useUser } from '@moved/services';

import { Action } from './Action';
import { SendJobOfferModal } from '../modals/SendJobOfferModal';
import { isOrderOfferable, hasAcceptedVendor } from '../../helpers';

export const SendJobOffer = ({ order, ...props }) => {
  const Modal = useModal();
  const { hasAbilities } = useUser();
  // early exit
  if(!isOrderOfferable(order)
    || !hasAbilities('CreateVendorJobOffers')
    || !hasAbilities('UpdateServiceOrders')
  ) return null;
  // create the action button
  const openModal = () => Modal.open(<SendJobOfferModal order={order} />);
  const icon = {
    library: 'communication',
    symbol: 'Sending-mail',
  }
  return <Action onClick={openModal} icon={icon} {...props}>{ hasAcceptedVendor(order) ? 'Change Vendor' : 'Send New Offer' }</Action>;
};
