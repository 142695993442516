import React from 'react';
import { get } from 'lodash';

import { Minibar } from '../../../../common/components/Minibar';
import { TaskDetails } from './TaskDetails';
import CSS from './styles/TaskList.module.scss';

export const TaskList = ({ tenantEvent }) => {

  const tasks = get(tenantEvent, 'move_tasks', []);

  const taskListSections = [
    {
      title: 'Required tasks',
      tasks: tasks.filter(task => task.requirement === 'required'),
      showProgressBar: true,
    },
    {
      title: 'Recommended tasks',
      tasks: tasks.filter(task => task.requirement === 'recommended'),
    },
  ];

  return (
    <div className={CSS.wrapper}>
      { taskListSections.map(taskSection => (
        taskSection.tasks.length > 0 && (
          <div className={CSS.task_section} key={taskSection.title}>
            <div className={CSS.task_title}>
              <h3>
                <span>{taskSection.title}</span>
                <span className={CSS.count}>{taskSection.tasks.length}</span>
              </h3>
              { taskSection.showProgressBar && (
                <Minibar tasks={taskSection.tasks} shadow={true} />
              )}
            </div>

            <div className={CSS.list}>
              { taskSection.tasks.map((task, index) => (
                <TaskDetails
                  key={task.task_type+(task.task_details_id || index)}
                  task={task}
                  tenantEvent={tenantEvent}
                />
              ))}
            </div>
          </div>
        )
      ))}
    </div>
  );
};
