import React from 'react';
import classNames from 'classnames';
import { Icon } from '@moved/ui';

import CSS from '../../styles/Action.module.scss';

export const Action = ({ onClick, icon, color, className, children }) => {
  return (
    <div className={classNames(CSS[color || 'ghost'],className)} onClick={onClick}>
      { icon && <Icon {...icon} size={'16px'} /> }
      { children }
    </div>
  );
};
