import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Table, Icon } from '@moved/ui';

export const ReservationList = ({ reservations }) => (
  <Table
    columns={[
      {
        name: 'tenant',
        getValue: (row) => (
          <div className='flex gap-8 items-center'>
            <Icon symbol='User' library='general' className='marginRight-8'/>
            <span>{ format.fullname(get(row,'tenant',{})) }</span>
          </div>
        ),
      },
      {
        name: 'reservation',
        getValue: (row) => (`${format.date(get(row,'start_time'),'date')} - ${format.date(get(row,'start_time'),'time')}`),
      },
    ]}
    rows={reservations.map(reservation => ({ data: reservation }))}
  />
);
