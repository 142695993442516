import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_RENTERS_INSURANCE_POLICY',
  request: (token, action, data) => request.post(`/partners/renters-insurance-policy-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.renters_insurance_policy_request')),
  reducers: {
    success: (state, { response: policy, params: [token] }) => {
      return {
        ...state,
        rentersInsurancePolicies: {
          ...state.rentersInsurancePolicies,
          [token]: policy,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useReviewRentersInsurancePolicyPending,
} = generate.redux(creator);

export const initialState = {};
