import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { FormDateSelect, FormSelect, AtomSpinner } from '@moved/ui';

import { getTenantBuildingReservationCalendar } from '../actions';
import { useTenantBuildingReservationCalendar } from '../actions/selectors';

const getEarliestAvailableDate = (calendar) => {
  const availability = get(calendar,'availability',[]);
  const firstAvailability = availability[0];
  return moment.max(
    moment(get(calendar,'earliest_reservation_date')),
    moment(get(firstAvailability,'date')),
  ).format('YYYY-MM-DD');
};

const getLatestAvailableDate = (calendar) => {
  const availability = get(calendar,'availability',[]);
  const lastAvailability = availability[availability.length - 1];
  return moment.min(
    moment(get(calendar,'latest_reservation_date')),
    moment(get(lastAvailability,'date')),
  ).format('YYYY-MM-DD');
};

export const ReservationSchedule = ({ calendarId, task, tenantEvent }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const [pending, setPending] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const calendar = useTenantBuildingReservationCalendar(tenantEvent.id,task.building_task_id,calendarId);
  const availability = get(calendar,'availability',[]);
  const selectedAvailability = availability.find(day => day.date === selectedDate);
  const earliestAvailableDate = getEarliestAvailableDate(calendar);
  const lastAvailableDate = getLatestAvailableDate(calendar);

  const updateDate = (newDate) => {
    if(newDate === selectedDate) return;
    setFieldValue('reservation_time',null);
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if(!calendarId) return;
    setPending(true);
    dispatch(getTenantBuildingReservationCalendar(tenantEvent.id,task.building_task_id,calendarId))
      .then(calendar => setSelectedDate(getEarliestAvailableDate(calendar)))
      .catch(err => Notify.error(format.error(err)))
      .finally(() => setPending(false));
  },[tenantEvent, task, calendarId, dispatch, Notify]);

  // can't initialize the form until the calendar is loaded
  if(pending || !calendar) return <AtomSpinner/>;

  const timeOptions = get(selectedAvailability,'timeslots',[])
    .filter(slot => slot.is_available)
    .map(slot => ({
      label: `${format.date(`${selectedDate}T${slot.start}`,'time')} - ${format.date(`${selectedDate}T${slot.end}`,'time')}`,
      value: slot.start,
    }));

  return (
    <>
      {/* TODO: disable dates with no availability */}
      <FormDateSelect
        name='reservation_date'
        label='Reservation date'
        minDate={earliestAvailableDate}
        maxDate={lastAvailableDate}
        value={selectedDate}
        onChange={({reservation_date}) => updateDate(reservation_date)}
      />
      { get(calendar,'booking_type') === 'appointment-slot' && (
        <FormSelect
          name='reservation_time'
          label='Reservation time'
          options={timeOptions}
        />
      )}
    </>
  );
};
