import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { noop } from 'lodash';

import { useNotify, format, useOutsideClick, useUser } from '@moved/services';
import { Icon, SlideToggle } from '@moved/ui'
import {
  respondBuildingRequest,
  getBuildingCalendarPendingRequests,
  getBuildingCalendarRepliedRequests,
  getPartnerCalendar
} from '../actions';
import {
  useCalendarPendingRequests,
  useCalendarPendingRequestsPending,
  useCalendarRepliedRequests,
  useCalendarRepliedRequestsPending,
  useCalendarSummaries
} from '../actions/selectors';
import { RequestInfo } from './';

import CSS from '../styles/NotificationsMenu.module.scss';

export const NotificationsMenu = ({ buildingId, modalEdit }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setVisible(false));

  const requests = useCalendarPendingRequests(buildingId);
  const replied = useCalendarRepliedRequests(buildingId);

  const [ visible, setVisible ] = useState(false);
  const toggleVisible = () => setVisible(!visible);

  useEffect(() => {
    dispatch(getBuildingCalendarPendingRequests(buildingId))
      .catch(error => {
        const errors = format.error(error);
        Notify.error(errors);
      });
    dispatch(getBuildingCalendarRepliedRequests(buildingId))
      .catch(error => {
        const errors = format.error(error);
        Notify.error(errors);
      });;
  // eslint-disable-next-line
  },[buildingId]);

  return (
    <div className={CSS.placement} ref={wrapperRef}>
      <div className={classNames(CSS.btn_requests,{ [CSS.active]: visible })}  onClick={modalEdit ? () => false : toggleVisible}>
        <Icon symbol='Notifications#1' library='general' size='20px' />
        {requests.length > 0 && (
          <div className={CSS.wrapper}>
            <div className={CSS.notifications}>{requests.length}</div>
          </div>
        )}
      </div>
      {visible && (
        <NotificationsPopOver requests={requests} replied={replied} setVisible={setVisible} buildingId={buildingId} />
      )}
    </div>
  );
};

const NotificationsPopOver = ({ requests, replied, buildingId, setVisible }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { Can } = useUser();

  const calendars = useCalendarSummaries(buildingId);
  const calendarMap = {}
  calendars.forEach(cal => (calendarMap[cal.id] = cal.name || (cal.task_type_name === 'reserve' ? 'Elevator': 'Keys')));

  // redux
  const requestPending = useCalendarPendingRequestsPending();
  const repliedPending = useCalendarRepliedRequestsPending();
  const pending = requestPending || repliedPending;

  // state
  const [ view, setView ] = useState('new');

  const handleRequest = (building_calendar_id, request, action) => {
    dispatch(respondBuildingRequest(
      buildingId,
      building_calendar_id,
      request.id,
      action,
    ))
    .then(resp => {
      return dispatch(getPartnerCalendar(building_calendar_id, moment(request.start_time).startOf('week').format('YYYY-MM-DD')));
    })
    .catch(error => {
      const errors = format.error(error);
        return Notify.error(errors);
    });
  };

  // handle the mapping of a request to its calendar type to it's permission class
  const getRequestPermission = (request, action) => {
    const cal = calendars.find(cal => cal.id === request.building_calendar_id);
    switch(cal.task_type_name) {
      case 'keys': return `${action}KeyPickupAppointmentRequests`;
      case 'reserve': return `${action}BuildingReservationRequests`;
      default: return null;
    }
  };

  return (
    <div className={CSS.menu}>
      <div className={CSS.popover}>
        <div className={CSS.title_bar}>
          <h4>Requests</h4>
          <div onClick={e => setVisible(false)}>
            <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
          </div>
        </div>

        <SlideToggle
          active={view}
          options={[{
            label: 'New',
            value: 'new',
          },{
            label: 'Replied',
            value: 'replied',
          }]}
          callback={setView}
          rectangular={true}
        />

        <div className={CSS.content}>

          <div className={CSS.requests}>

            {view === 'new' && (
              <>
                {requests.length > 0 && requests.map(request => (
                  <div className={CSS.request} key={`request_${request.id}`}>
                    <div className={CSS.request_wrapper}>
                      <RequestInfo request={request} calendarMap={calendarMap} />

                      <div className={CSS.actions}>
                        <Can I={getRequestPermission(request,'Reject')}>
                          <div
                            className={CSS.decline}
                            disabled={pending}
                            onClick={pending ? noop : e => handleRequest(request.building_calendar_id,request,'reject')}
                          >
                            Decline
                          </div>
                        </Can>
                        <Can I={getRequestPermission(request,'Approve')}>
                          <div
                            className={CSS.accept}
                            disabled={pending}
                            onClick={pending ? noop : e => handleRequest(request.building_calendar_id,request,'approve')}
                          >
                            Accept
                          </div>
                        </Can>
                      </div>

                    </div>
                  </div>
                ))}
                {requests.length <= 0 && (
                  <p className={CSS.no_results}>There are currently no requests pending approval.</p>
                )}
              </>
            )}

            {view === 'replied' && (
              <>
                {replied.length > 0 && replied.map(request => (
                  <div className={CSS.request} key={`request_${request.id}`}>
                    <div className={CSS.request_wrapper}>
                      <RequestInfo request={request} calendarMap={calendarMap} />

                      <div className={CSS.actions}>
                        {(request.status === 'rejected' || request.status === 'rejection-acknowledged')&& (
                          <div className={CSS.declined}><Icon symbol={'Close'} library={'navigation'} size={'20px'} />Declined</div>
                        )}
                        {request.status === 'approved' && (
                          <div className={CSS.accepted}><Icon symbol={'Done'} library={'code'} size={'20px'} />Accepted</div>
                        )}
                      </div>

                    </div>
                  </div>
                ))}
                {replied.length <= 0 && (
                  <p className={CSS.no_results}>There are currently no upcoming requests.</p>
                )}
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};
