import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_VEHICLE_REGISTRATION_REQUEST',
  request: (token) => request.get(`/partners/vehicle-registration-requests/token/${token}`).then(r => r.data.data.vehicle_registration_request),
  selector: (state, token) => get(state, `vehicleRegistrations.${token}`),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
        vehicleRegistrations: {
          ...state.vehicleRegistrations,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useVehicleRegistration,
  usePending: useVehicleRegistrationPending,
} = generate.redux(creator);

export const initialState = {
  vehicleRegistrations: {},
};
