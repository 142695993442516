import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import { SectionDisplay } from './SectionDisplay';

import { UpdateRate } from '../buttons';

export const QuoteSection = ({ order }) => {
  const details = {
    title: 'Quote',
    actions: [
      <UpdateRate order={order} key='update-rate'/>,
    ],
    columns: [
      [
        get(order,'rate_type') === 'hourly' && {
          label: 'Crew size',
          value: get(order,'crew_size'),
        },
        get(order,'rate_type') === 'hourly' && {
          label: 'Hourly rate',
          value: format.currency(get(order,'rate')),
        },
        get(order,'rate_type') === 'hourly' && {
          label: 'Minimum cost',
          value: format.currency(get(order,'minimum')),
        },
        get(order,'rate_type') === 'flat' && {
          label: 'Estimated price',
          value: format.currency(get(order,'estimated_price')),
        },
      ].filter(v => v),
      [
        // empty second column for layout consistency
      ].filter(v => v),
    ],
  };

  return (
    <SectionDisplay section={details}/>
  );

};
