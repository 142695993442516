import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { taskTypes } from '../types';

import { DefaultConfiguration } from './DefaultConfiguration';
import { useTaskDetails } from '../actions/selectors';

export const TaskDetailsBreadcrumb = ({match, label, isActive}) => {
  const { task_id } = match.params;
  const task = useTaskDetails(task_id);
  return (<>{isActive || !task ? 'Task details' : get(task,'title')}</>)
};

export const TaskDetails = () => {
  // HOOKS
  const { task_id } = useParams();

  // REDUX
  const task = useTaskDetails(task_id);

  const { Configuration=DefaultConfiguration } = taskTypes[task.task_type_name] || {};
  return <Configuration task={task} />;

};
