import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_TASK_DETAILS',
  request: (id, data={}) => request.patch(`/admins/building-tasks/${id}`, data).then(r => get(r,'data.data.building_task')),
  selector: (state, id) => { return get(state,`taskDetails.${id}`); },
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          [response.id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,

  usePending: useUpdateTaskDetailsPending,
} = generate.redux(creator);

export const initialState = {};
