import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_MOVES_GET_TENANT_EVENT_SEARCH',
  request: (params={},cancelToken) => request.get(`/admins/tenant-events`, { params, cancelToken }).then(r => r.data),
  selector: (state) => get(state,'tenantEventSearch'),
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        tenantEventSearch: {
          params: searchParams,
          totalResults: get(response,'pagination.total_items'),
          activeSet: get(response,'data.tenant_events'),
        },
      }
    },
    failure: (state, { params: [searchParams] }) => {
      return {
        ...state,
        tenantEventSearch: {
          params: searchParams,
          totalResults: 0,
          activeSet: [],
        }
      }
    },
  }
};

export const {
  action,
  reducer,
  useResource: useTenantEventSearch,
  usePending: useTenantEventSearchPending,
} = generate.redux(creator);

export const initialState = {
  tenantEventSearch: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
