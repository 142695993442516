import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useOptionalControl } from '@moved/services';

import { Chip } from './Chip';

export const ChipList = ({
  name,
  options,
  value,
  isControlled,
  disabled,
  error,
  onChange,
  className
}) => {
  const [selected, setSelected] = useOptionalControl(value, isControlled); // don't even worry about value here

  const handleChange = (newVal) => {
    if (disabled) return;
    setSelected(newVal);
    onChange?.({ [name]: newVal });
  };

  return (
    <div className={classNames('stackVertical-4', className)}>
      <div className='stackHorizontal-12'>
        {options.map(option => (
          <Chip
            key={`${name}__${option.value}`}
            name={name}
            {...option}
            selected={selected === option.value}
            disabled={disabled}
            onChange={handleChange}
          />
        ))}
      </div>
      {error != null && (
        <div className='labelS contentError'>{error}</div>
      )}
    </div>
  );
}

ChipList.propTypes = {
  /** Class name to be applied to chip group */
  className: PropTypes.string,

  /** Flag to disable all chips in the group */
  disabled: PropTypes.bool,

  /** Error message for the chip group */
  error: PropTypes.string,

  /** Value to use for this input (only initial value if not controlled) (must match the value of an option) */
  value: PropTypes.any,

  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,

  /** Name to use for the form input (shared by all chips within this group) */
  name: PropTypes.string.isRequired,

  /** (optional) onChange handler */
  onChange: PropTypes.func,

  /** List of options for individual chips in the group */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    icon: PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      library: PropTypes.string.isRequired,
    })
  })).isRequired,
};
