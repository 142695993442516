import React from 'react';
import { useUser } from '@moved/services';

import CSS from '../styles/shared.module.scss';

export const DashboardLink = () => {
  const { isAuthenticated } = useUser();
  if(isAuthenticated) return (
    <div className={CSS.actions}>
      <a className='btn-primary btn--full' href='/admin/moves'>Return to Dashboard</a>
    </div>
  )
  else return null;
};
