import { merge } from 'lodash';

import * as activity from './activity/reducer';
import * as configuration from './configuration/reducer';

export const reducers = [
  ...activity.reducers,
  ...configuration.reducers,
];

export const initialState = merge(
  activity.initialState,
  configuration.initialState,
);
