import React from 'react';

import { DefaultConfiguration } from '../../../components/DefaultConfiguration';

import { OrientationNavigation } from './OrientationNavigation';

export const Configuration = ({ task }) => {
  return (
    <DefaultConfiguration task={task} navigation={<OrientationNavigation />} />
  );
};
