import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { Icon, PopOver, Collapsible } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

import { NotificationOptions } from './';

import CSS from './styles/AssignedProperty.module.scss';

import { removeAdminBuilding } from '../actions/';

export const PropertyAddress = ({ address }) => {
  address = address || {};
  return (
    <div className={CSS.address_line}>
      <span className={CSS.address_line_one}>
        {format.address(
          address,
          {
            city: false,
            state: false,
            zipcode: false,
          })
        }
      </span>
      <span className={CSS.address_line_two}>
      {format.address(
        address,
        {
          street: false,
          unit: false,
        })
      }
      </span>
    </div>
  );
};

export const AssignedProperty = ({buildingAdmin, admin}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const Notify = useNotify();
  const { Can } = useUser();
  const [pending, setPending] = useState(false);
  const [notificationExpanded, setNotificationExpanded] = useState(false);

  const _removeProperty = id => {
    setPending(true);
    dispatch(removeAdminBuilding(id, admin.id))
      .then(resp => {
        setPending(false);
      })
      .catch(error => {
        setPending(false);
        return Notify.error(format.error(error));
      });
  };

  return (
    <div
      className={classNames(CSS.box,{ [CSS.pending]: pending, [CSS.open]: notificationExpanded, })}
      onClick={() => setNotificationExpanded(!notificationExpanded)}
    >

      <div className={CSS.box_top}>

        <div
          className={CSS.splash}
          style={{
            ...{backgroundImage: get(buildingAdmin,'building.dashboard_image_url') ?
              `url('${get(buildingAdmin,'building.dashboard_image_url')}')`
              : null,
            }
          }}
        />

        <div className={CSS.grow}>
          <div className={CSS.building_name}>
            {buildingAdmin.building.name}
          </div>

          <div className={CSS.address}>
            <PropertyAddress address={buildingAdmin.building} />
          </div>
        </div>

        <div className={CSS.actions}>
          <PopOver
            id={`more_${buildingAdmin.id}`}
            customClass={CSS.property_popover}
            className={CSS.trigger}
            hideArrow="true"
            placement="bottom"
            trigger="click"
            tooltip={(
              <div id={'popover_more_options'}>
                <div
                  className={CSS.popover_action}
                  onClick={() => history.push({
                    pathname:`/admin/properties/${get(buildingAdmin,'building.id')}/tasks`,
                    state: {
                      from: `${location.pathname}${location.search}`,
                    }
                  })}
                  >
                  <Icon symbol={'Building'} library={'home'} size={'20px'} className={CSS.popover_icon} />
                  <span className={CSS.popover_link}>View property</span>
                </div>
                <Can I="DeleteBuildingAdmins">
                  <div className={CSS.popover_action} onClick={() => _removeProperty(buildingAdmin.id)}>
                    <Icon symbol={'Trash'} library={'general'} size={'20px'} className={CSS.popover_icon} />
                    <span className={CSS.popover_link}>Remove</span>
                  </div>
                </Can>
              </div>
            )}
            stopPropagation
          >
            {(tooltipShown) => (
              <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
            )}
          </PopOver>

          <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow_icon} />
        </div>

      </div>
      <div className={CSS.box_bottom} onClick={e => e.stopPropagation()}>
        <Collapsible open={notificationExpanded}>
          <NotificationOptions notifications={buildingAdmin.notification_preferences} buildingAdmin={buildingAdmin} admin={admin}/>
        </Collapsible>
      </div>
    </div>
  );
};
