import React from 'react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import moment from 'moment';

import { Icon } from '@moved/ui';

import CSS from './styles/PropertyResult.module.scss';

const _renderDatePill = launch => {
  if(!launch) return (<span className={classNames(CSS.pill,CSS.pill_to_be_launched)}>To be launched</span>);

  const diff = moment(launch).startOf("day").diff(moment().startOf("day"),"days");

  if(diff > 0 && diff < 4) return (<span className={CSS.pill}>{diff} day{diff !== 1 && 's'} away</span>);

  else if(diff < 0 && diff > -4) return (<span className={CSS.pill}>{diff * -1} day{diff !== -1 && 's'} ago</span>);

  else return (<span className={classNames(CSS.pill, { [CSS.pill_future]: diff > 0 })}>{moment(launch).format('MMM DD, YYYY')}</span>);
};

const _renderAddress = (address) => {
  address = address || {};
  return (
    <div className={CSS.address_line}>
      <span className={CSS.address_line_one}>{[address.street,address.unit].filter((v)=>v).join(' ')}</span>
      <span className={CSS.address_line_two}>
        {[
          address.city,
          [
            address.state,
            address.zipcode,
          ].filter((v)=>v).join(' ')
        ].filter((v)=>v).join(', ')}
      </span>
    </div>
  );

};

export const PropertyResult = ({ property, active, onClick }) => {
  // HOOKS
  const location  = useLocation();
  const partner = property.partner || {};

  return (
    <Link
      className={classNames(CSS.box, { [CSS.box_active]: active })}
      to={{
        pathname: `/admin/properties/${property.id}`,
        state: {
          from: `${location.pathname}${location.search}`,
        },
      }}>

      <div className={CSS.box_top}>

        <div className={CSS.name}>
          <div
            className={CSS.splash}
            style={{
              ...{backgroundImage: property.dashboard_image_url ?
                `url('${property.dashboard_image_url}')`
                : null,
              }
            }}
          />
          <div className={CSS.name_text}>{property.name}</div>
        </div>

        <div className={CSS.partner_name}>
          {partner.name}
        </div>

        <div className={CSS.address}>
          {_renderAddress(property)}
        </div>

        <div className={CSS.launch_date}>
          {_renderDatePill(property.launch_date)}
        </div>

        <div className={CSS.moves}>
          {property.upcoming_moves_count}
        </div>

        <div className={CSS.arrow}>
          <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow_icon} />
        </div>

      </div>

    </Link>
  );
};
