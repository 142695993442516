import { merge } from 'lodash';

import * as getPayMoveInCosts from './getPayMoveInCosts';
import * as reviewPayMoveInCosts from './reviewPayMoveInCosts';

export const initialState = merge(
  getPayMoveInCosts.initialState,
  reviewPayMoveInCosts.initialState,
);

export const reducers = [
  getPayMoveInCosts.reducer,
  reviewPayMoveInCosts.reducer,
];
