import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_KEY_PICKUP_BY_ROOMMATE',
  request: (id, data={}) => request.post(`/admins/buildings/${id}/settings/key-pickup-by-roommate`, data).then(r => get(r, 'data.data.key_pickup_by_roommate_enabled')),
};

export const {
  action,
  reducer,
  usePending: useUpdateKeyPickupByRoomatePending,
} = generate.redux(creator);

export const initialState = {};
