import { merge } from 'lodash';

import * as createKeyPickupAppointment from './createKeyPickupAppointment';
import * as getBuildingCalendar from './getBuildingCalendar';
import * as getBuildingCalendarOptions from './getBuildingCalendarOptions';
import * as getTenantKeyPickupOptions from './getTenantKeyPickupOptions';
import * as getTenantKeyPickupCalendar from './getTenantKeyPickupCalendar';
import * as updateBuildingCalendar from './updateBuildingCalendar';
import * as updateKeyPickupByRoommate from './updateKeyPickupByRoommate';

export const reducers = [
  createKeyPickupAppointment.reducer,
  getBuildingCalendar.reducer,
  getBuildingCalendarOptions.reducer,
  getTenantKeyPickupOptions.reducer,
  getTenantKeyPickupCalendar.reducer,
  updateBuildingCalendar.reducer,
  updateKeyPickupByRoommate.reducer,
];

export const initialState = merge(
  createKeyPickupAppointment.initialState,
  getBuildingCalendar.initialState,
  getBuildingCalendarOptions.initialState,
  getTenantKeyPickupOptions.initialState,
  getTenantKeyPickupCalendar.initialState,
  updateBuildingCalendar.initialState,
  updateKeyPickupByRoommate.initialState,
);
