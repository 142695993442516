import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TableGenerator } from './TableGenerator';

import CSS from './Table.module.scss';

export const Table = ({
  columns,
  rows,
  options,
  children,
  className,
}) => (
  <div className={classNames(CSS.table, className)}>
    { rows && columns && (
      <TableGenerator columns={columns} rows={rows} options={options} />
    )}
    { children }
  </div>
);

Table.propTypes = {
  /** Array of columns to render */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** Unique key name for this column */
    name: PropTypes.string,
    /** Label to use in the header row for this column */
    label: PropTypes.node,
    /** Flag to enable sorting for this column */
    sortable: PropTypes.bool,
    /** (optional) Function to get the dynamic value for this column from a single row's data */
    getValue: PropTypes.func,
    /** (optional) Static display value for this column (overridden by getValue if defined) */
    value: PropTypes.node,
    /** (optional) class name to add to all column cells */
    className: PropTypes.string,
  })),
  /** Array of data for populating each row, decorated with functionality options */
  rows: PropTypes.arrayOf(PropTypes.shape({
    /** Data representing one row, can be any shape, will be passed to column.getValue(row.data) for each column */
    data: PropTypes.any.isRequired,
    /** (optional) handler for when clicking on this row, automatically adds an action column with right arrow icon */
    onClick: PropTypes.func,
    /** (optional) class name to add to this TableRow */
    className: PropTypes.string,
  })),
  /** (optional) Object to define standard table options */
  options: PropTypes.shape({
    /** Flag to include an empty column for alignment of an actions column in the table */
    showActions: PropTypes.bool,
  }),
  /** (optional) Manual table rows instead of auto-scaffolded (or extra content after the auto table) */
  children: PropTypes.node,
  /** (optional) class name to add to the table component */
  className: PropTypes.string,
};
