import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_CALENDAR_REPLIED_REQUESTS',
  request: (building_id) =>
    request.get(`/partners/buildings/${building_id}/replied-calendar-requests`).then(r => r.data.data.building_calendar_requests),
  selector: (state, building_id) => {
    if(!building_id) return [];
    return (get(state,`calendarRepliedRequests[${building_id}]`) || []);
  },
  reducers: {
    success: (state, { response, params: [building_id] }) => {
      return {
        ...state,
        calendarRepliedRequests: {
          ...state.calendarRepliedRequests,
          [building_id]: response,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useCalendarRepliedRequests,
  usePending: useCalendarRepliedRequestsPending,
} = generate.redux(creator);

export const initialState = {
  calendarRepliedRequests: {},
};
