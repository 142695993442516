import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get} from 'lodash';

import { LoaderOverlay } from '@moved/ui';

import { getProperty } from '../actions/';
import { useProperty, usePropertyPending, } from '../actions/selectors';


export const PropertyWrapper = ({ children }) => {
  // HOOKS
  const dispatch = useDispatch();
 // Get move id from URL
  const { property_id } = useParams();

  // MOVE DETAILS STATE
  const property = useProperty(property_id);
  const pending = usePropertyPending();

  useEffect(() => {
    dispatch(getProperty(property_id));
  // eslint-disable-next-line
  },[]);

  if(!property) return (
    <>
      <Helmet>
        <title>{`Admin Abode Properties : Moved`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${get(property,'name')} Admin Abode Properties : Moved`}</title>
      </Helmet>

      {pending && (<LoaderOverlay />)}

      {children}
    </>
  );
};
