import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import { DynamicForm, AnimatedCheckmark, ConfirmationModal } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { createBlock } from '../actions';
import { useBlockPending} from '../actions/selectors';

import CSS from '../styles/BlockTime.module.scss';

export const BlockTime = ({ id }) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  // state
  const [submitted, setSubmitted] = useState(false);
  const [startEnd, setStartEnd] = useState();
  const [values, setValues] = useState({});
  // redux
  const pending = useBlockPending();

  // helper functions
  const handleChange = ({ total, ...values }, { setFieldValue }) => {
    setValues(values);
  };
  const handleSubmit = (data) => {
    dispatch(createBlock(
      id,
      {
        start_time: moment(`${data.date} ${data.start}`,'YYYY-MM-DD h:mmA').format('YYYY-MM-DDTHH:mm:ss'),
        end_time: moment(`${data.date} ${data.end}`,'YYYY-MM-DD h:mmA').format('YYYY-MM-DDTHH:mm:ss'),
        title: data.title,
      }
    )).then(resp => {
      setTimeout(Modal.close, 650);
      setSubmitted(true);
    })
    .catch(error => {
      const errors = format.error(error);

      if(get(error,'response.status') === 409) {
        Modal.close();
        return Modal.open(
          <ConfirmationModal
            title={'Unable to block specified time'}
            copy={errors ?
              errors.map(err => (<p key={err}>{err}</p>)) :
              'Other appointments exist during this time. Please cancel or reschedule.'
            }
            cancelText={false}
            confirmText={'I understand'}
          />,
          { sondheim: true },
        );
      } else {
        return Notify.error(errors);
      }
    });
  };
  const timeChange = (option, setFieldValue, name) => {
    let start, end;
    if(name === 'start') {
      end = values.end;
      start = option.value;
      const startEnd = moment(start,'h:mma').add({minutes: 30}).format('h:mma');
      setStartEnd(startEnd);
      if(moment(end, 'h:mma').isBefore(moment(startEnd, 'h:mma'))) {
        setFieldValue('end',startEnd,true);
        end = startEnd;
      }
    }
    else if(name === 'end') {
      start = values.start;
      end = option.value;
    }
  }

  const slotFields = [
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      required: true,
    },
    {
      label: 'Date',
      type: 'datePicker',
      name: 'date',
      required: true,
      minDate: moment().format('YYYY-MM-DD'),
      value: moment().format('YYYY-MM-DD'),
    },
    {
      label: 'Start time',
      placeholder: 'Start time',
      name: 'start',
      type: 'timePicker',
      value: '',
      min: '5:00am',
      max: '10:00pm',
      interval: ['00','30'],
      onChange: timeChange,
      required: true,
      half: true,
    },
    {
      label: 'End time',
      placeholder: 'End time',
      name: 'end',
      type: 'timePicker',
      value: '',
      min: startEnd || '5:30am',
      max: '10:30pm',
      interval: ['00','30'],
      onChange: timeChange,
      required: true,
      half: true,
    },
  ];

  return (
    <div className={CSS.block}>
      { submitted && (<div className={CSS.confirm}><AnimatedCheckmark isChecked={true} fast={true} /></div>) }
      <div className={CSS.title}>
        <h3>Block time</h3>
      </div>
      <div className={CSS.block_edit_form}>
        <DynamicForm
          fields={slotFields}
          formStyle={'underline'}
          id="block-form"
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.block_edit_control}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => Modal.close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="block-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Block
        </label>
      </div>
    </div>
  );
}
