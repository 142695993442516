import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_COI_UPLOAD',
  request: (token, action, data) => request.post(`/partners/coi-uploads/token/${token}/${action}`, data).then(r => r.data.data.coi_upload),
  reducers: {
    success: (state, { response: coiUpload, params: [token]}) => {
      return {
        ...state,
        coiUploads: {
          ...state.coiUploads,
          [token]: coiUpload,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useCoiReviewPending,
} = generate.redux(creator);

export const initialState = {};
